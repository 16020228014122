var _a, _b, _c, _d;
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { Button, ButtonType, Logo, StepIndicator, TermsOfServiceMode } from '@lean-body/components/atoms';
import { observer } from 'mobx-react';
import * as React from 'react';
import { routes } from '@lean-body/src/route';
import { FooterComponent } from '@lean-body/components/organisms';
import { EF_INDICATOR_LABELS } from '../constants';
import { useNavigate } from 'react-router';
import { SettingItemKeys } from '../organisms/setting_menu';
import { useSmMediaQuery } from '@lean-body/src/util';
import { GoalMatchingAssessmentPageQuery } from './main_goal_matching_assessment';
export var CheckEmailPage = observer(function (_a) {
    var accountService = _a.accountService;
    var _b = React.useState(null), me = _b[0], setMe = _b[1];
    var _c = React.useState(null), term = _c[0], setTerm = _c[1];
    var navigate = useNavigate();
    var isSM = useSmMediaQuery();
    var onClickApproveButton = function () {
        navigate("".concat(routes.GOAL_MATCHING_ASSESSMENT.compile(), "?").concat(GoalMatchingAssessmentPageQuery.new, "=1"));
    };
    var onClickFixButton = function () {
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.email));
    };
    React.useEffect(function () {
        accountService.fetchMe().then(function (v) {
            setMe(v);
            if (v.payment.isMegalosUser) {
                setTerm(TermsOfServiceMode.megalos);
            }
            else {
                setTerm(TermsOfServiceMode.normal);
            }
        });
    }, []);
    if (!me)
        return null;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
        React.createElement("div", { className: css(styles.containerWithFooter) },
            React.createElement("div", { className: css(styles.content) },
                React.createElement(StepIndicator, { activeIdx: 2, captions: EF_INDICATOR_LABELS, classObject: styles.indicator }),
                React.createElement("div", { className: css(styles.title) }, "\u767B\u9332\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F"),
                React.createElement("div", { className: css(styles.sentence) },
                    "\u3054\u767B\u9332\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u300C",
                    React.createElement("span", { className: css(styles.mailAddress) }, me.email),
                    "\u300D",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u304A\u9593\u9055\u3048\u304C\u306A\u3051\u308C\u3070\u300C\u627F\u8A8D\u300D\u30DC\u30BF\u30F3\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                    !isSM && React.createElement("br", null),
                    "\u4FEE\u6B63\u3059\u308B\u5834\u5408\u306F\u300C\u4FEE\u6B63\u300D\u30DC\u30BF\u30F3\u3092\u62BC\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u203B\u767B\u9332\u5B8C\u4E86\u306E\u30E1\u30FC\u30EB\u304C\u5C4A\u3044\u3066\u3044\u306A\u3044\u5834\u5408\u3001\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u304C\u9593\u9055\u3063\u3066\u3044\u308B\u53EF\u80FD\u6027\u304C\u3042\u308A\u307E\u3059\u3002"),
                React.createElement(Button, { onClick: onClickApproveButton, className: css(styles.buttonApprove), isRadius: true, fullWidth: true, buttonType: ButtonType.primary }, "\u627F\u8A8D"),
                React.createElement(Button, { onClick: onClickFixButton, className: css(styles.buttonFix), isRadius: true, fullWidth: true }, "\u4FEE\u6B63")),
            React.createElement(FooterComponent, { termsOfServiceMode: term }))));
});
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 80,
        },
        _a),
    containerWithFooter: (_b = {
            padding: '40px 24px 0',
            minHeight: 'calc(100vh - 50px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: 0,
            minHeight: 'calc(100vh - 146px)',
        },
        _b),
    header: (_c = {},
        _c[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _c),
    content: (_d = {},
        _d[MediaBreakPointUp.MD] = {
            borderRadius: 10,
            maxWidth: 375,
            margin: '0 auto 40px',
            padding: '40px 0',
        },
        _d),
    indicator: {
        margin: '0 auto 53px',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 21,
        textAlign: 'center',
    },
    sentence: {
        color: Colors.GrayDarkest,
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: 30,
    },
    mailAddress: {
        fontWeight: 'bold',
        color: Colors.Black,
    },
    buttonApprove: {
        marginBottom: 14,
        fontSize: 16,
    },
    buttonFix: {
        fontSize: 16,
    },
});
