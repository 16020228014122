var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType, CircleCheckIcon, Input, Logo } from '../atoms';
import { Colors, MediaBreakPointUp } from '../styles';
import { useQuery, useSmMediaQuery } from '@lean-body/src/util';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { getSigninReirectPath, SIGNIN_REDIRECT } from './signin';
var Statsu = {
    ready: 'ready',
    loadingButton: 'loadingButton',
    success: 'success',
};
var TvLoginQuery = 'tvLoginToken';
export var TvLoginPage = function (_a) {
    var apiClient = _a.apiClient, accountService = _a.accountService, alertPresenter = _a.alertPresenter;
    var query = useQuery();
    var _b = React.useState(query.get(TvLoginQuery) || ''), token = _b[0], setToken = _b[1];
    var _c = React.useState(Statsu.ready), status = _c[0], setStatus = _c[1];
    var isSM = useSmMediaQuery();
    var navigate = useNavigate();
    React.useEffect(function () {
        var currentPath = getSigninReirectPath();
        accountService.authorization().catch(function () {
            navigate("".concat(routes.SIGNIN.compile(), "?").concat(SIGNIN_REDIRECT, "=").concat(currentPath));
        });
    }, []);
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setStatus(Statsu.loadingButton);
            apiClient
                .confirmTvSession(token)
                .then(function () {
                setStatus(Statsu.success);
            })
                .catch(function () {
                alertPresenter.showError(null, 'デバイス登録に失敗しました。時間をおいてから再度お試しください。');
                setStatus(Statsu.ready);
            });
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.logo }),
        React.createElement("div", { className: css(styles.body) }, status === Statsu.success ? (React.createElement(React.Fragment, null,
            React.createElement(CircleCheckIcon, { classObject: styles.checkIcon, color: Colors.PrimaryDark, fillColor: Colors.White, filled: true }),
            React.createElement("div", { className: css(styles.title) }, "\u30C7\u30D0\u30A4\u30B9\u306E\u767B\u9332\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F\uFF01"))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.title) }, "TV\u307E\u305F\u306F\u30C7\u30D0\u30A4\u30B9\u3092\u767B\u9332"),
            React.createElement("div", { className: css(styles.description) }, "\u30C7\u30D0\u30A4\u30B9\u306B\u8868\u793A\u3055\u308C\u3066\u3044\u308B8\u6841\u306E\u30B3\u30FC\u30C9\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
            React.createElement(Input, { type: "number", label: "\u767B\u9332\u30B3\u30FC\u30C9", value: token, onChange: function (v) { return setToken(v.target.value); }, labelClassObject: styles.inputLabel, containerClassObject: styles.input }),
            React.createElement(Button, { buttonType: ButtonType.primary, fullWidth: true, onClick: onSubmit, loading: status === Statsu.loadingButton }, "\u30C7\u30D0\u30A4\u30B9\u3092\u767B\u9332"))))));
};
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 375,
            margin: '80px auto 0',
        },
        _a),
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 30,
        textAlign: 'center',
        padding: '30px 24px',
    },
    logo: {
        maxWidth: 271,
        margin: '0 auto',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    description: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.6,
    },
    input: {
        width: '100%',
    },
    inputLabel: {
        color: Colors.Black,
        fontWeight: 'bold',
        marginTop: 14,
    },
    checkIcon: {
        width: 44,
        height: 44,
        marginTop: 30,
    },
});
