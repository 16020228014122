var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { routes } from '@lean-body/src/route';
import { NowRedirecting } from '@lean-body/components/organisms/now_redirecting';
import * as msg from '@lean-body/src/messages';
import * as alertGroup from '../../constants/alert_groups';
import { useNavigate } from 'react-router-dom';
var PageStatus;
(function (PageStatus) {
    PageStatus["Loading"] = "loading";
    PageStatus["InValidState"] = "invalid_state";
    PageStatus["Ready"] = "ready";
})(PageStatus || (PageStatus = {}));
export var AppleCallbackPage = observer(/** @class */ (function (_super) {
    __extends(AppleCallbackPage, _super);
    function AppleCallbackPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppleCallbackPage.prototype, "render", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return React.createElement(NowRedirecting, null);
        }
    });
    return AppleCallbackPage;
}(React.Component)));
var AppleCallbackPageVM = /** @class */ (function () {
    function AppleCallbackPageVM(accountService, router, alertPresenter, tracker) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pageStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: PageStatus.Loading
        });
        Object.defineProperty(this, "isSignUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "didSubExpired", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            pageStatus: observable.ref,
        });
        this.accountService = accountService;
        this.router = router;
        this.navigate = useNavigate();
        this.alertPresenter = alertPresenter;
        this.tracker = tracker;
        this.loadQueryParams();
        this.postAppleOAuthCode();
    }
    Object.defineProperty(AppleCallbackPageVM.prototype, "loadQueryParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var p = new URLSearchParams(window.location.search);
            this.isSignUp = p.get('is_sign_up') === 'true';
            this.didSubExpired = p.get('did_sub_expired') === 'true';
        }
    });
    Object.defineProperty(AppleCallbackPageVM.prototype, "postAppleOAuthCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, this.accountService.onAuthedSignInWithApple(this.isSignUp, this.didSubExpired, this.tracker)
                                // 初回登録の場合はプラン選択画面に遷移
                            ];
                        case 1:
                            _a.sent();
                            // 初回登録の場合はプラン選択画面に遷移
                            if (this.isSignUp) {
                                this.alertPresenter.showMessage(msg.APPLE_SIGNIN_SUCCEEDED);
                                this.navigate(routes.CC_REGISTRATION.compile());
                                return [2 /*return*/];
                            }
                            // StripeやAppStoreの課金期限が切れている場合はプラン選択画面に遷移
                            if (this.didSubExpired) {
                                this.alertPresenter.showMessage(msg.ACCOUNT_EXPIRED, alertGroup.ACCOUNT_EXPIRED);
                                this.navigate(routes.CC_REGISTRATION.compile());
                                return [2 /*return*/];
                            }
                            // 認可APIで検証後にトップページに遷移
                            this.alertPresenter.showMessage(msg.APPLE_SIGNIN_SUCCEEDED);
                            return [4 /*yield*/, this.router.authorizationForSignin()];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            err_1 = _a.sent();
                            this.alertPresenter.showError(err_1, msg.APPLE_SIGNIN_FAILED, 'signin_result');
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    });
    return AppleCallbackPageVM;
}());
export { AppleCallbackPageVM };
