import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CircleCheckIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 12,
            height: 12,
        },
    });
    var color = props.color || Colors.Gray;
    var fillColor = props.fillColor || Colors.White;
    return props.filled ? (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", onClick: props.onClick },
        React.createElement("circle", { cx: "10.5", cy: "10", r: "10", fill: color }),
        React.createElement("rect", { x: "6.91455", y: "8.9502", width: "6", height: "2", transform: "rotate(45 6.91455 8.9502)", fill: fillColor }),
        React.createElement("rect", { x: "6.91455", y: "8.9502", width: "6", height: "2", transform: "rotate(45 6.91455 8.9502)", fill: "url(#paint0_linear_11233_1978)" }),
        React.createElement("rect", { x: "14.6929", y: "6.82812", width: "2", height: "9", transform: "rotate(45 14.6929 6.82812)", fill: fillColor }),
        React.createElement("rect", { x: "14.6929", y: "6.82812", width: "2", height: "9", transform: "rotate(45 14.6929 6.82812)", fill: "url(#paint1_linear_11233_1978)" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_11233_1978", x1: "9.91455", y1: "8.9502", x2: "9.91455", y2: "10.9502", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: fillColor }),
                React.createElement("stop", { offset: "1", stopColor: fillColor, stopOpacity: "0" })),
            React.createElement("linearGradient", { id: "paint1_linear_11233_1978", x1: "15.6929", y1: "6.82812", x2: "15.6929", y2: "15.8281", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: fillColor }),
                React.createElement("stop", { offset: "1", stopColor: fillColor, stopOpacity: "0" }))))) : (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M6 10.722C8.60789 10.722 10.722 8.60789 10.722 6C10.722 3.39211 8.60789 1.278 6 1.278C3.39211 1.278 1.278 3.39211 1.278 6C1.278 8.60789 3.39211 10.722 6 10.722ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" }),
        React.createElement("path", { fill: color, fillRule: "evenodd", clipRule: "evenodd", d: "M9.17276 4.37018C9.41015 4.63131 9.39091 5.03544 9.12977 5.27284L5.82978 8.27284C5.57739 8.50228 5.18929 8.49305 4.9481 8.25186L3.1481 6.45186C2.89855 6.20231 2.89855 5.79772 3.1481 5.54817C3.39764 5.29863 3.80223 5.29863 4.05178 5.54817L5.42094 6.91734L8.2701 4.32719C8.53123 4.0898 8.93537 4.10905 9.17276 4.37018Z" })));
};
