var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e;
import { css, StyleSheet } from 'aphrodite';
import bind from 'bind-decorator';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { take } from 'rxjs/operators';
import { ReloclubService } from '@lean-body/src/domain';
import * as msg from '@lean-body/src/messages';
import { routes } from '@lean-body/src/route';
import { FacebookButton } from '@lean-body/components/atoms/facebook_button';
import { FBPixelEventName } from '../../infra';
import { PasswordVM } from '@lean-body/components/molecules/input_form_group/password_vm';
import { FooterComponent } from '@lean-body/components/organisms/footer';
import { Button, CheckBox, Input, LineButton, Logo, StepIndicator } from '@lean-body/components/atoms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { efUnloadEvent, isAndroidApp, useSmMediaQuery } from '@lean-body/src/util';
import { TermsOfServiceMode, TermsOfService, AppleButton, ButtonType } from '@lean-body/components/atoms';
import { EF_INDICATOR_LABELS, ZNEDESK_PRIVACY_POLICY_LINK } from '../constants';
import { LocalStorage, SignInType, SessionStorage } from '@lean-body/src/infra';
import { LineAuthType } from '@lean-body/src/infra/line_client';
import { LBError } from '@lean-body/src/errors';
import { FORM_PASSWORD_PLACEHOLDER } from '@lean-body/components/molecules/input_form_group/password_vm';
import { AmazonPayButton } from '../atoms/amazon_pay_button';
import { useNavigate } from 'react-router';
export var SignUpPage = observer(function (_a) {
    var vm = _a.vm;
    // androidアプリ以外は一括登録フォーム（signup_v2）に統一する
    var navigate = useNavigate();
    React.useEffect(function () {
        if (!isAndroidApp()) {
            navigate("".concat(routes.SIGNUP_V2.compile()).concat(location.search));
        }
    }, []);
    if (!isAndroidApp()) {
        return null;
    }
    var isSM = useSmMediaQuery();
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
        React.createElement("div", { className: css(styles.contentWithFooter) },
            React.createElement("div", { className: css(styles.content) },
                React.createElement(StepIndicator, { activeIdx: 0, captions: EF_INDICATOR_LABELS, classObject: styles.indicator }),
                React.createElement("div", { className: css(styles.title) },
                    "\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u4F5C\u6210\u3057\u3066",
                    React.createElement("br", null),
                    "\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9\u3092\u306F\u3058\u3081\u3088\u3046\uFF01"),
                vm.availableAmazonPaySignup && !isAndroidApp() && (React.createElement("div", { className: css(styles.amazonPayButtonSection) },
                    React.createElement("div", { className: css(styles.amazonPayButtonTextBold) },
                        "Amazon\u30A2\u30AB\u30A6\u30F3\u30C8\u3067",
                        React.createElement("br", null),
                        "\u7C21\u535830\u79D2\u767B\u9332\uFF01"),
                    "Amazon\u306B\u767B\u9332\u3057\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3084\u304A\u652F\u6255\u3044\u60C5\u5831\u3092\u305D\u306E\u307E\u307E\u5229\u7528\u3067\u304D\u307E\u3059",
                    React.createElement(AmazonPayButton, { classObject: styles.amazonPayButton, plan: vm.amazonPayPlan, config: vm.config, onClick: vm.onClickAmazonPayButton }))),
                React.createElement("form", null,
                    React.createElement(Input, { type: "text", label: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", value: vm.email, onChange: vm.onEmailChanged, labelClassObject: styles.inputLabel, placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B" }),
                    React.createElement(Input, { type: "password", label: "\u30D1\u30B9\u30EF\u30FC\u30C9", value: vm.passwordModel.passwordStr, onChange: vm.onPasswordChanged, labelClassObject: styles.inputLabel, placeholder: FORM_PASSWORD_PLACEHOLDER, enablePwVisible: true }),
                    React.createElement(CheckBox, { onChange: vm.toggleCheckbox, classObject: styles.checkbox, dataTest: "signupAgreeCheckbox" },
                        React.createElement("a", { className: css(styles.term), href: ZNEDESK_PRIVACY_POLICY_LINK, target: "_blank" }, "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC"),
                        "\u3068",
                        React.createElement(TermsOfService, { mode: vm.termsOfServiceMode, className: css(styles.term) }),
                        "\u306B\u540C\u610F\u3059\u308B"),
                    React.createElement(Button, { buttonType: vm.email && vm.passwordModel.passwordStr ? ButtonType.primary : ButtonType.transparentPrimary, className: css(styles.button), onClick: vm.submit, loading: vm.isSignupButtonLoading, disabled: vm.isSignupButtonDisabled, dataTest: "submit", fullWidth: true, isRadius: true }, "\u4ECA\u3059\u3050\u306F\u3058\u3081\u308B")),
                !isAndroidApp() && (React.createElement(AppleButton, { onClick: vm.onClickAppleSignUp, classObject: styles.snsButton, text: "Apple\u3067\u767B\u9332" })),
                React.createElement(LineButton, { disabled: vm.isSignupButtonDisabled, loading: vm.isLineSignInButtonLoading, onClick: vm.submitLineSignIn, classObject: styles.snsButton }, msg.BTN_SIGNUP_LINE),
                React.createElement(FacebookButton, { disabled: vm.isSignupButtonDisabled, loading: vm.isFBSignInButtonLoading, onClick: vm.submitFBSignIn, classObject: styles.snsButton }, msg.BTN_SIGNUP_FB),
                React.createElement("div", { className: css(styles.alreadyRegistered) }, "\u3059\u3067\u306B\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u304A\u6301\u3061\u306E\u65B9\u306F\u3053\u3061\u3089"),
                React.createElement(Button, { onClick: vm.onClickSignin, className: css(styles.signinButton), isRadius: true, fullWidth: true }, "\u30ED\u30B0\u30A4\u30F3")),
            React.createElement(FooterComponent, { termsOfServiceMode: vm.termsOfServiceMode }))));
});
var SignUpPageStatus;
(function (SignUpPageStatus) {
    SignUpPageStatus[SignUpPageStatus["initial"] = 0] = "initial";
    SignUpPageStatus[SignUpPageStatus["inValid"] = 1] = "inValid";
    SignUpPageStatus[SignUpPageStatus["loading"] = 2] = "loading";
    SignUpPageStatus[SignUpPageStatus["fbLoading"] = 3] = "fbLoading";
    SignUpPageStatus[SignUpPageStatus["lineLoading"] = 4] = "lineLoading";
    SignUpPageStatus[SignUpPageStatus["failed"] = 5] = "failed";
    SignUpPageStatus[SignUpPageStatus["finished"] = 6] = "finished";
})(SignUpPageStatus || (SignUpPageStatus = {}));
var SignUpPageVM = /** @class */ (function () {
    function SignUpPageVM(account, apiClient, tracker, router, alertPresenter, config, adlocateClient, tagClient, lineClient, gaClient) {
        var _this = this;
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lineClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gaClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: SignUpPageStatus.initial
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "passwordModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "termsOfServiceMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: TermsOfServiceMode.normal
        });
        Object.defineProperty(this, "checkbox", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "amazonPayPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, {
            status: observable,
            email: observable,
            passwordModel: observable,
            termsOfServiceMode: observable,
            checkbox: observable,
            amazonPayPlan: observable,
        });
        this.accountService = account;
        this.apiClient = apiClient;
        this.router = router;
        this.config = config;
        this.alertPresenter = alertPresenter;
        this.passwordModel = new PasswordVM();
        this.localStorage = new LocalStorage();
        this.tracker = tracker;
        this.lineClient = lineClient;
        this.gaClient = gaClient;
        if (this.localStorage.lpParamsHasMegalosCampaignCode) {
            this.termsOfServiceMode = TermsOfServiceMode.megalos;
        }
        this.sessionStorage = new SessionStorage();
        this.bindEvents();
        this.localStorage.saveIsBulkRegistration(false);
        tracker.trackPixelEvent(FBPixelEventName.EVENT_EF1);
        tagClient.embedAppleScript();
        adlocateClient.loadViewScript();
        // RELOCLUBユーザーのID保存
        this.saveRelolubKidIfShould();
        this.apiClient.fetchAmazonPayPlanForSignUp().then(function (v) {
            _this.amazonPayPlan = v;
        });
    }
    Object.defineProperty(SignUpPageVM.prototype, "onEmailChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.email = event.target.value;
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "onPasswordChanged", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.passwordModel.onPasswordChanged(event.target.value);
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            event.preventDefault();
            if (this.passwordModel.isPasswordInvalid) {
                return this.alertPresenter.showError(new LBError(), msg.PASSWORD_LENGTH);
            }
            if (!this.validateSubmit())
                return;
            this.gaClient.trackEmailSignupClick();
            this.status = SignUpPageStatus.loading;
            this.accountService
                .createUser({
                email: this.email,
                password: this.passwordModel.passwordStr,
            }, this.tracker)
                .then(function () {
                _this.localStorage.saveSignInType(SignInType.email);
                _this.status = SignUpPageStatus.finished;
                // location.searchのクエリパラメータはgaタグ等の分析用で使用してるので消さないように
                _this.router.historyPush("".concat(routes.CC_REGISTRATION.compile()).concat(location.search));
                _this.alertPresenter.showMessage(msg.SIGNUP_SUCCEEDED, 'signup_result');
            })
                .catch(function (err) {
                _this.status = SignUpPageStatus.failed;
                _this.alertPresenter.showError(err, msg.SIGNUP_FAILED, 'signup_result');
            });
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "submitFBSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _this = this;
            event.preventDefault();
            if (!this.validateSubmit())
                return;
            this.gaClient.trackFacebookSignupClick();
            this.status = SignUpPageStatus.fbLoading;
            this.accountService
                .fbSignIn(this.tracker, true)
                .then(function () {
                _this.status = SignUpPageStatus.finished;
                _this.alertPresenter.showMessage(msg.FB_SIGNUP_SUCCEEDED, 'signup_result');
                _this.router.push(routes.CC_REGISTRATION);
            })
                .catch(function (err) {
                _this.status = SignUpPageStatus.failed;
                _this.alertPresenter.showError(err, msg.FB_SIGNIN_FAILED, 'signup_result');
            });
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "submitLineSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            if (!this.validateSubmit())
                return;
            window.removeEventListener('beforeunload', efUnloadEvent);
            setTimeout(function () {
                _this.status = SignUpPageStatus.lineLoading;
                _this.lineClient.openLINEAuth(LineAuthType.signIn);
            }, 2);
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "onClickAppleSignUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var clientId, redirectUri;
                return __generator(this, function (_a) {
                    event.preventDefault();
                    if (!this.validateSubmit())
                        return [2 /*return*/];
                    this.gaClient.trackAppleSignupClick();
                    try {
                        clientId = this.config.signInWithAppleClientId;
                        redirectUri = this.config.signInWithAppleRedirectUri;
                        this.accountService.signInWithApple(clientId, redirectUri);
                    }
                    catch (err) {
                        this.alertPresenter.showError(err, msg.APPLE_SIGNIN_FAILED, 'signin_result');
                    }
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "onClickAmazonPayButton", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // AmazonPayはAmazonPayのログイン画面にLBの利用規約を表示しているので、LB側で利用規約に同意する必要なし
            this.gaClient.trackAmazonPaySignupClick();
            this.sessionStorage.setAmazonPayParams(null, null);
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "onClickSignin", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.router.push(routes.SIGNIN);
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "toggleCheckbox", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.checkbox = !this.checkbox;
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "validateSubmit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.checkbox) {
                this.alertPresenter.showError(new LBError(), 'プライバシーポリシーと利用規約に同意してください');
                return false;
            }
            return true;
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "isSignupButtonLoading", {
        get: function () {
            return this.status === SignUpPageStatus.loading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignUpPageVM.prototype, "isFBSignInButtonLoading", {
        get: function () {
            return this.status === SignUpPageStatus.fbLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignUpPageVM.prototype, "isLineSignInButtonLoading", {
        get: function () {
            return this.status === SignUpPageStatus.lineLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignUpPageVM.prototype, "isSignupButtonDisabled", {
        get: function () {
            if (this.isSignupButtonLoading || this.isFBSignInButtonLoading || this.isLineSignInButtonLoading)
                return true;
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignUpPageVM.prototype, "availableAmazonPaySignup", {
        get: function () {
            return !!this.amazonPayPlan;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SignUpPageVM.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            window.addEventListener('beforeunload', efUnloadEvent);
            this.router.locationChange$.pipe(take(1)).subscribe(function () {
                window.removeEventListener('beforeunload', efUnloadEvent);
            });
        }
    });
    Object.defineProperty(SignUpPageVM.prototype, "saveRelolubKidIfShould", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var kid = this.router.getQueryParams().get('kid');
            if (!kid)
                return;
            var reloclub = new ReloclubService(this.localStorage);
            reloclub.saveKidIfShould(kid);
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "onEmailChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "onPasswordChanged", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "submit", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "submitFBSignIn", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "submitLineSignIn", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], SignUpPageVM.prototype, "onClickAppleSignUp", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "onClickAmazonPayButton", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "onClickSignin", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], SignUpPageVM.prototype, "toggleCheckbox", null);
    return SignUpPageVM;
}());
export { SignUpPageVM };
var styles = StyleSheet.create({
    container: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 80,
        },
        _a),
    header: (_b = {},
        _b[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _b),
    contentWithFooter: (_c = {
            padding: '40px 24px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'calc(100vh - 50px)'
        },
        _c[MediaBreakPointUp.MD] = {
            padding: 0,
            minHeight: 'calc(100vh - 146px)',
        },
        _c),
    content: (_d = {
            width: '100%'
        },
        _d[MediaBreakPointUp.MD] = {
            borderRadius: 10,
            maxWidth: 375,
            margin: '0 auto 40px',
            padding: '40px 0',
        },
        _d),
    indicator: {
        margin: '0 auto 53px',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: '0.08em',
    },
    amazonPayButtonSection: {
        marginTop: 14,
        padding: 20,
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        border: "1px solid ".concat(Colors.PrimaryDark),
        borderRadius: 4,
    },
    amazonPayButtonTextBold: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    amazonPayButton: {
        marginTop: 14,
    },
    inputLabel: {
        color: Colors.Black,
        fontWeight: 'bold',
        marginTop: 30,
    },
    checkbox: {
        margin: '30px 0 16px',
        justifyContent: 'center',
    },
    term: {
        color: Colors.PrimaryDark,
        textDecoration: 'underline',
    },
    button: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 14,
    },
    snsButton: {
        marginTop: 14,
    },
    alreadyRegistered: {
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'center',
        margin: '30px 0 14px',
    },
    signinButton: (_e = {
            marginBottom: 50
        },
        _e[MediaBreakPointUp.MD] = {
            marginBottom: 0,
        },
        _e),
});
