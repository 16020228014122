import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { TriangleDirection, TriangleIcon } from '../atoms/icons/triangle_icon';
export var PaymentFormPriceDiff = function (_a) {
    var plans = _a.plans;
    if (plans.length < 2)
        return;
    var planAmountDiff = function () {
        return plans
            .map(function (v) { return (v.isAnnualPlan() ? -v.amountPerAnnual : v.amountPerAnnual); })
            .reduce(function (sum, num) { return sum + num; }, 0);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(TriangleIcon, { classObject: styles.traiangleIcon, color: Colors.Accent, direction: TriangleDirection.top }),
        React.createElement("div", { className: css(styles.content) },
            "1\u5E74\u3067",
            React.createElement("div", { className: css(styles.price) }, planAmountDiff().toLocaleString()),
            "\u5186\u304A\u30C8\u30AF\uFF01")));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    traiangleIcon: {
        width: 13,
        height: 12,
        marginBottom: -4,
    },
    content: {
        color: Colors.White,
        backgroundColor: Colors.Accent,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '100%',
        letterSpacing: 0.48,
        width: 'auto',
        height: 36,
        padding: '7px 12px 9px 14px',
        borderRadius: 20,
    },
    price: {
        fontSize: 22,
        letterSpacing: 1,
        margin: '0 1px 3px',
    },
});
