var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { NowRedirecting } from '@lean-body/components/organisms/now_redirecting';
import { useNavigate } from 'react-router-dom';
import { FBPixelEventName, LocalStorage, SessionStorage, SignInType, } from '@lean-body/src/infra';
import { routes } from '@lean-body/src/route';
import { GoalMatchingAssessmentPageQuery } from './main_goal_matching_assessment';
export var AMAZON_PAY_CALLBACK_QUERY_PARAMS = 'amazonCheckoutSessionId';
export var AmazonPayCallback = function (_a) {
    var apiClient = _a.apiClient, accountService = _a.accountService, signedCookieService = _a.signedCookieService, tracker = _a.tracker, adlocateClient = _a.adlocateClient;
    var navigate = useNavigate();
    var sessionStorage = new SessionStorage();
    var localStorage = new LocalStorage();
    React.useEffect(function () {
        var amazonPayParams = sessionStorage.loadAmazonPayParams();
        // プラン選択時にあったクエリパラメータを現在のクエリパラメータとmergeする
        var urlSearchParams = new URLSearchParams(__assign(__assign({}, Object.fromEntries(new URLSearchParams(location.search))), Object.fromEntries(new URLSearchParams(amazonPayParams.queryParams))));
        apiClient
            .registerAmazonPay(urlSearchParams.get(AMAZON_PAY_CALLBACK_QUERY_PARAMS), amazonPayParams.planId, localStorage.loadInvitationCode)
            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
            var me;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, accountService.afterCCRegister()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, accountService.fetchMe()];
                    case 2:
                        me = _a.sent();
                        signedCookieService.fetchSignedCookies(); // 動画を閲覧するための署名付きCookie取得
                        // BigQueryのuser_paymentsにログを送信
                        tracker.trackPixelEvent(FBPixelEventName.COMPLET_EREGISTRATION);
                        tracker.trackCCRegistered(me.id, me.payment, amazonPayParams.campaignCode, localStorage.loadReloclubKid);
                        adlocateClient.loadConversionScript(me.id, me.email);
                        localStorage.removeAfterCCRegistration();
                        if (localStorage.loadSignInType === SignInType.email) {
                            return [2 /*return*/, navigate(routes.CHECK_EMAIL.compile())];
                        }
                        else {
                            navigate("".concat(routes.GOAL_MATCHING_ASSESSMENT.compile(), "?").concat(GoalMatchingAssessmentPageQuery.new, "=1"));
                        }
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function (e) {
            // このページで更新ボタンを連打した場合、サーバーに2回目のリクエストが送信されて、後者のリクエストがエラーになってしまうので、
            // エラー後の遷移と一緒にページをリロードして、決済が成功してる場合、Topページに遷移させる.
            // AmazonPayの仕組みで、ここのAPIリクエストを何度送信しても二重決済にならない仕様になっています.
            window.location.href = "".concat(routes.CC_REGISTRATION.compile(), "?errorMessage=").concat(e.displayMessage);
        });
    }, []);
    return React.createElement(NowRedirecting, null);
};
