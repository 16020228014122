var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useEffect, useState } from 'react';
import { loadStripe, } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, } from '@stripe/react-stripe-js';
import { Colors } from '@lean-body/components/styles';
import { css, StyleSheet } from 'aphrodite';
import { CCBrandIcon, Input } from '../atoms';
import { useRefHooks } from '@lean-body/src/util';
import { FORM_PASSWORD_PLACEHOLDER } from './input_form_group/password_vm';
import { PaymentFormSubmitType } from '../organisms/payment_form';
import { RadioIcon } from '../atoms/icons/radio_icon';
var RegisterForm = function (_a) {
    var showErrorFromStripeInput = _a.showErrorFromStripeInput, authed = _a.authed, signedInAmazonPay = _a.signedInAmazonPay, amazonPayPlan = _a.amazonPayPlan, afterSubmit = _a.afterSubmit, beforeSubmit = _a.beforeSubmit, changeFilledAllForm = _a.changeFilledAllForm, hiddenInputRef = _a.hiddenInputRef, hiddenInputElement = _a.hiddenInputElement, children = _a.children, isOpenStripeSection = _a.isOpenStripeSection, onClickSection = _a.onClickSection, createSetupIntent = _a.createSetupIntent, onSubmitPlan = _a.onSubmitPlan;
    var stripe = useStripe();
    var elements = useElements();
    var _b = useState(''), email = _b[0], setEmail = _b[1];
    var _c = useState(''), password = _c[0], setPassword = _c[1];
    var _d = useState(false), cardNumber = _d[0], changeCardNumber = _d[1];
    var _e = useState(false), cardExpire = _e[0], changeCardExpire = _e[1];
    var _f = useState(false), cardCvc = _f[0], changeCardCvc = _f[1];
    var _g = useRefHooks(), ref = _g.ref, element = _g.element;
    useEffect(function () {
        changeFilledAllForm(authed ? cardNumber && cardExpire && cardCvc : email && password && cardNumber && cardExpire && cardCvc);
    }, [email, password, cardNumber, cardExpire, cardCvc]);
    useEffect(function () {
        // クレカ入力のformを最小化したときに、入力中の値に自動でscrollされてしまうので、されないように調整
        var scrollToTop = function () { return element.scrollTo(0, 0); };
        element === null || element === void 0 ? void 0 : element.addEventListener('scroll', scrollToTop);
        return function () { return element === null || element === void 0 ? void 0 : element.removeEventListener('scroll', scrollToTop); };
    }, [element]);
    var options = function (placeholder) {
        return {
            placeholder: placeholder,
            style: {
                base: {
                    color: Colors.Black,
                    fontSize: '16px',
                    lineHeight: '20px',
                    '::placeholder': {
                        color: Colors.GrayDarkest,
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var validated, isSecondaryPlan, setupIntent, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    validated = beforeSubmit(true);
                    if (!validated)
                        return [2 /*return*/];
                    isSecondaryPlan = hiddenInputElement.value === PaymentFormSubmitType.secondaryPlan;
                    return [4 /*yield*/, createSetupIntent()];
                case 1:
                    setupIntent = _a.sent();
                    return [4 /*yield*/, stripe.confirmCardSetup(setupIntent.clientSecret, {
                            payment_method: { card: elements.getElement(CardNumberElement) },
                        })];
                case 2:
                    result = _a.sent();
                    if (result.error) {
                        showErrorFromStripeInput([result.error.message]);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, onSubmitPlan(email, password, result.setupIntent.payment_method, isSecondaryPlan)];
                case 3:
                    _a.sent();
                    afterSubmit();
                    return [2 /*return*/];
            }
        });
    }); };
    var onEmailChanged = function (event) {
        setEmail(event.target.value);
    };
    var onPasswordChanged = function (event) {
        setPassword(event.target.value);
    };
    var onChangeCardNumber = function (event) {
        changeCardNumber(event.complete);
        if (event.complete) {
            elements.getElement(CardExpiryElement).focus();
        }
    };
    var onChangeCardExpire = function (event) {
        changeCardExpire(event.complete);
        if (event.complete) {
            elements.getElement(CardCvcElement).focus();
        }
    };
    var onChangeCardCvc = function (event) {
        changeCardCvc(event.complete);
    };
    return (React.createElement(React.Fragment, null, signedInAmazonPay && amazonPayPlan ? (React.createElement("div", { className: css(styles.form) }, children)) : (React.createElement("form", { className: css(styles.form), onSubmit: handleSubmit },
        React.createElement("input", { type: "hidden", ref: hiddenInputRef }),
        React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.header, authed && amazonPayPlan && styles.selectableHeader), onClick: authed ? onClickSection : undefined },
                authed && amazonPayPlan && React.createElement(RadioIcon, { checked: isOpenStripeSection, onClick: onClickSection }),
                "\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9\u3067\u304A\u652F\u6255\u3044"),
            React.createElement("div", { className: css(styles.content, !isOpenStripeSection && styles.hidden) },
                React.createElement("div", { className: css(styles.section), ref: ref },
                    !authed && (React.createElement(React.Fragment, null,
                        React.createElement(Input, { type: "text", label: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", value: email, onChange: onEmailChanged, labelClassObject: styles.inputLabel, placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B" }),
                        React.createElement("div", { className: css(styles.inputWithAnnotation) },
                            React.createElement(Input, { type: "password", label: "\u30D1\u30B9\u30EF\u30FC\u30C9", value: password, onChange: onPasswordChanged, labelClassObject: styles.inputLabel, placeholder: FORM_PASSWORD_PLACEHOLDER, enablePwVisible: true }),
                            React.createElement("div", { className: css(styles.passwordAnnotation) }, "\u534A\u89D2\u82F1\u6570\u5B576\u6841\u4EE5\u4E0A\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002")))),
                    React.createElement("div", { className: css(styles.box) },
                        React.createElement("div", { className: css(styles.label) },
                            "\u30AB\u30FC\u30C9\u756A\u53F7",
                            React.createElement(CCBrandIcon, { classObject: styles.blandIconContainer, iconClassObject: styles.blandIcon })),
                        React.createElement("div", { className: css(styles.input) },
                            React.createElement(CardNumberElement, { options: options('0000 0000 0000 0000'), onChange: onChangeCardNumber }))),
                    React.createElement("div", { className: css(styles.box) },
                        React.createElement("div", { className: css(styles.label) }, "\u6709\u52B9\u671F\u9650"),
                        React.createElement("div", { className: css(styles.input) },
                            React.createElement(CardExpiryElement, { options: options('月 / 年'), onChange: onChangeCardExpire }))),
                    React.createElement("div", { className: css(styles.box) },
                        React.createElement("div", { className: css(styles.label) }, "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30FC\u30B3\u30FC\u30C9"),
                        React.createElement("div", { className: css(styles.input) },
                            React.createElement(CardCvcElement, { options: options('000'), onChange: onChangeCardCvc })),
                        React.createElement("div", { className: css(styles.annotation) }, "\u30AB\u30FC\u30C9\u88CF\u306E\u6570\u5B573\u6841\u307E\u305F\u306F4\u6841"))))),
        children))));
};
export var SignUpForm = function (_a) {
    var stripeAPIKey = _a.stripeAPIKey, showErrorFromStripeInput = _a.showErrorFromStripeInput, authed = _a.authed, signedInAmazonPay = _a.signedInAmazonPay, basePlan = _a.basePlan, amazonPayPlan = _a.amazonPayPlan, afterSubmit = _a.afterSubmit, beforeSubmit = _a.beforeSubmit, changeFilledAllForm = _a.changeFilledAllForm, hiddenInputRef = _a.hiddenInputRef, hiddenInputElement = _a.hiddenInputElement, children = _a.children, isOpenStripeSection = _a.isOpenStripeSection, onClickSection = _a.onClickSection, createSetupIntent = _a.createSetupIntent, onSubmitPlan = _a.onSubmitPlan;
    var stripePromise = React.useState(function () { return loadStripe(stripeAPIKey); })[0];
    return (React.createElement(Elements, { stripe: stripePromise },
        React.createElement(RegisterForm, { showErrorFromStripeInput: showErrorFromStripeInput, authed: authed, signedInAmazonPay: signedInAmazonPay, basePlan: basePlan, amazonPayPlan: amazonPayPlan, beforeSubmit: beforeSubmit, afterSubmit: afterSubmit, changeFilledAllForm: changeFilledAllForm, hiddenInputRef: hiddenInputRef, hiddenInputElement: hiddenInputElement, isOpenStripeSection: isOpenStripeSection, onClickSection: onClickSection, createSetupIntent: createSetupIntent, onSubmitPlan: onSubmitPlan }, children)));
};
var styles = StyleSheet.create({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
    },
    container: {
        borderRadius: 10,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    header: {
        color: Colors.TextDark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '100%',
        width: '100%',
        height: 52,
        padding: '16px 20px',
    },
    selectableHeader: {
        justifyContent: 'flex-start',
        gap: 8,
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 14,
        padding: '0 14px 16px',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: 14,
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
    inputLabel: {
        color: Colors.Black,
        fontWeight: 'bold',
    },
    inputWithAnnotation: {
        width: '100%',
    },
    passwordAnnotation: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginTop: 4,
    },
    blandIconContainer: {
        display: 'flex',
        gap: 6,
        marginLeft: 8,
    },
    blandIcon: {
        height: 18,
        margin: 0,
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        display: 'flex',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
        height: 20,
        marginBottom: 8,
    },
    input: {
        position: 'relative',
        width: '100%',
        border: "1px solid ".concat(Colors.Gray),
        '-webkit-appearance': 'none',
        borderRadius: 4,
        height: 50,
        padding: '15px 12px',
    },
    annotation: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginTop: 4,
    },
});
