var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, MediaBreakPointUp } from '../styles';
import { Input } from '../atoms';
import { ChatTextSendIcon } from '../atoms/icons/chat_text_send_icon';
export var UnsubscribeChatbotTextInput = function (_a) {
    var value = _a.value, setValue = _a.setValue, onClick = _a.onClick;
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement(Input, { containerClassObject: styles.inputContainer, classObject: styles.input, placeholder: "\u3054\u5165\u529B\u304F\u3060\u3055\u3044\u3002", value: value, onChange: function (e) { return setValue(e.target.value); }, height: 44 }),
        React.createElement("div", { className: css(styles.sendIconContainer) },
            React.createElement(ChatTextSendIcon, { classObject: styles.sendIcon, color: !value && Colors.BorderLightGray, onClick: onClick }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'fixed',
            display: 'flex',
            left: 0,
            bottom: 0,
            width: '100%'
        },
        _a[MediaBreakPointUp.MD] = {
            position: 'absolute',
        },
        _a),
    inputContainer: {
        flex: 1,
    },
    input: {
        height: 44,
        borderRadius: 0,
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    sendIconContainer: {
        backgroundColor: Colors.White,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 81,
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    sendIcon: {
        cursor: 'pointer',
    },
});
