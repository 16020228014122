var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { bind } from 'bind-decorator';
import { Link } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { Badge, UserProfile, BadgeType, UserSeasonAggregate, } from '@lean-body/src/domain';
import { BadgeOrUserSeasonRankDialog, TwitterFormDialog } from '@lean-body/src/view/molecules';
import { ActivityCalendarBlock, PageLoading } from '@lean-body/components/organisms';
import { APISequences } from '@lean-body/src/interfaces';
import { BadgeListSection } from '@lean-body/components/organisms/badge_list_section';
import { CertificateListSection } from '@lean-body/components/organisms/certificate_list_section';
import { LifetimeActivityRank } from '../organisms/lifetime_activity_rank';
import { ActivitySectionTitle } from '@lean-body/components/atoms';
export var ActivityPage = observer(function (_a) {
    var vm = _a.vm;
    var batchListSectionHeaderRef = React.useRef();
    var certificateListSectionHeaderRef = React.useRef();
    if (!vm.profile)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.summaryTab) },
            vm.profile && vm.calendar && (React.createElement(React.Fragment, null,
                React.createElement(ActivitySectionTitle, { title: "\u30EC\u30C3\u30B9\u30F3\u5C65\u6B74\u30AB\u30EC\u30F3\u30C0\u30FC", classObject: styles.activitySectionTitle }),
                React.createElement(ActivityCalendarBlock, { calendar: vm.calendar, year: vm.year, month: vm.month, onClickNext: vm.onClickNext, onClickPrev: vm.onClickPrev, fetchDailyActivity: vm.apiClient.fetchDailyActivity, classObject: styles.sectionContainer }))),
            vm.badges && (React.createElement(React.Fragment, null,
                React.createElement(BadgeListSection, { badges: vm.normalBadges, header: 'バッジ', subHeader: '個のバッジを獲得しました！その調子です！', headerRef: batchListSectionHeaderRef, onClick: vm.onClickBadge }),
                React.createElement(ActivitySectionTitle, { title: "\u30E9\u30F3\u30AF", classObject: styles.activitySectionTitle }),
                React.createElement(Link, { to: routes.USER_SEASON_RANK_HELP.compile() },
                    React.createElement(LifetimeActivityRank, { classObject: styles.rankContainer, userSeasonAggregate: vm.currentUserSeasonAggregate })),
                React.createElement(CertificateListSection, { badges: vm.challengeBadges, header: '達成したプレイリスト', subHeader: '個の修了証を獲得しました！その調子です！', headerRef: certificateListSectionHeaderRef, onClick: vm.onClickBadge })))),
        (vm.badgeForDialog || vm.userSeasonAggregateForDialog) && (React.createElement(BadgeOrUserSeasonRankDialog, { badge: vm.badgeForDialog, userSeasonAggregate: vm.userSeasonAggregateForDialog, onClickCancel: vm.closeBadgeDialog, twitterService: vm.twitterService, onClickTwitterShareIfAuthed: vm.onClickTwitterShareIfAuthed })),
        vm.isShowTwitterFormDialog && (React.createElement(TwitterFormDialog, { imageURL: vm.twitterService.tweetImgSrc, centerImageWidth: vm.twitterService.tweetCenterImageWidth, defaultText: vm.twitterService.tweetDefaultText, onClickCancel: vm.onClickTwitterPostCancel, submit: vm.onClickTwitterPost }))));
});
var ActivityPageVM = /** @class */ (function () {
    function ActivityPageVM(accountService, tracker, apiClient, router, alertPresenter, config, twitterService) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "router", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "calendar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "profile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "me", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "year", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "month", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "badges", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "currentUserSeasonAggregate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // 現在進行中のシーズンアクティビティ
        Object.defineProperty(this, "pastUserSeasonAggregates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        }); // 過去のシーズンアクティビティ
        Object.defineProperty(this, "badgeForDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userSeasonAggregateForDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "twitterService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isShowTwitterFormDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this, {
            calendar: observable.ref,
            profile: observable.ref,
            me: observable.ref,
            year: observable,
            month: observable,
            badges: observable.ref,
            currentUserSeasonAggregate: observable,
            pastUserSeasonAggregates: observable,
            badgeForDialog: observable,
            userSeasonAggregateForDialog: observable,
            isShowTwitterFormDialog: observable,
        });
        this.accountService = accountService;
        this.tracker = tracker;
        this.apiClient = apiClient;
        this.router = router;
        this.alertPresenter = alertPresenter;
        this.config = config;
        this.twitterService = twitterService;
        var d = new Date();
        this.year = d.getFullYear();
        this.month = d.getMonth() + 1;
        this.load();
        if (this.twitterService.showFormDialog) {
            this.isShowTwitterFormDialog = true;
            this.twitterService.showFormDialog = false;
        }
    }
    Object.defineProperty(ActivityPageVM.prototype, "userName", {
        get: function () {
            return this.profile.userName || 'No Name';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ActivityPageVM.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Promise.all([this.loadCalendar(), this.loadProfile(), this.fetchMe()])];
                        case 1:
                            _a.sent();
                            this.apiClient.fetchBadges().then(function (badges) {
                                _this.badges = badges;
                            });
                            this.apiClient.getUserSeasonAggregates().then(function (v) {
                                _this.currentUserSeasonAggregate = v.shift();
                                _this.pastUserSeasonAggregates = v;
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "fetchMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.accountService.fetchMe().then(function (me) {
                            _this.me = me;
                        })];
                });
            });
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "loadProfile", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var next, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.apiClient.appendToSequence(APISequences.LESSON_FINISH_AND_PATCH_PROFILE)];
                        case 1:
                            next = _b.sent();
                            _a = this;
                            return [4 /*yield*/, this.apiClient.patchProfile()];
                        case 2:
                            _a.profile = _b.sent();
                            next();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "setProfile", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            this.profile = new UserProfile(__assign(__assign({}, this.profile), value), this.config);
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "loadCalendar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.apiClient.fetchActivityCalendar(this.year, this.month).then(function (calendar) {
                            _this.calendar = calendar;
                        })];
                });
            });
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "normalBadges", {
        // バッジ取得
        get: function () {
            return this.badges.filter(function (badge) { return badge.type === BadgeType.normal; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ActivityPageVM.prototype, "challengeBadges", {
        // 修了証取得
        get: function () {
            return this.badges.filter(function (badge) { return badge.type === BadgeType.challenge; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickPrev", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.month--;
            if (this.month < 1) {
                this.month = 12;
                this.year--;
            }
            this.loadCalendar();
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickNext", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.month++;
            if (this.month > 12) {
                this.month = 1;
                this.year++;
            }
            this.loadCalendar();
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "closeBadgeDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.badgeForDialog = null;
            this.userSeasonAggregateForDialog = null;
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickBadge", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (badge) {
            this.badgeForDialog = badge;
            this.twitterService.sendBqBadgeOpenDialog(badge);
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickUserSeasonAggregate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userSeasonAggregate) {
            this.userSeasonAggregateForDialog = userSeasonAggregate;
            this.twitterService.sendBqRankOpenDialog(userSeasonAggregate.userSeasonAggregateRank);
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickTwitterShareIfAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = true;
            this.closeBadgeDialog();
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickTwitterPost", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (text, file) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    this.twitterService.postTweet(text, file).finally(function () {
                        _this.isShowTwitterFormDialog = false;
                    });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(ActivityPageVM.prototype, "onClickTwitterPostCancel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isShowTwitterFormDialog = false;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "setProfile", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "onClickPrev", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "onClickNext", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "closeBadgeDialog", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Badge]),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "onClickBadge", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [UserSeasonAggregate]),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "onClickUserSeasonAggregate", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "onClickTwitterShareIfAuthed", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, File]),
        __metadata("design:returntype", Promise)
    ], ActivityPageVM.prototype, "onClickTwitterPost", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActivityPageVM.prototype, "onClickTwitterPostCancel", null);
    return ActivityPageVM;
}());
export { ActivityPageVM };
var styles = StyleSheet.create({
    container: {
        margin: '0 30px',
        paddingBottom: 20,
    },
    summaryTab: (_a = {
            margin: '0 auto'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 484,
            paddingBottom: 40,
        },
        _a[MediaBreakPointUp.LG] = {
            width: 940,
        },
        _a),
    sectionContainer: {
        marginBottom: 14,
    },
    rankContainer: {
        backgroundColor: Colors.White,
        borderRadius: 10,
        marginBottom: 14,
        border: "1px solid ".concat(Colors.BorderLightGray),
    },
    activitySectionTitle: {
        margin: '24px 0px 8px',
    },
});
