var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { FormError } from '../../errors';
import * as msg from '@lean-body/src/messages';
import { CampaignCodeFormSection, CorporationFormSection } from '../organisms';
import { ArrowDirection, ArrowIconWithMargin, TermsOfService, TermsOfServiceMode } from '../atoms';
import { Colors } from '@lean-body/components/styles';
import { ZNEDESK_PRIVACY_POLICY_LINK } from '../constants';
import { useRefHooks } from '@lean-body/src/util';
import { PlanAmountText } from '../atoms/plan_amount_text';
import { PaymentFormButton } from '../molecules/payment_form_button';
import { PaymentFormPriceDiff } from '../molecules/payment_form_price_diff';
import { SignUpForm } from '../molecules/signup_form';
import { PaymentFormAmazonPay } from '../molecules/payment_form_amazon_pay';
export var PaymentFormSections = {
    amazonPay: 'amazonPay',
    stripe: 'stripe',
};
export var PaymentFormSubmitType = {
    firstPlan: 'firstPlan',
    secondaryPlan: 'secondaryPlan',
};
export var PaymentForm = function (_a) {
    var config = _a.config, alertPresenter = _a.alertPresenter, basePlan = _a.basePlan, secondaryBasePlan = _a.secondaryBasePlan, campaign = _a.campaign, campaignPlan = _a.campaignPlan, withCampaignParams = _a.withCampaignParams, employeeCode = _a.employeeCode, employeeName = _a.employeeName, authed = _a.authed, signedInAmazonPay = _a.signedInAmazonPay, isCampaignSimplifiedView = _a.isCampaignSimplifiedView, setEmployeeCode = _a.setEmployeeCode, setEmployeeName = _a.setEmployeeName, fetchCampaignWithPlans = _a.fetchCampaignWithPlans, createSetupIntent = _a.createSetupIntent, onSubmitPlan = _a.onSubmitPlan, onSubmitAmazonPay = _a.onSubmitAmazonPay;
    var termsOfServiceMode = localStorage.lpParamsHasMegalosCampaignCode
        ? TermsOfServiceMode.megalos
        : TermsOfServiceMode.normal;
    var hiddenInput = useRefHooks();
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), filledAllForm = _c[0], setFilledAllForm = _c[1];
    var _d = React.useState(false), isOpenSecondaryPlan = _d[0], setIsOpenSecondaryPlan = _d[1];
    var firstPlan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.plan;
    var secondaryPlan = secondaryBasePlan === null || secondaryBasePlan === void 0 ? void 0 : secondaryBasePlan.plan;
    var plans = [firstPlan, secondaryPlan].filter(function (v) { return v; });
    var amazonPayPlan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.amazonPayPlan;
    var _e = React.useState(null), section = _e[0], setSection = _e[1];
    var selectedAmazonPaySection = section === PaymentFormSections.amazonPay;
    useEffect(function () {
        var shouldUseAmazonPay = amazonPayPlan && ((signedInAmazonPay && section === null) || selectedAmazonPaySection);
        setSection(shouldUseAmazonPay ? PaymentFormSections.amazonPay : PaymentFormSections.stripe);
    }, [signedInAmazonPay, amazonPayPlan]);
    var onChangeEmployeeCode = function (e) {
        setEmployeeCode(e.target.value.trim());
    };
    var onChangeEmployeeName = function (e) {
        setEmployeeName(e.target.value.trim());
    };
    var beforeSubmit = function (isLoading) {
        setIsLoading(isLoading);
        return true;
    };
    var showErrorFromStripeInput = function (errors) {
        if (errors.length > 0) {
            var err = new FormError(void 0, errors.join('\n'));
            alertPresenter.showError(err, msg.INVALID_INPUT);
            setIsLoading(false);
            return;
        }
    };
    var afterSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsLoading(false);
            return [2 /*return*/];
        });
    }); };
    var onClickOpenSecondaryPlan = function () {
        setIsOpenSecondaryPlan(!isOpenSecondaryPlan);
    };
    var onClickBasePlan = function (e) {
        // AmazonPayでログインしているユーザーで、AmazonPayの支払いを実行した際に、
        // stripe の form submit が実行されないように preventDefault()
        if (signedInAmazonPay && selectedAmazonPaySection) {
            setIsLoading(true);
            e.preventDefault();
            onSubmitAmazonPay(basePlan.amazonPayPlan.id);
        }
        else {
            // Stripeの送信は StripeCardForm で handleSubmit()を実行している
            // どちらのプランを選択したか StripeCardForm で判定できるように、value をセットしている
            hiddenInput.element.value = PaymentFormSubmitType.firstPlan;
        }
    };
    var onClickSecondaryPlan = function (e) {
        // AmazonPayでログインしているユーザーで、AmazonPayの支払いを実行した際に、
        // stripe の form submit が実行されないように preventDefault()
        if (signedInAmazonPay && selectedAmazonPaySection) {
            setIsLoading(true);
            e.preventDefault();
            onSubmitAmazonPay(secondaryBasePlan.amazonPayPlan.id);
        }
        else {
            // Stripeの送信は StripeCardForm で handleSubmit()を実行している
            // どちらのプランを選択したか StripeCardForm で判定できるように、value をセットしている
            hiddenInput.element.value = PaymentFormSubmitType.secondaryPlan;
        }
        // "他のプランを表示する"が閉じないように実行
        e.stopPropagation();
    };
    return (React.createElement(React.Fragment, null,
        authed && amazonPayPlan && (React.createElement(PaymentFormAmazonPay, { classObject: styles.amazonPayFormAbove, checked: selectedAmazonPaySection, signedInAmazonPay: signedInAmazonPay, amazonPayPlan: amazonPayPlan, onClickSection: function () { return setSection(PaymentFormSections.amazonPay); } })),
        React.createElement(SignUpForm, { stripeAPIKey: config.stripeAPIKey, showErrorFromStripeInput: showErrorFromStripeInput, authed: authed, signedInAmazonPay: signedInAmazonPay, basePlan: basePlan, amazonPayPlan: amazonPayPlan, afterSubmit: afterSubmit, beforeSubmit: beforeSubmit, changeFilledAllForm: setFilledAllForm, hiddenInputRef: hiddenInput.ref, hiddenInputElement: hiddenInput.element, isOpenStripeSection: !selectedAmazonPaySection, onClickSection: function () { return setSection(PaymentFormSections.stripe); }, createSetupIntent: createSetupIntent, onSubmitPlan: onSubmitPlan },
            React.createElement("div", { className: css(styles.campaignCodeSection, campaign && styles.campaignSection) },
                (firstPlan === null || firstPlan === void 0 ? void 0 : firstPlan.isCorporationPlan()) && (React.createElement(CorporationFormSection, { employeeCode: employeeCode, employeeName: employeeName, onChangeEmployeeCode: onChangeEmployeeCode, onChangeEmployeeName: onChangeEmployeeName })),
                React.createElement(CampaignCodeFormSection, { classObject: styles.campaignCode, isBulkRegistration: true, isCampaignSimplifiedView: isCampaignSimplifiedView, campaignPlan: campaignPlan, defaultCampaign: campaign, withCampaignParams: withCampaignParams, onSubmit: fetchCampaignWithPlans })),
            React.createElement("div", { className: css(styles.submitBasePlanSection) },
                (!campaignPlan || isCampaignSimplifiedView) && (React.createElement("div", null, "\u203B\u81EA\u52D5\u66F4\u65B0\u3068\u306A\u308B\u305F\u3081\u3001\u7D99\u7D9A\u3092\u3054\u5E0C\u671B\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u8A2D\u5B9A\u304B\u3089\u9000\u4F1A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002")),
                !signedInAmazonPay && (React.createElement("div", null,
                    "\u4E0A\u8A18\u5185\u5BB9\u306B\u304A\u9593\u9055\u3044\u304C\u306A\u3051\u308C\u3070\u3001",
                    React.createElement("a", { className: css(styles.term), href: ZNEDESK_PRIVACY_POLICY_LINK, target: "_blank" }, "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC"),
                    "\u3068",
                    React.createElement(TermsOfService, { mode: termsOfServiceMode, className: css(styles.term) }),
                    "\u3092\u3054\u78BA\u8A8D\u306E\u4E0A\u3001\u4E0B\u8A18\u306E\u30DC\u30BF\u30F3\u3088\u308A\u304A\u9032\u307F\u304F\u3060\u3055\u3044\u3002")),
                React.createElement(PaymentFormButton, { config: config, selectedSection: section, signedInAmazonPay: signedInAmazonPay, isLoading: isLoading, filledAllForm: filledAllForm, basePlan: basePlan, campaign: campaign, onClick: onClickBasePlan, dataTest: "submit" }),
                (!campaignPlan || isCampaignSimplifiedView) && React.createElement(PlanAmountText, { campaign: campaign, plan: firstPlan }),
                isOpenSecondaryPlan && React.createElement(PaymentFormPriceDiff, { plans: plans })),
            secondaryBasePlan && (React.createElement(React.Fragment, null,
                React.createElement("div", { onClick: onClickOpenSecondaryPlan, "data-test": "openSecondaryPlan" },
                    React.createElement("div", { className: css(styles.displaySecondaryPlan) },
                        "\u4ED6\u306E\u30D7\u30E9\u30F3\u3092\u8868\u793A\u3059\u308B",
                        React.createElement(ArrowIconWithMargin, { classObject: styles.arrowIcon, direction: isOpenSecondaryPlan ? ArrowDirection.top : ArrowDirection.bottom }))),
                isOpenSecondaryPlan && (React.createElement("div", null,
                    React.createElement(PaymentFormButton, { config: config, selectedSection: section, signedInAmazonPay: signedInAmazonPay, isLoading: isLoading, filledAllForm: filledAllForm, basePlan: secondaryBasePlan, campaign: campaign, onClick: onClickSecondaryPlan, dataTest: "submitSecondaryPlan" }),
                    React.createElement(PlanAmountText, { classObject: styles.planAmountText, campaign: campaign, plan: secondaryPlan }))))))));
};
var styles = StyleSheet.create({
    amazonPayFormAbove: {
        marginBottom: 14,
    },
    campaignCodeSection: {
        margin: '14px 0',
    },
    campaignSection: {
        margin: 0,
    },
    campaignCode: {
        padding: '16px 0',
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    submitBasePlanSection: {
        color: Colors.Black,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.5,
        gap: 8,
        margin: '10px 0',
    },
    planSection: {
        marginTop: 0,
    },
    term: {
        color: Colors.Black,
        textDecoration: 'underline',
    },
    displaySecondaryPlan: {
        color: Colors.PrimaryDark,
        display: 'flex',
        justifyContent: 'center',
        gap: 8,
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.56,
    },
    arrowIcon: {
        width: 20,
        height: 20,
    },
    planAmountText: {
        marginTop: 8,
    },
});
