var _a;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ArrowIcon } from '../atoms/icons/arrow_icon';
import { Link } from 'react-router-dom';
import * as routes from '@lean-body/src/route';
import { TermsOfService, TermsOfServiceMode } from '../atoms';
import { CloseIcon } from '@lean-body/components/atoms/icons/close_icon';
import { Colors, MediaBreakPointUp } from '../styles';
import { Avatar } from '@mui/material';
import { isAndroidApp, useSmMediaQuery } from '@lean-body/src/util';
import { settingMenuWidth } from '@lean-body/src/view/styles/utils';
import { CORPORATE_LINK, ZENDESK_QA_LINK, ZENDESK_REQUESTS_LINK, ZENDESK_SPECIFIED_COMMERCIAL_TRANSACTION_LAW_LINK, ZNEDESK_PRIVACY_POLICY_LINK, } from '../constants';
import { SessionStorage } from '@lean-body/src/infra/session_storage';
export var SettingItems = {
    mypage: null,
    goal: {
        icon: 'goal_setting',
        text: '目標設定',
        link: "".concat(routes.GOAL_MATCHING_ASSESSMENT.compile(), "?change=1"), //FIXME: GoalMatchingAssessmentPageQueryをimportすると動かなくなるので直で書いてます
    },
    healthCare: { icon: 'connect', text: 'ヘルスコネクト連携' },
    email: { icon: 'email', text: 'メールアドレス変更' },
    password: { icon: 'password', text: 'パスワード変更' },
    notification: { icon: 'bell', text: '通知の設定' },
    zendesk: { icon: 'question', text: 'よくある質問', link: ZENDESK_QA_LINK, dataTest: 'zendeskQA' },
    requests: {
        icon: 'paper_airplane',
        text: 'ご意見・お問い合わせ',
        link: ZENDESK_REQUESTS_LINK,
        dataTest: 'zendeskQA',
    },
    plan: { icon: 'plan', text: 'ご登録中のプラン' },
    payment: { icon: 'payment', text: 'お支払い方法の変更' },
    initialGuide: { icon: 'initial_guide', text: 'はじめてガイド', link: routes.INITIAL_GUIDE.compile() },
    friendInvitaion: {
        icon: 'friend_invitation',
        text: 'お友達紹介',
        subText: 'ギフト券をGET！',
        link: routes.INVITATION.compile(),
        dataTest: 'friendInvitaion',
    },
    corporate: { icon: 'corporate', text: '運営会社', link: CORPORATE_LINK },
    term: { icon: 'term', text: '利用規約' },
    privacyPolicy: { icon: 'privacy_policy', text: 'プライバシーポリシー', link: ZNEDESK_PRIVACY_POLICY_LINK },
    specifiedCommercialTransactionsLaw: {
        icon: 'information',
        text: '特定商取引法に基づく表記',
        link: ZENDESK_SPECIFIED_COMMERCIAL_TRANSACTION_LAW_LINK,
    },
};
export var SettingItemKeys = {
    goal: 'goal',
    mypage: 'mypage',
    editMypage: 'editMypage',
    email: 'email',
    password: 'password',
    notification: 'notification',
    zendesk: 'zendesk',
    requests: 'requests',
    plan: 'plan',
    payment: 'payment',
    initialGuide: 'initialGuide',
    friendInvitaion: 'friendInvitaion',
    corporate: 'corporate',
    term: 'term',
    privacyPolicy: 'privacyPolicy',
    specifiedCommercialTransactionsLaw: 'specifiedCommercialTransactionsLaw',
    androidAccountDeletion: 'androidAccountDeletion',
    healthCare: 'healthCare',
};
export var SettingMenu = function (_a) {
    var classObject = _a.classObject, currentItem = _a.currentItem, onClick = _a.onClick, onClickClose = _a.onClickClose, isStripeUser = _a.isStripeUser, isMegalosUser = _a.isMegalosUser, me = _a.me, onClickSignOut = _a.onClickSignOut, profile = _a.profile;
    var termsOfServiceMode = isMegalosUser ? TermsOfServiceMode.megalos : TermsOfServiceMode.normal;
    var isSM = useSmMediaQuery();
    var onClickLink = function (e) {
        e.stopPropagation();
        onClickClose && onClickClose();
    };
    var sessionStorage = new SessionStorage();
    var isDisplayHealthCare = isAndroidApp();
    var keys = Object.keys(SettingItems)
        .map(function (v) {
        // クレカ変更はstripeユーザーのみの機能
        if (v === SettingItemKeys.payment && !isStripeUser)
            return null;
        // 目標設定は目標設定済みのユーザのみに表示
        if (v === SettingItemKeys.goal && !profile.isGoalsSet)
            return null;
        // 通知はAndroidアプリユーザーのみ表示
        if (v === SettingItemKeys.notification && !isAndroidApp())
            return null;
        // ヘルスケア連携はAndroidアプリユーザーのみ表示
        if (v === SettingItemKeys.healthCare && !isDisplayHealthCare)
            return null;
        if (v === SettingItemKeys.friendInvitaion && !me.canFriendInvitation)
            return null;
        return v;
    })
        .filter(function (v) { return v; });
    var isActive = function (key) {
        return currentItem === key || (key === SettingItemKeys.mypage && currentItem === SettingItemKeys.editMypage);
    };
    var iconSrc = function (key) {
        var _a;
        return ((_a = SettingItems[key]) === null || _a === void 0 ? void 0 : _a.icon)
            ? "/assets/images/icons/".concat(SettingItems[key].icon).concat(isActive(key) ? '_light_blue' : '', ".svg")
            : '';
    };
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.titlebox) },
                React.createElement("div", { className: css(styles.titleForItem) }, "\u8A2D\u5B9A"),
                isSM && onClickClose && React.createElement(CloseIcon, { color: Colors.Black, onClick: onClickClose })),
            React.createElement("ul", null,
                keys.map(function (key, i) {
                    var _a;
                    return (React.createElement("li", { className: css(styles.menuItem, key === SettingItemKeys.goal && !isDisplayHealthCare && styles.sectionEnd, key === SettingItemKeys.healthCare && styles.sectionEnd, key === SettingItemKeys.mypage && styles.sectionEnd, key === SettingItemKeys.initialGuide && styles.sectionEnd, key === SettingItemKeys.friendInvitaion && styles.sectionEnd), key: i, onClick: function () { return onClick(key); }, "data-test": (_a = SettingItems[key]) === null || _a === void 0 ? void 0 : _a.dataTest },
                        key === SettingItemKeys.term ? (React.createElement(TermsOfService, { mode: termsOfServiceMode, className: css(styles.text), stopPropagation: true, iconSrc: iconSrc(key), iconClassObject: styles.icon })) : key === SettingItemKeys.mypage ? (React.createElement(React.Fragment, null,
                            React.createElement(Avatar, { src: profile.pictureSrc, className: css(styles.avatarSize, profile.profilePictureSrc && styles.avatarBorder) }),
                            React.createElement("div", { className: css(styles.pictureText, isActive(key) && styles.activeText) }, profile.initialName))) : key === SettingItemKeys.plan ? (React.createElement("div", { className: css(styles.text, isActive(key) && styles.activeText) },
                            React.createElement("img", { src: iconSrc(key), className: css(styles.icon) }),
                            SettingItems.plan.text,
                            me.isDormant && (React.createElement(React.Fragment, null,
                                "\uFF08",
                                React.createElement("span", { className: css(styles.textAccent) }, "\u4F11\u7720\u4E2D"),
                                "\uFF09")))) : SettingItems[key].link ? (React.createElement(React.Fragment, null, SettingItems[key].link.includes('https') ? (React.createElement("a", { href: SettingItems[key].link, target: "_blank", className: css(styles.text), onClick: onClickLink },
                            React.createElement("img", { src: iconSrc(key), className: css(styles.icon) }),
                            SettingItems[key].text)) : (React.createElement(Link, { to: SettingItems[key].link, className: css(styles.text), onClick: onClickLink },
                            React.createElement("img", { src: iconSrc(key), className: css(styles.icon) }),
                            React.createElement("div", null,
                                React.createElement("p", null, SettingItems[key].text),
                                React.createElement("p", { className: css(styles.subText) }, SettingItems[key].subText)))))) : (React.createElement(React.Fragment, null,
                            SettingItems[key].icon && React.createElement("img", { src: iconSrc(key), className: css(styles.icon) }),
                            React.createElement("div", { className: css(styles.text, isActive(key) && styles.activeText) }, SettingItems[key].text))),
                        !isSM && React.createElement(ArrowIcon, { classObject: styles.arrow, color: Colors.Black })));
                }),
                React.createElement("li", { className: css(styles.menuItem, styles.signOut), onClick: onClickSignOut },
                    React.createElement("img", { src: "/assets/images/icons/logout.svg", className: css(styles.icon) }),
                    React.createElement("div", { className: css(styles.text, styles.signOutText) }, "\u30ED\u30B0\u30A2\u30A6\u30C8"),
                    !isSM && React.createElement(ArrowIcon, { classObject: styles.arrow, color: Colors.Black })),
                isAndroidApp() ? (React.createElement(React.Fragment, null,
                    React.createElement(Link, { to: routes.ANDROID_DELETE_ACCOUNT.compile(), className: css(styles.menuItem, styles.deleteAccount), onClick: onClickLink },
                        React.createElement("img", { src: "/assets/images/icons/logout.svg", className: css(styles.icon) }),
                        React.createElement("div", { className: css(styles.text, styles.deleteAccountText) }, "\u30A2\u30AB\u30A6\u30F3\u30C8\u524A\u9664"),
                        !isSM && React.createElement(ArrowIcon, { classObject: styles.arrow, color: Colors.Black })),
                    React.createElement("li", { className: css(styles.menuItem, styles.version), onClick: onClickSignOut },
                        React.createElement("div", { className: css(styles.text, styles.versionText) },
                            "ver:",
                            sessionStorage.loadAndroidAppVersion())))) : null))));
};
var styles = StyleSheet.create({
    container: (_a = {
            width: '100%',
            backgroundColor: Colors.White,
            transition: 'all 0.2s ease'
        },
        _a[MediaBreakPointUp.XL] = {
            position: 'fixed',
            width: settingMenuWidth.xl,
            height: '100vh',
            overflow: 'auto',
            borderRight: "1px solid ".concat(Colors.BorderLightGray),
        },
        _a),
    menuItem: {
        height: 60,
        paddingLeft: 24,
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            backgroundColor: Colors.BackgroundLightGray,
        },
    },
    signOut: {
        marginTop: 24,
    },
    notificationSetting: {
        marginTop: 24,
    },
    deleteAccount: {
        marginTop: 24,
    },
    version: {
        marginBottom: 60,
    },
    signOutText: {
        color: isAndroidApp() ? null : Colors.Accent,
    },
    icon: {
        width: 24,
        marginRight: 16,
    },
    text: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.Black,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.6,
    },
    subText: {
        fontSize: 12,
        color: Colors.GrayDarkest,
    },
    activeText: {
        color: Colors.PrimaryDarkB,
    },
    sectionEnd: {
        marginBottom: 24,
    },
    pictureText: {
        fontSize: 16,
        fontWeight: 'bold',
        marginLeft: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginRight: 40,
    },
    textAccent: {
        color: Colors.Accent,
    },
    arrow: {
        height: 9,
        width: 15,
        position: 'absolute',
        top: '50%',
        right: 20,
        transform: 'translateY(-50%) rotate(90deg)',
    },
    avatarSize: {
        width: 48,
        height: 48,
    },
    avatarBorder: {
        border: "0.5px solid ".concat(Colors.Gray),
    },
    titlebox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 20px 16px 24px',
    },
    titleForItem: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    deleteAccountText: {
        color: Colors.Accent,
    },
    versionText: {
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
    },
});
