var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable */
import axios from 'axios';
import * as Bowser from 'bowser';
import { v4 as uuidv4 } from 'uuid';
import { BigQueryError } from '../errors';
// 今後の分析データはlb_datasetというデータセットにためていくため、こちらを使うようにする。
var DEFAULT_DATASET_NAME = 'lb_dataset';
/**
 * CloudFunctionsに行動イベントを送信する
 * @class
 */
var CloudFunctionClient = /** @class */ (function () {
    function CloudFunctionClient(endpoint, authToken, localStorage) {
        var _this = this;
        Object.defineProperty(this, "endpoint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "axios", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "os", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sessionId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initialReferrer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.axios = axios.create();
        this.axios.interceptors.request.use(function (req) {
            if (_this.authToken) {
                var header = {
                    Authorization: authToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                };
                req.headers = Object.assign({}, req.headers, header);
            }
            return req;
        });
        this.axios.interceptors.response.use(function (res) {
            return res;
        }, function (error) {
            _this.onError(error);
        });
        this.authToken = authToken;
        this.endpoint = endpoint;
        this.localStorage = localStorage;
        this.setFixedParameters();
    }
    Object.defineProperty(CloudFunctionClient.prototype, "setReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.localStorage.loadStatsReferrer) {
                this.localStorage.saveReferrer(document.referrer);
            }
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "setInitialReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (initialReferrer) {
            if (!this.initialReferrer) {
                this.initialReferrer = initialReferrer;
            }
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "sendBqEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (eventName, datasetName, data) {
            return __awaiter(this, void 0, void 0, function () {
                var param, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            param = new URLSearchParams();
                            param.append('type', eventName);
                            param.append('datasetName', datasetName);
                            param.append('data', JSON.stringify(data));
                            return [4 /*yield*/, this.axios.post(this.endpoint, param)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "onError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (error) {
            var e = error;
            if (!e.response) {
                throw e;
            }
            switch (e.response.status) {
                // 401のみ許容している
                case 401:
                    console.log('Unauthorized.');
                    break;
                // 503はsentryに送信しないようにエラーを返さないようにする
                case 503:
                    console.log('Unavailable.');
                    break;
            }
            throw e;
        }
    });
    /*------ tracking ------*/
    /**
     * PVイベント
     * @param userId
     * @param sessionId
     */
    Object.defineProperty(CloudFunctionClient.prototype, "trackView", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, sessionId) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.userId) {
                                this.userId = userId;
                            }
                            if (!this.sessionId) {
                                this.sessionId = sessionId;
                            }
                            params = Object.assign({}, this.commonParameters, {
                                userId: userId,
                                sessionId: sessionId,
                                referrer: this.localStorage.loadStatsReferrer,
                                initialReferrer: this.initialReferrer,
                            });
                            return [4 /*yield*/, this.sendBqEvent('views', 'views', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    /**
     * ビデオ再生イベントに再生時間などのオプションを付与。
     */
    Object.defineProperty(CloudFunctionClient.prototype, "trackVideoPlayStats", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, isAutoPlay, stats) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, __assign({ userId: userId, sessionId: this.sessionId, isAutoPlay: isAutoPlay }, stats));
                            return [4 /*yield*/, this.sendBqEvent('movie_play_stats', 'movie_play_stats', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    /**
     * ビデオ再生イベント
     * @param userId
     * @param stats
     */
    Object.defineProperty(CloudFunctionClient.prototype, "trackVideoPlay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, stats) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, __assign({ userId: userId, sessionId: this.sessionId }, stats));
                            return [4 /*yield*/, this.sendBqEvent('movie_plays', 'movie_plays', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    /**
     * ビデオ一時停止イベント
     * @param userId
     * @param stats
     * @param time
     */
    Object.defineProperty(CloudFunctionClient.prototype, "trackVideoStop", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, stats, time) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, __assign({ userId: userId, pauseTime: time, sessionId: this.sessionId }, stats));
                            return [4 /*yield*/, this.sendBqEvent('movie_stops', 'movie_stops', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    /**
     * signupイベント
     * @param userId
     * @param email
     */
    Object.defineProperty(CloudFunctionClient.prototype, "trackSignUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, email) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                id: userId,
                                email: email,
                                sessionId: this.sessionId,
                                referrer: this.localStorage.loadStatsReferrer,
                                initialReferrer: this.initialReferrer,
                            });
                            return [4 /*yield*/, this.sendBqEvent('users', 'users', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    /**
     * signinイベント
     * @param userId
     * @param email
     * @param authMethod
     */
    Object.defineProperty(CloudFunctionClient.prototype, "trackSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, email, authMethod) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: userId,
                                email: email,
                                authMethod: authMethod,
                                sessionId: this.sessionId,
                                referrer: this.localStorage.loadStatsReferrer,
                                initialReferrer: this.initialReferrer,
                            });
                            return [4 /*yield*/, this.sendBqEvent('signin', 'signin', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "ccRegister", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, ccRegisterParams) {
            return __awaiter(this, void 0, void 0, function () {
                var curPeriodEndTime, params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            curPeriodEndTime = this.formatTimestampByClient(ccRegisterParams.curPeriodEndTime);
                            params = {
                                id: uuidv4(),
                                createdAt: this.reduceTimestamp(new Date().getTime()),
                                userId: userId,
                                trialStartTime: this.reduceTimestamp(new Date().getTime()),
                                referrer: this.localStorage.loadStatsReferrer,
                                cardLastFour: ccRegisterParams.cardLastFour,
                                curPeriodEndTime: curPeriodEndTime,
                                subPlanID: ccRegisterParams.subPlanId,
                                trialEndTime: ccRegisterParams.trialEndTime,
                                stripeCustomerId: '',
                                initialReferrer: this.initialReferrer,
                                CampaignCode: ccRegisterParams.campaignCode,
                                reloclubKid: ccRegisterParams.reloclubKid,
                            };
                            return [4 /*yield*/, this.sendBqEvent('user_payments', 'user_payments', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackBecomeDormant", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = {
                                createdAt: this.formatTimestamp(),
                                id: uuidv4(),
                                sessionId: this.sessionId,
                                user_id: this.userId,
                            };
                            return [4 /*yield*/, this.sendBqEvent('dormant_start', 'dormant_start', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickSearchPartCard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tagId) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                tagId: tagId,
                            });
                            return [4 /*yield*/, this.sendBqEvent('search_part_card_click', 'search_part_card_click', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickSearchStyleCard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (styleID) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                styleID: styleID,
                            });
                            return [4 /*yield*/, this.sendBqEvent('search_style_card_click', 'search_style_card_click', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackSubmitSearch", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (word) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                word: word,
                            });
                            return [4 /*yield*/, this.sendBqEvent('search_word', 'search_word', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickSearchResultProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programID) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                programID: programID,
                            });
                            return [4 /*yield*/, this.sendBqEvent('search_result_program_click', 'search_result_program_click', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickSearchResultLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonID) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                lessonId: lessonID,
                            });
                            return [4 /*yield*/, this.sendBqEvent('search_result_lesson_click', 'search_result_lesson_click', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackFavoriteLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonId, status) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                lessonId: lessonId,
                                status: status,
                            });
                            return [4 /*yield*/, this.sendBqEvent('favorite_lessons', 'favorite_lessons', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackFavoriteProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, status) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                programId: programId,
                                status: status,
                            });
                            return [4 /*yield*/, this.sendBqEvent('favorite_programs', 'favorite_programs', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackReceivedBadge", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (badgeId, badgeName, badgeType) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: this.userId,
                                sessionId: this.sessionId,
                                badgeId: badgeId,
                                badgeName: badgeName,
                                badgeType: badgeType,
                            });
                            return [4 /*yield*/, this.sendBqEvent('received_badges', 'received_badges', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickMatchingAssessmentStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: this.userId,
                                sessionId: this.sessionId,
                            });
                            return [4 /*yield*/, this.sendBqEvent('matching_assessment_start_clicks', 'matching_assessment_start_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickChallengeStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, start, end) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: this.userId,
                                sessionId: this.sessionId,
                                programId: programId,
                                challengeStart: start,
                                challengeEnd: end,
                            });
                            return [4 /*yield*/, this.sendBqEvent('challenge_start_clicks', 'challenge_start_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickUpdateChallengeStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, status, start, end) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: this.userId,
                                sessionId: this.sessionId,
                                programId: programId,
                                status: status,
                                challengeStart: start,
                                challengeEnd: end,
                            });
                            return [4 /*yield*/, this.sendBqEvent('challenge_update_status_clicks', 'challenge_update_status_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickPlanChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (plan, me) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: this.userId,
                                sessionId: this.sessionId,
                                currentPlanId: me.payment.subPlanId,
                                curPeriodEndDate: me.payment.curPeriodEndDate,
                                trialEndDate: me.payment.trialEndTime && new Date(me.payment.trialEndTime),
                                selectedQueuedPlanId: plan.stripePlanId,
                                selectedQueuedPlanName: plan.name,
                            });
                            return [4 /*yield*/, this.sendBqEvent('plan_changes', 'plan_changes', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackClickRemovePlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (me) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                userId: this.userId,
                                sessionId: this.sessionId,
                                currentPlanId: me.payment.subPlanId,
                                curPeriodEndDate: me.payment.curPeriodEndDate,
                                trialEndDate: me.payment.trialEndTime && new Date(me.payment.trialEndTime),
                                selectedQueuedPlanId: me.payment.subPlanId,
                            });
                            return [4 /*yield*/, this.sendBqEvent('plan_changes', 'plan_changes', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackHomeBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (banner) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = Object.assign({}, this.commonParameters, {
                                sessionId: this.sessionId,
                                userId: this.userId,
                                bannerName: 'id: ' + banner.id,
                                lessonId: banner.lessonId,
                                programId: banner.programId,
                                liveLessonId: banner.liveLessonId,
                                announcementId: banner.announcementId,
                                // NOTE: BQでlinkPathカラム削除したら、同名のカラムは7日間使用できなかったのでbaseLinkPathという名前にした
                                baseLinkPath: banner.linkPath,
                            });
                            return [4 /*yield*/, this.sendBqEvent('home_banner_clicks', 'home_banner_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackPlayLiveVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (liveUrl, liveTitle, startPositionTime) {
            return __awaiter(this, void 0, void 0, function () {
                var params, res, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId, sessionId: this.sessionId, liveUrl: liveUrl, liveTitle: liveTitle, startPositionTime: startPositionTime });
                            return [4 /*yield*/, this.sendBqEvent('live_video_plays', 'live_video_plays', params)];
                        case 1:
                            res = _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            err_1 = _a.sent();
                            console.error(err_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackOriginalPlaylistBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pageName) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId, pageName: pageName });
                            return [4 /*yield*/, this.sendBqEvent('original_playlist_banner_clicks', 'original_playlist_banner_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackOriginalPlaylistCreate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId });
                            return [4 /*yield*/, this.sendBqEvent('original_playlist_create_clicks', 'original_playlist_create_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackFirstViewCarouselClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkPath) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId, linkPath: linkPath });
                            return [4 /*yield*/, this.sendBqEvent('first_view_carousel_clicks', 'first_view_carousel_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackFirstViewBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkPath) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId, linkPath: linkPath });
                            return [4 /*yield*/, this.sendBqEvent('first_view_banner_clicks', 'first_view_banner_clicks', params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackHomeClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId, name: name });
                            return [4 /*yield*/, this.sendBqEvent('home_clicks', DEFAULT_DATASET_NAME, params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackInvitaionLinkCopy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId });
                            return [4 /*yield*/, this.sendBqEvent('invitation_link_copy_clicks', DEFAULT_DATASET_NAME, params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackSnsShareClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (snsParams) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign(__assign({}, this.commonParameters), { userId: this.userId }), snsParams);
                            return [4 /*yield*/, this.sendBqEvent('sns_share_clicks', DEFAULT_DATASET_NAME, params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackCancelUnsubscribeBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId });
                            return [4 /*yield*/, this.sendBqEvent('cancel_unsubscribe_banner_clicks', DEFAULT_DATASET_NAME, params)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackUnsubscribeChatbotLogs", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chatbotSessionId, sectionId, descriptionId, choiceId, inputData, nextSectionId, isStart, isUnsubscribeButtonClick) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    params = __assign(__assign({}, this.commonParameters), { userId: this.userId, sessionId: chatbotSessionId, sectionId: sectionId, descriptionId: descriptionId, choiceId: choiceId, inputData: inputData, nextSectionId: nextSectionId, isStart: isStart, isUnsubscribeButtonClick: isUnsubscribeButtonClick });
                    return [2 /*return*/, this.sendBqEvent('unsubscribe_chatbot_logs', DEFAULT_DATASET_NAME, params)];
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackMypageProfileUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (profile) {
            return __awaiter(this, void 0, void 0, function () {
                var params, worryPromises, interestPromises;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = __assign(__assign({}, this.commonParameters), { userId: this.userId, userName: profile.userName, birthday: profile.birthday, gender: profile.gender, prefecture: profile.prefecture, height: profile.height, weight: profile.weight, exerciseFrequencyType: profile.exerciseFrequency.name });
                            return [4 /*yield*/, this.sendBqEvent('mypage_profile_updates', DEFAULT_DATASET_NAME, params)
                                // mypage_profile_updatesに紐づく「悩み」と「興味」のレコードも作成する
                            ];
                        case 1:
                            _a.sent();
                            worryPromises = profile.worries.map(function (worry) { return _this.trackMypageProfileWorryUpdate(params.id, worry.id); });
                            interestPromises = profile.interests.map(function (interest) {
                                return _this.trackMypageProfileInterestUpdate(params.id, interest.id);
                            });
                            return [4 /*yield*/, Promise.all(__spreadArray(__spreadArray([], worryPromises, true), interestPromises, true))];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackMypageProfileWorryUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (mypageProfileUpdateId, worryId) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    params = {
                        id: uuidv4(),
                        mypageProfileUpdateId: mypageProfileUpdateId,
                        worryId: worryId,
                        createdAt: this.formatTimestamp(),
                    };
                    return [2 /*return*/, this.sendBqEvent('mypage_profile_worry_updates', DEFAULT_DATASET_NAME, params)];
                });
            });
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "trackMypageProfileInterestUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (mypageProfileUpdateId, interestId) {
            return __awaiter(this, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    params = {
                        id: uuidv4(),
                        mypageProfileUpdateId: mypageProfileUpdateId,
                        interestId: interestId,
                        createdAt: this.formatTimestamp(),
                    };
                    return [2 /*return*/, this.sendBqEvent('mypage_profile_interest_updates', DEFAULT_DATASET_NAME, params)];
                });
            });
        }
    });
    /*------ Private utilities ------*/
    Object.defineProperty(CloudFunctionClient.prototype, "setFixedParameters", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setOS();
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "commonParameters", {
        get: function () {
            return {
                browser: this.browser,
                id: uuidv4(),
                createdAt: this.formatTimestamp(),
                currentUrl: document.URL,
                os: this.os,
                ua: window.navigator.userAgent.toLocaleLowerCase(),
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CloudFunctionClient.prototype, "browser", {
        get: function () {
            var ua = Bowser.parse(window.navigator.userAgent);
            var platform = ua.platform.type;
            return "".concat(ua.browser.name, "(").concat(platform, ") ").concat(ua.browser.version);
        },
        enumerable: false,
        configurable: true
    });
    // バンドルのjsのサイズを減らしたいのであえてmomentを使っていない
    // NOTE: BQ上ではUTCと表記されるがJSTです
    Object.defineProperty(CloudFunctionClient.prototype, "formatTimestamp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var d = new Date();
            var y = d.getFullYear();
            var mon = this.numPadding(d.getMonth() + 1);
            var date = this.numPadding(d.getDate());
            var h = this.numPadding(d.getHours());
            var min = this.numPadding(d.getMinutes());
            var s = this.numPadding(d.getSeconds());
            return "".concat(y, "-").concat(mon, "-").concat(date, " ").concat(h, ":").concat(min, ":").concat(s);
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "formatTimestampByClient", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (n) {
            var d = new Date(n);
            var y = d.getFullYear();
            var mon = this.numPadding(d.getMonth() + 1);
            var date = this.numPadding(d.getDate());
            var h = this.numPadding(d.getHours());
            var min = this.numPadding(d.getMinutes());
            var s = this.numPadding(d.getSeconds());
            return "".concat(y, "-").concat(mon, "-").concat(date, " ").concat(h, ":").concat(min, ":").concat(s);
        }
    });
    // jsデフォルトのstringPaddingが使えないブラウザがあるので自前定義
    Object.defineProperty(CloudFunctionClient.prototype, "numPadding", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (n) {
            return n < 10 ? "0".concat(n) : n;
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "setOS", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var ua = Bowser.parse(window.navigator.userAgent);
            this.os = "".concat(ua.os.name, "-").concat(ua.os.version);
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "reduceTimestamp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (n) {
            return parseInt(String(n / 1000));
        }
    });
    Object.defineProperty(CloudFunctionClient.prototype, "checkError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (response) {
            if (response.includes('FAILED'))
                throw new BigQueryError();
        }
    });
    return CloudFunctionClient;
}());
export { CloudFunctionClient };
