import { Route, RouteGroup as group } from './base';
export var TOP = Route('/');
export var SIGNUP = Route('/signup');
export var SIGNUP_V2 = Route('/signup_v2');
export var SIGNIN = Route('/signin');
export var CC_REGISTRATION = Route('/cc_registration');
export var GOAL_MATCHING_ASSESSMENT = Route('/goal_matching_assessment');
export var PROGRAM = Route('/programs/:slug').with();
export var PROGRAMS = Route('/programs');
export var PLAYLIST = Route('/playlists/:id').with();
export var PLAYLISTS = Route('/playlists');
export var ORIGINAL_PLAYLIST = Route('/original_playlists/:id').with();
export var ORIGINAL_PLAYLIST_UPDATE = Route('/original_playlists/:id/edit').with();
export var NEW_PLAYLIST = Route('/new_playlist');
export var LESSON = Route('/lessons/:slug/:type').with();
export var PLAN_CHANGE = Route('/account/plan_change');
export var PLAN_CHANGE_UNSUBSCRIBE_CP = Route('/account/plan_change_unsubscribe_cp');
export var COACHING_PLAN_UNSUBSCRIBE = Route('/account/coaching_plan_unsubscribe');
export var INVITATION = Route('/invitation');
export var EMAIL_VERIFICATION = Route('/email_verification');
export var UNSUBSCRIBE_CAUTION = Route('/unsubscribe_caution');
export var UNSUBSCRIBE_CONFIRM = Route('/unsubscribe_confirm');
export var PASSWORD_RESET_REQUEST = Route('/password_reset_request');
export var PASSWORD_RESET = Route('/password_reset');
export var ACTIVITY = Route('/activity');
export var MAGAZINE_UNSUBSCRIBE = Route('/magazine_unsubscribe/:token').with();
export var SEARCH = Route('/search');
export var MERCHANDISE = Route('/merchandise');
export var RANKING = Route('/ranking');
export var CALLBACK_LINE = Route('/line_callback');
export var CALLBACK_CONNECT_LINE = Route('/line_connect_callback');
export var CALLBACK_APPLE = Route('/apple_callback');
export var CALLBACK_AMAZON_PAY = Route('/amazon_pay_callback');
export var CALLBACK_AMAZON_PAY_FOR_SIGNUP = Route('/amazon_pay_callback_for_signup');
export var SEARCH_RESULT = Route('/search/result');
export var TRAINERS = Route('/trainers');
export var INSTRUCTOR = Route('/instructor/:id').with();
export var LP_IFRAME = Route('/lp_iframe');
export var CHECK_EMAIL = Route('/check_email');
export var MEGALOS_LIVE_CALENDAR = Route('/megalos_live_calendar');
export var FUTURE_USER = Route('/future_user');
export var ANNOUNCEMENT_LIST = Route('/announcements');
export var ANNOUNCEMENT_DETAIL = Route('/announcements/:id').with();
export var LIVE_SCHEDULE = Route('/live_schedule');
export var LIVE_LESSON = Route('/live_lessons/:id').with();
export var USER_SEASON_RANK_HELP = Route('/user_season_rank_help');
export var INITIAL_GUIDE = Route('/initial_guide');
export var INITIAL_GUIDE_DETAIL = Route('/initial_guide_detail');
export var LIVE_LESSON_VIMEO = Route('/live_lessons/:id/vimeo').with();
export var DORMANT = Route('/dormant');
export var TWITTER_CALLBACK = Route('/twitter_callback');
export var BILLYS_STUDIO = Route('/billys_studio');
export var SETTING = Route('/setting');
export var MY_LESSON = Route('/my_lesson');
export var MAINTENANCE = Route('/maintenance');
export var COURSE = Route('/courses').with();
export var COURSE_VOLUME = Route('/courses/volumes/:id').with();
export var CHANGE_COURSE = Route('/change_course');
export var COURSE_REWARD = Route('/rewards/course_days/:courseDayID').with();
export var DAILY_TASKS = Route('/daily_tasks/:taskName').with();
export var DAILY_TASKS_SUBTASK = Route('/daily_tasks/:taskName/:subtaskName').with();
export var CHART_TASKS = Route('/chart_tasks/:taskName/:chartType').with();
export var ANDROID_DELETE_ACCOUNT = Route('/delete_account');
export var COACHING = Route('/coaching');
export var NUTRIENTS = Route('/nutrients');
export var MEAL_SCORE = Route('/meal_score');
export var TV_LOGIN = Route('/tv_login');
export var ProgramType;
(function (ProgramType) {
    ProgramType["normal"] = "normal";
})(ProgramType || (ProgramType = {}));
export var LessonType;
(function (LessonType) {
    LessonType["normal"] = "normal";
})(LessonType || (LessonType = {}));
export var DailyTasksPageTaskName = {
    meal: 'meal',
    workout: 'workout',
    steps: 'steps',
    weight: 'weight',
};
export var MealSubtaskPageName = {
    breakfast: 'breakfast',
    lunch: 'lunch',
    dinner: 'dinner',
    snacking: 'snacking',
};
export var ChartTaskPageChartType = {
    weekly: 'weekly',
    monthly: 'monthly',
    quarterly: 'quarterly',
};
/**
 * 認証不要画面のRouteGroup
 */
export var UNRESTRICTED_ROUTE_GROUP = group([
    SIGNUP,
    SIGNUP_V2,
    SIGNIN,
    EMAIL_VERIFICATION,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET,
    CALLBACK_LINE,
    CALLBACK_APPLE,
    CALLBACK_AMAZON_PAY_FOR_SIGNUP,
    LP_IFRAME,
]);
/**
 * 認証画面のRouteGroup
 */
export var AUTH_ROUTE_GROUP = group([SIGNUP, SIGNUP_V2, SIGNIN]);
/**
 * クレカ登録関連のRouteGroup
 */
export var SELECT_PAGE = group([SIGNUP_V2, CC_REGISTRATION, CALLBACK_AMAZON_PAY, CALLBACK_AMAZON_PAY_FOR_SIGNUP]);
export var FUTURE_USER_PAGE = group([FUTURE_USER]);
/**
 * 休眠会員の人がアクセスできるRouteGroup
 */
export var DORMANT_ALLOWED_ROUTE_GROUP = group([
    PLAN_CHANGE,
    UNSUBSCRIBE_CAUTION,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET,
    EMAIL_VERIFICATION,
    SETTING,
]);
export var AB_TEST_ROUTE_GROUP = group([SIGNUP, PROGRAM, LESSON]);
export var ORIGINAL_PLAYLIST_GROUP = group([ORIGINAL_PLAYLIST, ORIGINAL_PLAYLIST_UPDATE]);
export var LIVE_GROUP = group([LIVE_LESSON, LIVE_SCHEDULE]);
export var SEARCH_GROUP = group([SEARCH, SEARCH_RESULT, PROGRAMS, PLAYLISTS, MERCHANDISE, RANKING]);
export var ANNOUNCEMENT_GROUP = group([ANNOUNCEMENT_LIST, ANNOUNCEMENT_DETAIL]);
/**
 * 認証ステータスによってリダイレクトを行わないRouteGroup
 */
export var NOT_HANDLE_REDIRECT_GROUP = group([MAINTENANCE, MAGAZINE_UNSUBSCRIBE]);
export var COURSE_DAY_LESSON_ID_QUERY_PARAM_KEY = 'course_day_lesson_id';
export var generateLessonQueryParams = function (courseDayLessonID) {
    return courseDayLessonID == null ? '' : "?".concat(COURSE_DAY_LESSON_ID_QUERY_PARAM_KEY, "=").concat(courseDayLessonID);
};
