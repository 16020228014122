var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import * as msg from '@lean-body/src/messages';
import { take } from 'rxjs/operators';
import { efUnloadEvent, isAndroidApp, useQuery, useSmMediaQuery } from '@lean-body/src/util';
import { CAMPAIGN_CODE_RESET, FBPixelEventName, LocalStorage, SessionStorage, SessionStorageType, SignInType, } from '@lean-body/src/infra';
import { LBError } from '@lean-body/src/errors';
import { BASIC_CAMPAIGN_PRICE, CorporationEmployeeParams, Payment, ReloclubService, SignedCookieService, } from '@lean-body/src/domain';
import { PageLoading } from '../organisms';
import { FooterComponent } from '../organisms/footer';
import { PasswordVM } from '../molecules/input_form_group/password_vm';
import { PaymentForm } from '../organisms/payment_form';
import { Button, Logo, TermsOfServiceMode } from '../atoms';
import { AmazonPayButton } from '../atoms/amazon_pay_button';
import { Colors, MediaBreakPointUp } from '../styles';
import { AMAZON_PAY_CALLBACK_QUERY_PARAMS } from './amazon_pay_callback';
import { AuthStatus } from '@lean-body/src/interfaces';
import { GoalMatchingAssessmentPageQuery } from './main_goal_matching_assessment';
var AMAZON_PAY_CHECKOUT_ERROR = 'AmazonPayの決済に失敗しました。時間をおいてから再度お試しください。';
export var SignUpV2Page = function (_a) {
    var _b;
    var accountService = _a.accountService, apiClient = _a.apiClient, tracker = _a.tracker, router = _a.router, alertPresenter = _a.alertPresenter, config = _a.config, adlocateClient = _a.adlocateClient, tagClient = _a.tagClient, gaClient = _a.gaClient;
    var isSM = useSmMediaQuery();
    var query = useQuery();
    var navigate = useNavigate();
    var authed = function () {
        return accountService.authStatus === AuthStatus.NoSubscription;
    };
    var localStorage = new LocalStorage();
    var sessionStorage = new SessionStorage();
    var signedCookieService = new SignedCookieService(apiClient);
    var defaultCampaignCode = localStorage.loadCampaingCode;
    var signedInAmazonPay = !!query.get(AMAZON_PAY_CALLBACK_QUERY_PARAMS) && authed();
    var termsOfServiceMode = localStorage.lpParamsHasMegalosCampaignCode
        ? TermsOfServiceMode.megalos
        : TermsOfServiceMode.normal;
    var _c = useState(null), me = _c[0], setMe = _c[1];
    var _d = useState(null), amazonPayPlan = _d[0], setAmazonPayPlan = _d[1];
    var _e = useState(null), basePlan = _e[0], setBasePlan = _e[1];
    var _f = useState(null), secondaryBasePlan = _f[0], setSecondaryBasePlan = _f[1];
    var _g = useState(null), campaign = _g[0], setCampaign = _g[1];
    var _h = useState(null), campaignPlan = _h[0], setCampaignPlan = _h[1];
    var _j = useState(null), friendInvitationCampaign = _j[0], setFriendInvitationCampaign = _j[1];
    var _k = useState(), employeeCode = _k[0], setEmployeeCode = _k[1];
    var _l = useState(), employeeName = _l[0], setEmployeeName = _l[1];
    var _m = useState(true), isLoading = _m[0], setIsLoading = _m[1];
    var _o = useState(false), withCampaignParams = _o[0], setWithCampaignParams = _o[1];
    var isCampaignSimplifiedView = (campaignPlan === null || campaignPlan === void 0 ? void 0 : campaignPlan.amount) !== BASIC_CAMPAIGN_PRICE;
    useEffect(function () {
        if (defaultCampaignCode) {
            fetchCampaignWithPlans(defaultCampaignCode, true).then(function () { return setIsLoading(false); });
        }
        else {
            fetchDefaultPlans().then(function () { return setIsLoading(false); });
        }
        apiClient.fetchAmazonPayPlanForSignUp().then(function (v) { return setAmazonPayPlan(v); });
        authed() && accountService.fetchMe().then(function (me) { return setMe(me); });
        bindEvents();
        tracker.trackPixelEvent(FBPixelEventName.EVENT_EF1);
        tagClient.embedAppleScript();
        adlocateClient.loadViewScript();
        saveRelolubKidIfShould();
        localStorage.saveIsBulkRegistration(true);
    }, []);
    var fetchDefaultPlans = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, apiClient
                    .fetchRegistablePlans(query.get('campaign_code') == CAMPAIGN_CODE_RESET)
                    .then(function (basePlans) {
                    setBasePlan(basePlans[0]);
                    setSecondaryBasePlan(findSecondaryBasePlan(basePlans));
                })
                    .catch(function () {
                    alertPresenter.showMessage(msg.PLAN_INDEX_FAILED);
                })];
        });
    }); };
    var fetchCampaignWithPlans = function (campaignCode, withParams) { return __awaiter(void 0, void 0, void 0, function () {
        var campaign, basePlans, secondaryPlan, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, apiClient.fetchCampaignWithPlans(campaignCode).catch(function () {
                        alertPresenter.showMessage(msg.CAMPAIGN_CODE_NOT_FOUND);
                    })];
                case 1:
                    campaign = _d.sent();
                    if (!campaign)
                        return [2 /*return*/, null];
                    basePlans = campaign.basePlans;
                    setCampaign(campaign);
                    setCampaignPlan((_c = (_b = campaign === null || campaign === void 0 ? void 0 : campaign.basePlans) === null || _b === void 0 ? void 0 : _b.find(function (v) { var _a; return (_a = v.plan) === null || _a === void 0 ? void 0 : _a.isAnnualPlan(); })) === null || _c === void 0 ? void 0 : _c.plan);
                    setBasePlan(basePlans[0]);
                    setWithCampaignParams(withParams);
                    secondaryPlan = findSecondaryBasePlan(basePlans);
                    setSecondaryBasePlan(secondaryPlan ? secondaryPlan : null);
                    if (!campaign.isFriendInvitation) return [3 /*break*/, 3];
                    _a = setFriendInvitationCampaign;
                    return [4 /*yield*/, apiClient.getFriendInvitationCampaign()];
                case 2:
                    _a.apply(void 0, [_d.sent()]);
                    _d.label = 3;
                case 3: return [2 /*return*/, campaign];
            }
        });
    }); };
    var findSecondaryBasePlan = function (plans) {
        // プランが2件以上ない場合は null を返す
        if (plans.length < 2)
            return null;
        // 他のプランとして表示するプランは月額プランのみを対象にする
        return plans.find(function (v) { return v.plan.isStandardPlan(); });
    };
    var createSetupIntent = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.createSetupIntent()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var onClickAmazonPayButton = function () {
        // AmazonPayはAmazonPayのログイン画面にLBの利用規約を表示しているので、LB側で利用規約に同意する必要なし
        gaClient.trackAmazonPaySignupClick();
        sessionStorage.setAmazonPayParams(null, null);
    };
    var onClickSignin = function () {
        router.push(routes.SIGNIN);
    };
    var availableAmazonPaySignup = function () {
        return !!amazonPayPlan;
    };
    var bindEvents = function () {
        window.addEventListener('beforeunload', efUnloadEvent);
        router.locationChange$.pipe(take(1)).subscribe(function () {
            window.removeEventListener('beforeunload', efUnloadEvent);
        });
    };
    var saveRelolubKidIfShould = function () {
        var kid = router.getQueryParams().get('kid');
        if (!kid)
            return;
        var reloclub = new ReloclubService(localStorage);
        reloclub.saveKidIfShould(kid);
    };
    var validateCorporationParams = function (params) {
        var validateResult = params.validate();
        validateResult.errors.forEach(function (error) {
            alertPresenter.showError(new LBError(), error);
        });
        return !validateResult.isValid;
    };
    var onSubmit = function (email, password, paymentMethodID, isSecondaryPlan) { return __awaiter(void 0, void 0, void 0, function () {
        var passwordModel, submitPlan, corporationParams, hasErro;
        return __generator(this, function (_a) {
            passwordModel = new PasswordVM();
            if (!authed()) {
                passwordModel.onPasswordChanged(password);
                if (passwordModel.isPasswordInvalid) {
                    alertPresenter.showError(new LBError(), msg.PASSWORD_LENGTH);
                    return [2 /*return*/];
                }
            }
            submitPlan = isSecondaryPlan ? secondaryBasePlan.plan : basePlan.plan;
            if (submitPlan.isCorporationPlan()) {
                corporationParams = new CorporationEmployeeParams(employeeName, employeeCode);
                hasErro = validateCorporationParams(corporationParams);
                if (hasErro) {
                    return [2 /*return*/];
                }
            }
            if (authed()) {
                return [2 /*return*/, accountService
                        .registerPayment(submitPlan.stripePlanId, paymentMethodID, corporationParams, localStorage.loadInvitationCode)
                        .then(function (userPayment) {
                        signUpEvent(me.id, me.email, userPayment);
                        if (localStorage.loadSignInType === SignInType.email) {
                            navigate(routes.CHECK_EMAIL.compile());
                        }
                        else {
                            navigate("".concat(routes.GOAL_MATCHING_ASSESSMENT.compile(), "?").concat(GoalMatchingAssessmentPageQuery.new, "=1"));
                        }
                    })
                        .catch(function (err) {
                        console.log(err);
                        alertPresenter.showError(err, msg.CC_REGISTER_FAILED);
                    })];
            }
            else {
                gaClient.trackEmailSignupClick();
                return [2 /*return*/, accountService
                        .signUp({
                        email: email,
                        password: passwordModel.passwordStr,
                        paymentMethodID: paymentMethodID,
                        subPlanID: submitPlan.stripePlanId,
                        corporationEmployee: corporationParams,
                        invitationCode: localStorage.loadInvitationCode,
                    }, tracker)
                        .then(function (v) {
                        bulkRegisterationEvent(v);
                        navigate(routes.CHECK_EMAIL.compile());
                    })
                        .catch(function (err) {
                        console.log(err);
                        alertPresenter.showError(err, msg.SIGNUP_FAILED, 'signup_result');
                    })];
            }
            return [2 /*return*/];
        });
    }); };
    var onSubmitAmazonPay = function (planId) {
        sessionStorage.setAmazonPayParams(planId, campaign === null || campaign === void 0 ? void 0 : campaign.campaignCode);
        window.removeEventListener('beforeunload', efUnloadEvent);
        apiClient
            .checkoutFromAmazonPay(query.get(AMAZON_PAY_CALLBACK_QUERY_PARAMS), planId)
            .then(function (url) {
            // AmazonPayリダイレクト用のURLに遷移
            // 遷移後に自動で /amazon_pay_callback に遷移して、決済が完了します
            window.location.href = url;
        })
            .catch(function (e) {
            // AmazonPayの決済ページで、決済を実行せずに「販売事業サイトに戻る」というボタンを押すと、決済に失敗するCheckoutSessionになり、最初からやり直す必要があります
            // このエラーハンドリングでは最初からやり直せるようにクエリやセッションのパラメータを削除して、プラン選択ページに遷移しています
            alertPresenter.showError(null, AMAZON_PAY_CHECKOUT_ERROR);
            sessionStorage.remove(SessionStorageType.AamazonPayParams);
            query.delete(AMAZON_PAY_CALLBACK_QUERY_PARAMS);
            navigate("".concat(routes.SIGNUP_V2.compile(), "?").concat(query.toString()));
            throw e;
        });
    };
    var signUpEvent = function (userId, email, userPayment) {
        signedCookieService.fetchSignedCookies(); // 動画を閲覧するための署名付きCookie取得
        window.removeEventListener('beforeunload', efUnloadEvent);
        tracker.trackPixelEvent(FBPixelEventName.COMPLET_EREGISTRATION);
        tracker.trackCCRegistered(userId, userPayment, campaign === null || campaign === void 0 ? void 0 : campaign.campaignCode, localStorage.loadReloclubKid);
        adlocateClient.loadConversionScript(userId, email);
        localStorage.removeAfterCCRegistration();
    };
    var bulkRegisterationEvent = function (user) {
        alertPresenter.showMessage(msg.SIGNUP_SUCCEEDED, 'signup_result');
        var payment = new Payment(user.payment, config);
        signUpEvent(user.id, user.email, payment);
    };
    var onClickSignOut = function () {
        accountService.signOut();
    };
    if (isLoading)
        return React.createElement(PageLoading, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.container) },
            React.createElement(Logo, { forHeader: isSM, classObject: styles.header }),
            isCampaignSimplifiedView ? (React.createElement("div", { className: css(styles.title) },
                "\u30D7\u30E9\u30F3\u767B\u9332\u3092\u3057\u3066",
                React.createElement("br", null),
                "\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9\u3092\u306F\u3058\u3081\u3088\u3046")) : (React.createElement("div", { className: css(styles.campaignTitle) },
                "\uFF3C\u304A\u5F97\u306A\u30D7\u30E9\u30F3\u3067\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9\u3092\u306F\u3058\u3081\u3088\u3046\uFF0F",
                React.createElement("div", { className: css(styles.titleDescription) }, "\u203B\u4E88\u544A\u306A\u304F\u7D42\u4E86\u3059\u308B\u53EF\u80FD\u6027\u304C\u3054\u3056\u3044\u307E\u3059"))),
            React.createElement("div", { className: css(styles.form) },
                React.createElement("div", { className: css(styles.formTitle) }, "\u3054\u767B\u9332\u65B9\u6CD5"),
                basePlan.plan.hasFreeTrial() && (React.createElement("div", { className: css(styles.formAnnotation) }, "\u7121\u6599\u671F\u9593\u4E2D\u306B\u89E3\u7D04\u3055\u308C\u305F\u5834\u5408\u3001\u6599\u91D1\u306F\u4E00\u5207\u767A\u751F\u3057\u307E\u305B\u3093\u3002")),
                (friendInvitationCampaign === null || friendInvitationCampaign === void 0 ? void 0 : friendInvitationCampaign.amazonGiftCampaignIsAvailable) && (React.createElement("div", { className: css(styles.formAnnotation) },
                    "\u767B\u9332\u3092\u5B8C\u4E86\u3057\u3066Amazon\u30AE\u30D5\u30C8\u5238", (_b = friendInvitationCampaign === null || friendInvitationCampaign === void 0 ? void 0 : friendInvitationCampaign.amazonGiftPrice) === null || _b === void 0 ? void 0 :
                    _b.toLocaleString(),
                    "\u5186\u5206\u3092\u30B2\u30C3\u30C8\u3057\u3088\u3046\uFF01")),
                !authed() && (React.createElement(React.Fragment, null, availableAmazonPaySignup() && !isAndroidApp() && (React.createElement("div", { className: css(styles.amazonPaySignupSection) },
                    React.createElement("div", { className: css(styles.amazonPayTitle) },
                        "Amazon\u30A2\u30AB\u30A6\u30F3\u30C8\u3067",
                        React.createElement("br", null),
                        "\u7C21\u535830\u79D2\u767B\u9332\uFF01"),
                    React.createElement("div", { className: css(styles.amazonPayDescription) }, "Amazon\u306B\u767B\u9332\u3057\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3084\u304A\u652F\u6255\u3044\u60C5\u5831\u3092\u305D\u306E\u307E\u307E\u5229\u7528\u3067\u304D\u307E\u3059"),
                    React.createElement(AmazonPayButton, { plan: amazonPayPlan, config: config, onClick: onClickAmazonPayButton }))))),
                React.createElement(PaymentForm, { config: config, alertPresenter: alertPresenter, basePlan: basePlan, secondaryBasePlan: secondaryBasePlan, campaign: campaign, campaignPlan: campaignPlan, withCampaignParams: withCampaignParams, employeeCode: employeeCode, employeeName: employeeName, authed: authed(), signedInAmazonPay: signedInAmazonPay, isCampaignSimplifiedView: isCampaignSimplifiedView, setEmployeeCode: setEmployeeCode, setEmployeeName: setEmployeeName, fetchCampaignWithPlans: fetchCampaignWithPlans, createSetupIntent: createSetupIntent, onSubmitPlan: onSubmit, onSubmitAmazonPay: onSubmitAmazonPay })),
            authed() ? (React.createElement("div", { className: css(styles.logout), onClick: onClickSignOut }, "\u30ED\u30B0\u30A2\u30A6\u30C8")) : (React.createElement("div", { className: css(styles.alreadyRegistered) },
                "\u3059\u3067\u306B\u30A2\u30AB\u30A6\u30F3\u30C8\u3092\u304A\u6301\u3061\u306E\u65B9\u306F\u3053\u3061\u3089",
                React.createElement(Button, { onClick: onClickSignin, className: css(styles.signinButton), isRadius: true, fullWidth: true }, "\u30ED\u30B0\u30A4\u30F3")))),
        React.createElement(FooterComponent, { classObject: styles.term, termsOfServiceMode: termsOfServiceMode })));
};
var styles = StyleSheet.create({
    container: (_a = {
            padding: '0 24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 'calc(100vh - 50px)',
            gap: 30
        },
        _a[MediaBreakPointUp.MD] = {
            padding: '80px 0 0',
            width: 375,
            minHeight: 'calc(100vh - 146px)',
            margin: 'auto',
        },
        _a),
    header: (_b = {},
        _b[MediaBreakPointUp.MD] = {
            width: 271,
            margin: '0 auto 18px',
        },
        _b),
    content: (_c = {
            width: '100%'
        },
        _c[MediaBreakPointUp.MD] = {
            borderRadius: 10,
            maxWidth: 375,
            margin: '0 auto 40px',
            padding: '40px 0',
        },
        _c),
    indicator: {
        margin: '0 auto 53px',
    },
    title: {
        color: Colors.TextDark,
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 1.6,
    },
    campaignTitle: {
        color: Colors.TextDark,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '100%',
    },
    titleDescription: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '100%',
        marginTop: 10,
    },
    accentBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: 194,
        height: 33,
    },
    accentLargeText: {
        fontSize: 30,
        lineHeight: '110%',
        letterSpacing: 0.6,
    },
    form: {
        width: '100%',
    },
    formTitle: {
        color: Colors.TextDark,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        marginBottom: 10,
    },
    formAnnotation: {
        color: Colors.Accent,
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginBottom: 10,
    },
    amazonPaySignupSection: {
        color: Colors.Black,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 8,
        marginBottom: 14,
        padding: 20,
        borderRadius: 10,
        border: "1px solid ".concat(Colors.PrimaryDark),
    },
    amazonPayTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
    amazonPayDescription: {
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    input: {
        width: '100%',
    },
    snsButton: {
        marginTop: 14,
    },
    logout: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        marginTop: 30,
        cursor: 'pointer',
    },
    alreadyRegistered: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginTop: 30,
    },
    signinButton: {
        marginTop: 14,
    },
    term: {
        paddingTop: 70,
    },
});
