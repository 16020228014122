import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles/variables';
import { NoticeSection } from '../atoms/notice_section';
var selectedBadgeImgPath = 'assets/images/select_plan/selected_badge_gray.png';
export var CampaignPlanSection = function (_a) {
    var classObject = _a.classObject, isBulkRegistration = _a.isBulkRegistration, // TODO: 一括登録フォームのみになったら削除
    isCampaignSimplifiedView = _a.isCampaignSimplifiedView, campaign = _a.campaign, campaignPlan = _a.campaignPlan, withCampaignParams = _a.withCampaignParams;
    var appliedLabel = withCampaignParams ? 'が適用されています' : 'が適用されました';
    // TODO: 一括登録フォームのみになったら削除
    if (!isBulkRegistration) {
        return (React.createElement(NoticeSection, null,
            React.createElement("div", null,
                React.createElement("span", { className: css(styles.campaignName) }, campaign.name),
                appliedLabel)));
    }
    if (isCampaignSimplifiedView) {
        return (React.createElement(NoticeSection, null,
            React.createElement("div", null,
                React.createElement("span", { className: css(styles.campaignName) }, campaign.name),
                appliedLabel)));
    }
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.content) },
            React.createElement("img", { className: css(styles.checkIcon), src: selectedBadgeImgPath, alt: "selected badge" }),
            React.createElement("div", { className: css(styles.campaignNameAndPrice) },
                React.createElement("div", null,
                    React.createElement("span", { className: css(styles.campaignName) }, campaign.name),
                    appliedLabel),
                React.createElement("img", { src: "/assets/images/signup/campaign_plan_price.png", className: css(styles.image) }))),
        React.createElement("div", { className: css(styles.text) },
            "\u7279\u5225\u30AD\u30E3\u30F3\u30DA\u30FC\u30F312\u30F6\u6708\u30D7\u30E9\u30F3 ",
            campaignPlan.amountWithoutTax.toLocaleString(),
            "\u5186(\u7A0E\u8FBC",
            campaignPlan.amount.toLocaleString(),
            "\u5186)\u306E\u3054\u767B\u9332"),
        React.createElement("div", { className: css(styles.text) },
            !withCampaignParams && !campaignPlan.hasFreeTrial() && (React.createElement(React.Fragment, null,
                "\u203B\u7121\u6599\u671F\u9593\u306F\u3054\u3056\u3044\u307E\u305B\u3093\u3002",
                React.createElement("br", null))),
            "\u203B\u7FCC\u5E74\u66F4\u65B0\u6642\u3082\u7279\u5225\u30AD\u30E3\u30F3\u30DA\u30FC\u30F312\u30F6\u6708\u30D7\u30E9\u30F3\u304C\u9069\u7528\u3068\u306A\u308A\u307E\u3059\u3002",
            React.createElement("br", null),
            "\u203B\u81EA\u52D5\u66F4\u65B0\u3068\u306A\u308B\u305F\u3081\u3001\u7D99\u7D9A\u3092\u3054\u5E0C\u671B\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u8A2D\u5B9A\u304B\u3089\u9000\u4F1A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002")));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.BackgroundLightBlueMagenta,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 11,
        padding: 16,
        fontSize: 10,
        fontWeight: 500,
    },
    content: {
        display: 'flex',
        gap: 11,
        width: '100%',
    },
    checkIcon: {
        width: 16,
        height: 16,
    },
    campaignNameAndPrice: {
        color: Colors.GrayDarkest,
        display: 'flex',
        flexDirection: 'column',
        gap: 11,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    campaignName: {
        color: Colors.Accent,
    },
    image: {
        width: '100%',
    },
    text: {
        textAlign: 'left',
        width: '100%',
        lineHeight: '140%',
        letterSpacing: 0.5,
    },
});
