import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from '../../styles/variables';
export var TriangleDirection = {
    right: 'right',
    left: 'left',
    bottom: 'bottom',
    top: 'top',
};
var TriangleTransform = {
    top: 'rotate(90deg)',
    right: 'rotate(180deg)',
    bottom: 'rotate(270deg)',
    left: 'rotate(0deg)',
};
export var TriangleIcon = function (_a) {
    var color = _a.color, classObject = _a.classObject, direction = _a.direction;
    var styles = StyleSheet.create({
        container: {
            width: 9,
            height: 10,
            transform: TriangleTransform[direction || TriangleDirection.left],
        },
    });
    var iconColor = color || Colors.BackgroundBlue;
    return (React.createElement("svg", { className: css(styles.container, classObject), viewBox: "0 0 9 10", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { id: "Polygon 2", d: "M0.5 5.86602C-0.166667 5.48112 -0.166667 4.51887 0.5 4.13397L7.25 0.236858C7.91667 -0.148043 8.75 0.333083 8.75 1.10288L8.75 8.89711C8.75 9.66691 7.91667 10.148 7.25 9.76314L0.5 5.86602Z", fill: iconColor })));
};
