import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles';
export var CautionIcon = function (props) {
    var styles = StyleSheet.create({
        icon: {
            width: 40,
            height: 40,
        },
    });
    var color = props.color || Colors.Accent;
    return (React.createElement("svg", { className: css(styles.icon, props.classObject), viewBox: "0 0 40 38", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M39.1689 28.8072L25.0388 2.8648C22.7686 -0.952398 17.2345 -0.95747 14.9612 2.8648L0.831788 28.8072C-1.48899 32.7077 1.32077 37.6471 5.86945 37.6471H34.1301C38.6749 37.6471 41.4897 32.7117 39.1689 28.8072ZM20 32.9654C18.708 32.9654 17.6562 31.915 17.6562 30.6245C17.6562 29.3341 18.708 28.2837 20 28.2837C21.292 28.2837 22.3437 29.3341 22.3437 30.6245C22.3437 31.915 21.292 32.9654 20 32.9654ZM22.3437 23.602C22.3437 24.8925 21.292 25.9429 20 25.9429C18.708 25.9429 17.6562 24.8925 17.6562 23.602V11.8979C17.6562 10.6074 18.708 9.55702 20 9.55702C21.292 9.55702 22.3437 10.6074 22.3437 11.8979V23.602Z", fill: color })));
};
