var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useStripe } from '@stripe/react-stripe-js';
import { Colors } from '@lean-body/components/styles';
import * as msg from '@lean-body/src/messages';
import { Button, ButtonType, CautionIcon } from '../atoms';
export var CC3DSAuthentication = function (_a) {
    var alertPresenter = _a.alertPresenter, clientSecret = _a.clientSecret, curCCLastFour = _a.curCCLastFour, handleAfterAuthentication = _a.handleAfterAuthentication;
    var stripe = useStripe();
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error, isSuccess;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, stripe.confirmCardPayment(clientSecret)];
                case 1:
                    error = (_a.sent()).error;
                    isSuccess = false;
                    if (error) {
                        alertPresenter.showError(null, msg.CC_3DS_FAILED, 'cc_change_result');
                    }
                    else {
                        isSuccess = true;
                        alertPresenter.showMessage(msg.CC_3DS_SUCCEEDED, 'cc_change_result');
                    }
                    handleAfterAuthentication(isSuccess);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) },
            React.createElement(CautionIcon, null),
            "\u304A\u652F\u6255\u3044\u306B\u5931\u6557\u3057\u307E\u3057\u305F"),
        React.createElement("div", { className: css(styles.description) },
            "\u5B89\u5168\u306B\u3054\u5229\u7528\u3044\u305F\u3060\u304F\u305F\u3081\u3001\u672C\u4EBA\u8A8D\u8A3C\u30B5\u30FC\u30D3\u30B9\uFF083D\u30BB\u30AD\u30E5\u30A2\uFF09\u306E\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u3054\u5165\u529B\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            "\u672C\u4EBA\u8A8D\u8A3C\u30B5\u30FC\u30D3\u30B9\u306E\u8A2D\u5B9A\u65B9\u6CD5\u7B49\u306B\u3064\u3044\u3066\u306F\u3001\u5404\u30AB\u30FC\u30C9\u4F1A\u793E\u306B\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044\u3002",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("div", { className: css(styles.alert) }, "\uFF11\u30F6\u6708\u4EE5\u5185\u306B\u672C\u4EBA\u8A8D\u8A3C\u304C\u5B8C\u4E86\u3057\u3066\u3044\u306A\u3044\u5834\u5408\u306F\u9000\u4F1A\u6271\u3044\u3068\u3055\u305B\u3066\u3044\u305F\u3060\u304D\u307E\u3059\u3002")),
        React.createElement("div", { className: css(styles.currentCC) },
            React.createElement("div", { className: css(styles.currentCCLabel) }, "\u73FE\u5728\u306E\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9"),
            React.createElement("div", null, "**** **** **** ".concat(curCCLastFour))),
        React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.primary, onClick: onClick }, "\u672C\u4EBA\u8A8D\u8A3C\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B\u3059\u308B")));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 20,
    },
    title: {
        color: Colors.Black,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '100%',
    },
    description: {
        color: Colors.TextDark,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
    },
    alert: {
        color: Colors.Alert,
        fontWeight: 500,
    },
    currentCC: {
        margin: '10px 0',
        textAlign: 'center',
    },
    currentCCLabel: {
        marginBottom: 4,
        fontSize: 14,
        color: Colors.GrayDarkest,
    },
    button: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 0.56,
        width: 315,
        height: 50,
    },
});
