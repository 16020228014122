import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
export var PlanAmountText = function (_a) {
    var classObject = _a.classObject, campaign = _a.campaign, plan = _a.plan;
    var planName = (campaign === null || campaign === void 0 ? void 0 : campaign.isFriendInvitation) ? campaign.name : plan === null || plan === void 0 ? void 0 : plan.name;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        plan.hasFreeTrial() && '無料体験終了後',
        " ",
        planName,
        !plan.hasFreeTrial() && plan.isStandardPlan() ? React.createElement("br", null) : ' ',
        plan.amountWithoutTax.toLocaleString(),
        "\u5186\uFF08\u7A0E\u8FBC",
        plan.amount.toLocaleString(),
        "\u5186\uFF09",
        plan.isStandardPlan() && '/月',
        "\u306E\u3054\u767B\u9332",
        !plan.isStandardPlan() && (React.createElement(React.Fragment, null,
            React.createElement("br", null),
            "1\u30F6\u6708\u3042\u305F\u308A",
            plan.amountPerMonthWithoutTax.toLocaleString(),
            "\u5186/\u6708\uFF08\u7A0E\u8FBC",
            plan.amountPerMonth.toLocaleString(),
            "\u5186/\u6708\uFF09\u76F8\u5F53"))));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.5,
    },
});
