import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors, drawerWidth, MediaBreakPointUp, Sizes } from '@lean-body/components/styles';
import { ArrowDirection, ArrowIcon } from '../atoms/icons/arrow_icon';
import { LessonComponent } from './lesson_component';
import { FIXED_FOOTER_HEIGHT } from '../organisms/fixed_footer';
import { Button, ButtonType } from '../atoms';
import { PlayButtonIcon } from '../atoms/icons/play_button_icon';
import { routes } from '@lean-body/src/route';
import { Link } from 'react-router-dom';
var OTHER_LESSON_PADDING = 16;
var OTHER_LESSON_PC_TOP_AND_BOTTOM_PADDING = 12;
var OTHER_LESSON_PC_SIDE_PADDING = 24;
export var SHADOW_WIDTH = 30;
export var LessonPlaylistBox = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var program = _a.program, playlist = _a.playlist, currentLessonIndex = _a.currentLessonIndex, nextLesson = _a.nextLesson, programLessons = _a.programLessons, addFavoriteLesson = _a.addFavoriteLesson, unFavoriteLesson = _a.unFavoriteLesson, isShowOtherLessons = _a.isShowOtherLessons, onClickShowOtherLessonsIcon = _a.onClickShowOtherLessonsIcon, showTopImg = _a.showTopImg, buttonText = _a.buttonText, buttonClick = _a.buttonClick, title = _a.title, courseDay = _a.courseDay, isSkippableCourseDay = _a.isSkippableCourseDay, onClickLesson = _a.onClickLesson, onClickShowCourseDaySkipDialog = _a.onClickShowCourseDaySkipDialog, aboveLessonName = _a.aboveLessonName;
    var hasOnClickShowOtherLessonsIcon = !!onClickShowOtherLessonsIcon;
    var displayNextLessonThumbnail = showTopImg && nextLesson;
    var styles = StyleSheet.create({
        otherLesson: (_b = {
                position: 'fixed',
                width: '100%',
                left: 0,
                bottom: 59,
                paddingTop: OTHER_LESSON_PADDING,
                paddingBottom: OTHER_LESSON_PADDING,
                backgroundColor: Colors.White,
                borderRadius: '14px 14px 0 0',
                boxShadow: "0 0 ".concat(SHADOW_WIDTH, "px rgba(0, 0, 0, 0.16)"),
                cursor: 'pointer'
            },
            _b[MediaBreakPointUp.MD] = {
                inset: 0,
                top: 'unset',
                left: drawerWidth.md,
                bottom: 0,
                maxWidth: 600,
                margin: 'auto',
            },
            _b),
        otherLessonShow: (_c = {
                height: "calc(100vh - ".concat(FIXED_FOOTER_HEIGHT, "px - ").concat(Sizes.MobileHeaderHeight, "px)"),
                top: Sizes.MobileHeaderHeight,
                left: 0,
                bottom: 0
            },
            _c[MediaBreakPointUp.MD] = {
                position: 'fixed',
                height: '100%',
                top: 0,
                left: drawerWidth.md,
            },
            _c[MediaBreakPointUp.XL] = {
                position: 'relative',
                maxWidth: 392,
                margin: 0,
                border: "1px solid ".concat(Colors.BorderLightGray),
                borderRadius: 4,
                boxShadow: 'none',
                left: 0,
                paddingTop: OTHER_LESSON_PC_TOP_AND_BOTTOM_PADDING,
                paddingBottom: OTHER_LESSON_PC_TOP_AND_BOTTOM_PADDING,
            },
            _c),
        fixedFooter: {
            paddingLeft: OTHER_LESSON_PADDING,
            paddingRight: OTHER_LESSON_PADDING,
        },
        footerExtension: (_d = {
                paddingBottom: OTHER_LESSON_PADDING
            },
            _d[MediaBreakPointUp.XL] = {
                paddingLeft: OTHER_LESSON_PC_SIDE_PADDING,
                paddingRight: OTHER_LESSON_PC_SIDE_PADDING,
                paddingBottom: OTHER_LESSON_PC_TOP_AND_BOTTOM_PADDING,
            },
            _d),
        fixedFooterImgWithText: {
            display: 'flex',
            justifyContent: 'space-between',
            maxHeight: 40,
        },
        button: {
            padding: '15px 0',
        },
        buttonInner: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        playButton: {
            marginRight: 10,
        },
        img: {
            width: 70,
            height: 40,
            marginRight: 8,
            borderRadius: 4,
        },
        hidden: {
            display: 'none',
        },
        footerInfo: (_e = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: 'calc(100% - 104px)',
                marginRight: 16
            },
            _e[MediaBreakPointUp.XL] = {
                width: '100%',
            },
            _e),
        footerWithoutImg: {
            width: 'calc(100% - 26px)',
        },
        seriesTitle: {
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '160%',
            letterSpacing: '0.08em',
            height: 26,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
        },
        seriesTitleOpen: {
            fontSize: 16,
        },
        seriesTitlePrimary: {
            color: Colors.PrimaryDarkB,
        },
        nextLessonTitle: {
            height: 19,
            fontSize: 12,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
        },
        nextLessonTitleMargin: {
            marginRight: 7,
        },
        currentLessonTitle: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        arrowIcon: {
            display: 'flex',
            alignItems: 'center',
            width: 10,
        },
        lessonBox: (_f = {
                height: '100%',
                paddingBottom: 60,
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                    display: 'none',
                }
            },
            _f[MediaBreakPointUp.XL] = {
                overflowY: 'unset',
                paddingBottom: 0,
            },
            _f),
        lesson: (_g = {
                padding: "6px ".concat(OTHER_LESSON_PADDING, "px")
            },
            _g[MediaBreakPointUp.XL] = {
                padding: "".concat(OTHER_LESSON_PC_TOP_AND_BOTTOM_PADDING, "px ").concat(OTHER_LESSON_PC_SIDE_PADDING, "px"),
            },
            _g),
        activeLesson: {
            backgroundColor: Colors.BackgroundBlue,
        },
        courseDaySkipButtonBox: (_h = {
                display: 'flex',
                justifyContent: 'center',
                marginTop: 16
            },
            _h[MediaBreakPointUp.XL] = {
                marginTop: 12,
            },
            _h),
        courseDaySkipButton: {
            color: Colors.PrimaryDarkB,
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '160%',
            letterSpacing: '0.48px',
            padding: '6px 0',
            cursor: 'pointer',
        },
    });
    return (React.createElement(React.Fragment, null, !!programLessons.length && (React.createElement("div", { className: css(styles.otherLesson, isShowOtherLessons && styles.otherLessonShow), onClick: onClickShowOtherLessonsIcon },
        React.createElement("div", { className: css(styles.fixedFooter, isShowOtherLessons && styles.footerExtension) }, buttonText ? (React.createElement(Button, { isRadius: true, fullWidth: true, buttonType: ButtonType.primary, className: css(styles.button), onClick: buttonClick, dataTest: "lessonPlaylistButton" },
            React.createElement("div", { className: css(styles.buttonInner) },
                React.createElement(PlayButtonIcon, { classObject: styles.playButton }),
                React.createElement("p", null, buttonText)))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.fixedFooterImgWithText) },
                displayNextLessonThumbnail && (React.createElement("img", { className: css(styles.img, isShowOtherLessons && styles.hidden), src: nextLesson.thumbnailURL })),
                React.createElement("div", { className: css(styles.footerInfo, (isShowOtherLessons || !displayNextLessonThumbnail) && styles.footerWithoutImg) },
                    title ? (React.createElement("div", { className: css(styles.seriesTitle, isShowOtherLessons && styles.seriesTitleOpen) }, title)) : (React.createElement(React.Fragment, null, courseDay ? (React.createElement(Link, { to: routes.COURSE_VOLUME.compile({ id: String(courseDay.volume.id) }), className: css(styles.seriesTitle, styles.seriesTitlePrimary, isShowOtherLessons && styles.seriesTitleOpen) },
                        courseDay.volume.title,
                        " DAY",
                        courseDay.day)) : playlist ? (React.createElement(Link, { to: routes.PLAYLIST.compile({ id: playlist.id.toString() }), className: css(styles.seriesTitle, styles.seriesTitlePrimary, isShowOtherLessons && styles.seriesTitleOpen) }, playlist.name)) : (React.createElement(Link, { to: routes.PROGRAM.compile({ slug: program.slug }), className: css(styles.seriesTitle, styles.seriesTitlePrimary, isShowOtherLessons && styles.seriesTitleOpen) }, program.name)))),
                    nextLesson && (React.createElement("div", { className: css(styles.nextLessonTitle) },
                        React.createElement("span", { className: css(styles.nextLessonTitleMargin) }, "\u6B21\uFF1A"),
                        nextLesson.name))),
                hasOnClickShowOtherLessonsIcon && (React.createElement("div", { className: css(styles.arrowIcon), "data-test": "lessonPlaylistOpenList" },
                    React.createElement(ArrowIcon, { direction: isShowOtherLessons ? ArrowDirection.bottom : ArrowDirection.top, color: Colors.GrayDarkest }))))))),
        React.createElement("div", { className: css(styles.lessonBox, !isShowOtherLessons && styles.hidden) },
            programLessons.map(function (v, i) {
                return (React.createElement("div", { className: css(styles.lesson, i == currentLessonIndex && styles.activeLesson), key: i, "data-test": "lessonItem" },
                    React.createElement(LessonComponent, { lesson: v, addFavoriteLesson: addFavoriteLesson, unFavoriteLesson: unFavoriteLesson, onClick: onClickLesson, isVertical: true, isMin: true, isActive: i == currentLessonIndex, disableHistoryPush: !!onClickLesson, aboveName: aboveLessonName && aboveLessonName(i) })));
            }),
            isSkippableCourseDay && (React.createElement("div", { className: css(styles.courseDaySkipButtonBox) },
                React.createElement("div", { className: css(styles.courseDaySkipButton), onClick: onClickShowCourseDaySkipDialog }, "\u3053\u306EDAY\u3092\u30B9\u30AD\u30C3\u30D7"))))))));
};
