var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { loadStripe, } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, } from '@stripe/react-stripe-js';
import { Colors } from '@lean-body/components/styles';
import { css, StyleSheet } from 'aphrodite';
import { CCBrandIcon, CircleCheckIcon } from '../atoms';
import { useRefHooks } from '@lean-body/src/util';
import { CCFormSubmitType } from './cc_form';
var useState = React.useState, useEffect = React.useEffect;
var CheckoutForm = function (props) {
    var afterSubmit = props.afterSubmit, openSection = props.openSection, hideSectionTitle = props.hideSectionTitle, hiddenInputRef = props.hiddenInputRef, hiddenInputElement = props.hiddenInputElement, createSetupIntent = props.createSetupIntent, onSubmitFirstPlan = props.onSubmitFirstPlan, onSubmitSecondaryPlan = props.onSubmitSecondaryPlan;
    var stripe = useStripe();
    var elements = useElements();
    var _a = useState(false), cardNumber = _a[0], changeCardNumber = _a[1];
    var _b = useState(false), cardExpire = _b[0], changeCardExpire = _b[1];
    var _c = useState(false), cardCvc = _c[0], changeCardCvc = _c[1];
    var _d = useRefHooks(), ref = _d.ref, element = _d.element;
    useEffect(function () {
        props.changeFilledAllForm(cardNumber && cardExpire && cardCvc);
    }, [cardNumber, cardExpire, cardCvc]);
    useEffect(function () {
        // クレカ入力のformを最小化したときに、入力中の値に自動でscrollされてしまうので、されないように調整
        var scrollToTop = function () { return element.scrollTo(0, 0); };
        element === null || element === void 0 ? void 0 : element.addEventListener('scroll', scrollToTop);
        return function () { return element === null || element === void 0 ? void 0 : element.removeEventListener('scroll', scrollToTop); };
    }, [element]);
    var options = function (placeholder) {
        return {
            placeholder: placeholder,
            style: {
                base: {
                    color: Colors.Black,
                    fontSize: '16px',
                    lineHeight: '20px',
                    '::placeholder': {
                        color: Colors.GrayDarkest,
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var validated, setupIntent, result, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    event.preventDefault();
                    validated = props.beforeSubmit(true);
                    if (!validated)
                        return [2 /*return*/];
                    return [4 /*yield*/, createSetupIntent()];
                case 1:
                    setupIntent = _b.sent();
                    return [4 /*yield*/, stripe.confirmCardSetup(setupIntent.clientSecret, {
                            payment_method: { card: elements.getElement(CardNumberElement) },
                        })];
                case 2:
                    result = _b.sent();
                    if (result.error) {
                        props.showErrorFromStripeInput([result.error.message]);
                        return [2 /*return*/];
                    }
                    _a = hiddenInputElement.value;
                    switch (_a) {
                        case CCFormSubmitType.firstPlan: return [3 /*break*/, 3];
                        case CCFormSubmitType.secondaryPlan: return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 7];
                case 3: return [4 /*yield*/, onSubmitFirstPlan(result.setupIntent.payment_method)];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, onSubmitSecondaryPlan(result.setupIntent.payment_method)];
                case 6:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 7:
                    afterSubmit();
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeCardNumber = function (event) {
        changeCardNumber(event.complete);
        if (event.complete) {
            elements.getElement(CardExpiryElement).focus();
        }
    };
    var onChangeCardExpire = function (event) {
        changeCardExpire(event.complete);
        if (event.complete) {
            elements.getElement(CardCvcElement).focus();
        }
    };
    var onChangeCardCvc = function (event) {
        changeCardCvc(event.complete);
    };
    return (React.createElement("form", { onSubmit: handleSubmit, className: css(styles.form) },
        React.createElement("input", { type: "hidden", ref: hiddenInputRef }),
        React.createElement("div", { className: css(styles.section, openSection && styles.sectionOpened), ref: ref },
            hideSectionTitle && (React.createElement("div", { className: css(styles.sectionTitle), onClick: props.onClickSection },
                openSection ? (React.createElement(CircleCheckIcon, { classObject: styles.checkIcon, filled: true, color: Colors.Black })) : (React.createElement("div", { className: css(styles.checkIcon, styles.notChecked) })),
                "\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9\u3067\u304A\u652F\u6255\u3044")),
            React.createElement("div", { className: css(styles.title) }, "\u30AB\u30FC\u30C9\u60C5\u5831\u3092\u5165\u529B"),
            React.createElement(CCBrandIcon, { classObject: styles.brandIcons }),
            React.createElement("div", { className: css(styles.label) }, "\u30AB\u30FC\u30C9\u756A\u53F7"),
            React.createElement("div", { className: css(styles.input) },
                React.createElement(CardNumberElement, { options: options('半角数字'), onChange: onChangeCardNumber })),
            React.createElement("div", { className: css(styles.label) }, "\u6709\u52B9\u671F\u9650"),
            React.createElement("div", { className: css(styles.input) },
                React.createElement(CardExpiryElement, { options: options('月 / 年'), onChange: onChangeCardExpire })),
            React.createElement("div", { className: css(styles.label) }, "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30FC\u30B3\u30FC\u30C9(\u30AB\u30FC\u30C9\u88CF\u306E\u6570\u5B57)"),
            React.createElement("div", { className: css(styles.input) },
                React.createElement(CardCvcElement, { options: options('半角数字(3桁または4桁)'), onChange: onChangeCardCvc })),
            React.createElement("div", { className: css(styles.noticeMsg) }, "\u203BAMEX\u306F4\u6841")),
        stripe && props.children));
};
export var StripeCardForm = function (props) {
    var stripePromise = React.useState(function () { return loadStripe(props.stripeAPIKey); })[0];
    return (React.createElement(Elements, { stripe: stripePromise },
        React.createElement(CheckoutForm, { afterSubmit: props.afterSubmit, showErrorFromStripeInput: props.showErrorFromStripeInput, beforeSubmit: props.beforeSubmit, changeFilledAllForm: props.changeFilledAllForm, openSection: props.openSection, onClickSection: props.onClickSection, hideSectionTitle: props.hideSectionTitle, hiddenInputRef: props.hiddenInputRef, hiddenInputElement: props.hiddenInputElement, createSetupIntent: props.createSetupIntent, onSubmitFirstPlan: props.onSubmitFirstPlan, onSubmitSecondaryPlan: props.onSubmitSecondaryPlan }, props.children)));
};
var styles = StyleSheet.create({
    form: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    section: {
        padding: '16px 14px',
        border: "1px solid ".concat(Colors.PrimaryDark),
        borderRadius: 4,
        maxHeight: 52,
        transition: 'all .2s',
        overflow: 'hidden',
    },
    sectionOpened: {
        maxHeight: 600,
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    notChecked: {
        backgroundColor: Colors.Gray,
        borderRadius: 100,
    },
    checkIcon: {
        margin: '0 8px 0 4px',
        width: 16,
        height: 16,
    },
    brandIcons: {
        margin: '14px 0',
    },
    title: {
        textAlign: 'center',
    },
    label: {
        margin: '14px 0 8px',
    },
    input: {
        position: 'relative',
        width: '100%',
        border: "1px solid ".concat(Colors.Gray),
        '-webkit-appearance': 'none',
        borderRadius: 4,
        height: 50,
        padding: '15px 12px',
    },
    noticeMsg: {
        marginTop: 8,
        fontWeight: 'normal',
        color: Colors.GrayDarkest,
    },
});
