import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { PageLoading, RecommendDormant, RecommendDormantInputs } from '@lean-body/components/organisms';
import { Colors } from '../styles';
import { useQuery } from '@lean-body/src/util/custom_hooks';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { SettingItemKeys } from '../organisms/setting_menu';
export var DormantPage = function (_a) {
    var apiClient = _a.apiClient, accountService = _a.accountService, alertPresenter = _a.alertPresenter, tracker = _a.tracker;
    var _b = React.useState(null), payment = _b[0], setPayment = _b[1];
    var query = useQuery();
    var strictDormantMonth = query.get('dormantMonth');
    var navigate = useNavigate();
    var strictDormantMonthAvailable = function () {
        if (!strictDormantMonth)
            return true; // クエリがない場合は問題なし
        return RecommendDormantInputs.find(function (v) { return v.value === strictDormantMonth; });
    };
    var dormantAccount = function (month) {
        return accountService.dormantAccount(month).then(function () {
            tracker.trackBecomeDormant();
        });
    };
    React.useEffect(function () {
        if (strictDormantMonthAvailable()) {
            accountService.fetchMe().then(function (v) {
                if (v.isDormantAvailable) {
                    setPayment(v.payment);
                }
                else {
                    alertPresenter.showMessage('Stripeプランのみ利用可能です');
                    navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                }
            });
        }
        else {
            alertPresenter.showMessage('不正な休眠期間です');
            navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
        }
    }, []);
    if (!payment) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u4F11\u7720\u30D7\u30E9\u30F3"),
        React.createElement(RecommendDormant, { classObject: styles.recommentDormant, apiClient: apiClient, payment: payment, dormantAccount: dormantAccount, strictDormantMonth: parseInt(strictDormantMonth) })));
};
var styles = StyleSheet.create({
    container: {
        minHeight: '100vh',
        backgroundColor: Colors.White,
        padding: '30px',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 24,
        textAlign: 'start',
    },
    recommentDormant: {
        maxWidth: 730,
        margin: '0 auto',
        textAlign: 'center',
    },
});
