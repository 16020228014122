import * as React from 'react';
import { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
import { ZIndex } from '@lean-body/src/view/constants';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { GoalMatchingAssessment, PageLoading } from '@lean-body/components/organisms';
import { Logo } from '@lean-body/components/atoms';
import { KIND_WEIGHT_GOAL, } from '@lean-body/src/domain';
import { useQuery } from '@lean-body/src/util';
export var GOAL_MATCHING_ASSESSMENT_VERSION = 6;
export var GoalMatchingAssessmentPageQuery = {
    new: 'new',
    change: 'change',
};
/**
 * 目標診断設定ページ
 */
export var GoalMatchingAssessmentPage = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, setProfile = _a.setProfile;
    var isSM = useSmMediaQuery();
    var query = useQuery();
    var _b = useState(), assessments = _b[0], setAssessments = _b[1];
    var _c = useState(), answers = _c[0], setAnswers = _c[1];
    var isNewUser = Boolean(query.get(GoalMatchingAssessmentPageQuery.new)); // 新規登録した後のユーザー
    var _d = useState(!!query.get(GoalMatchingAssessmentPageQuery.change)), isReassessment = _d[0], setIsReassessment = _d[1];
    var skipToKind = isReassessment ? KIND_WEIGHT_GOAL : null;
    var _e = useState(!isReassessment), showStartPage = _e[0], setShowStartPage = _e[1];
    useEffect(function () {
        apiClient
            .fetchGoalMatchingAssessmentAnswerCheck(GOAL_MATCHING_ASSESSMENT_VERSION)
            .then(function (answeredRequiredFields) {
            if (isReassessment && !answeredRequiredFields) {
                setIsReassessment(false);
                setShowStartPage(false);
            }
            apiClient.fetchGoalMatchingAssessments(GOAL_MATCHING_ASSESSMENT_VERSION).then(setAssessments);
            apiClient.fetchGoalMatchingAssessmentAnswer(GOAL_MATCHING_ASSESSMENT_VERSION).then(setAnswers);
        });
    }, []);
    if (!(assessments && answers)) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        isSM && React.createElement(Logo, { forHeader: isSM, classObject: styles.logo }),
        React.createElement(GoalMatchingAssessment, { apiClient: apiClient, config: config, setProfile: setProfile, assessments: assessments, pastAnswers: answers, isNewUser: isNewUser, isReassessment: isReassessment, showStartPage: showStartPage, skipToKind: skipToKind })));
};
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        width: '100%',
        height: '100vh',
    },
    logo: {
        position: 'fixed',
        width: '100%',
        backgroundColor: Colors.White,
        zIndex: ZIndex.Header,
    },
});
