var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useEffect } from 'react';
import { Colors } from '@lean-body/components/styles';
import { SessionStorage } from '@lean-body/src/infra';
import { Switch } from '@mui/material';
import { Button, ButtonType, CloseButtonLabel } from '../atoms';
import { MEAL_TASK_REMIND_AT_DEFAULT } from '@lean-body/src/domain';
import { Dialog, TimeSelectForm, TimeSelectFormat } from '../molecules';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { CourseSettingDialogType } from './course_setting_dialog';
import { WebViewCallbackHandlers, WebViewEvents, } from '@lean-body/src/interfaces';
import { NotificationPermissionAlert } from '../molecules/notification_permission_alert';
export var NotificationSettingSubsection = function (_a) {
    var apiClient = _a.apiClient;
    var navigate = useNavigate();
    var sessionStorage = new SessionStorage();
    var _b = React.useState(false), hasNotificationPermission = _b[0], setHasNotificationPermission = _b[1];
    var _c = React.useState({}), notificationSettings = _c[0], setNotificationSettings = _c[1];
    var _d = React.useState(null), courseId = _d[0], setCourseId = _d[1];
    var _e = React.useState(false), showSetCourseDialog = _e[0], setShowSetCourseDialog = _e[1];
    useEffect(function () {
        window.addEventListener(WebViewEvents.deviceNotificationPermission, onDeviceNotificationPermissionEvent);
        // 通知設定をSessionStorageに格納するのはAndroid側で行っているため、
        // WebViewコールバック実行の後、SessionStorageから読み取る
        window.flutter_inappwebview
            .callHandler(WebViewCallbackHandlers.loadNotificationSettings)
            .then(loadNotificationSettings);
        apiClient.fetchMyCourse().then(function (course) {
            if (course) {
                setCourseId(course.id);
            }
        });
        return function () {
            window.removeEventListener(WebViewEvents.deviceNotificationPermission, onDeviceNotificationPermissionEvent);
        };
    }, []);
    var onDeviceNotificationPermissionEvent = function (e) {
        setHasNotificationPermission(e.detail.hasPermission);
    };
    function handleNotificationChange() {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        (_a = window.flutter_inappwebview).callHandler.apply(_a, __spreadArray([WebViewCallbackHandlers.notificationSettingsChange], args, false)).then(function () {
            loadNotificationSettings();
        });
    }
    var loadNotificationSettings = function () {
        var hasPermission = sessionStorage.loadAndroidDeviceNotificationPermission();
        var settings = sessionStorage.loadAndroidNotificationSettings();
        setHasNotificationPermission(hasPermission);
        setNotificationSettings(settings);
    };
    var onLiveSwitchChanged = function () {
        handleNotificationChange('live');
    };
    var onNewsSwitchChanged = function () {
        handleNotificationChange('news');
    };
    var onSetCourseScheduleButtonClick = function () {
        if (courseId) {
            navigate("".concat(routes.COURSE.compile(), "?setting_dialog=").concat(CourseSettingDialogType.weekdaysEditor));
        }
        else {
            setShowSetCourseDialog(true);
        }
    };
    var onSetCourseDialogCancel = function () {
        setShowSetCourseDialog(false);
    };
    var onSetCourseClick = function () {
        navigate(routes.TOP.compile());
    };
    var onCourseReminderSwitchChanged = function () {
        handleNotificationChange('course_reminder');
    };
    var onMealTaskReminderSwitchChanged = function () {
        var mealTaskRemindAt = notificationSettings.mealTaskRemindAt ? '' : MEAL_TASK_REMIND_AT_DEFAULT;
        handleNotificationChange('meal_task_reminder', mealTaskRemindAt);
    };
    var onMealTaskRemindTimeChange = function (time) {
        handleNotificationChange('meal_task_reminder', time);
    };
    var onClickNotificationPermissionBanner = function () {
        window.flutter_inappwebview.callHandler(WebViewCallbackHandlers.requestDeviceNotificationPermissionSettings);
    };
    return (React.createElement("div", { className: css(styles.sectionContainer) },
        !hasNotificationPermission && React.createElement(NotificationPermissionAlert, { onClick: onClickNotificationPermissionBanner }),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.label, !hasNotificationPermission && styles.labelDisabled) }, "LIVE\u914D\u4FE1"),
            React.createElement(Switch, { disabled: !hasNotificationPermission, checked: hasNotificationPermission && notificationSettings.live, onChange: onLiveSwitchChanged })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.label, !hasNotificationPermission && styles.labelDisabled) },
                "\u98DF\u4E8B\u8A18\u9332\u306E\u5165\u529B\u5FD8\u308C\u304A\u77E5\u3089\u305B",
                React.createElement("div", { className: css(styles.description, !hasNotificationPermission && styles.descriptionDisabled) }, "1\u5EA6\u3082\u98DF\u4E8B\u5165\u529B\u304C\u306A\u3044\u65E5\u306B\u304A\u77E5\u3089\u305B"),
                hasNotificationPermission && notificationSettings.mealTaskRemindAt && (React.createElement(TimeSelectForm, { selectClassObject: styles.timeSelect, currentValue: notificationSettings.mealTaskRemindAt, setCurrentValue: onMealTaskRemindTimeChange, label: "", format: TimeSelectFormat.hhmm, step: 5, listClassObject: styles.timeSelectList, listItemClassObject: styles.timeSelectListItem, displayIcon: false }))),
            React.createElement(Switch, { disabled: !hasNotificationPermission, checked: hasNotificationPermission && !!notificationSettings.mealTaskRemindAt, onChange: onMealTaskReminderSwitchChanged })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.label, !hasNotificationPermission && styles.labelDisabled) },
                "\u30B3\u30FC\u30B9\u30EA\u30DE\u30A4\u30F3\u30C0\u30FC",
                hasNotificationPermission && (React.createElement(Button, { className: css(styles.courseScheduleBtn), isRadius: true, buttonType: ButtonType.primary, onClick: onSetCourseScheduleButtonClick }, "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u3092\u8A2D\u5B9A\u3059\u308B"))),
            React.createElement(Switch, { disabled: !hasNotificationPermission, checked: hasNotificationPermission && notificationSettings.courseReminder, onChange: onCourseReminderSwitchChanged })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement("div", { className: css(styles.label, !hasNotificationPermission && styles.labelDisabled) },
                "LEAN BODY\u304B\u3089\u306E\u304A\u77E5\u3089\u305B",
                React.createElement("div", { className: css(styles.description, !hasNotificationPermission && styles.descriptionDisabled) }, "\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u3084\u65B0\u7740\u30EC\u30C3\u30B9\u30F3\u60C5\u5831\u3092\u914D\u4FE1")),
            React.createElement(Switch, { disabled: !hasNotificationPermission, checked: hasNotificationPermission && notificationSettings.news, onChange: onNewsSwitchChanged })),
        showSetCourseDialog && (React.createElement(Dialog, { classObject: styles.setCourseDialog, onClickCancel: onSetCourseDialogCancel, isCloseButton: true, closeButtonLabel: CloseButtonLabel.Close, borderRadius: 14, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.setCourseDialogMessage) }, "\u3053\u306E\u6A5F\u80FD\u3092\u5229\u7528\u3059\u308B\u306B\u306F\u30B3\u30FC\u30B9\u3092\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044"),
                React.createElement(Button, { className: css(styles.setCourseDialogBtn), isRadius: true, buttonType: ButtonType.primary, onClick: onSetCourseClick }, "\u30B3\u30FC\u30B9\u3092\u8A2D\u5B9A\u3059\u308B")) }))));
};
var styles = StyleSheet.create({
    sectionContainer: {
        margin: '0 30px',
    },
    section: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '14px 0',
        ':first-child': {
            borderTop: "1px solid ".concat(Colors.BorderLightGray),
        },
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
    },
    label: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.TextDark,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    labelDisabled: {
        color: Colors.Gray,
    },
    description: {
        fontSize: 12,
        fontWeight: 400,
        color: Colors.Gray,
    },
    descriptionDisabled: {
        color: Colors.Gray,
    },
    courseScheduleBtn: {
        width: 156,
        height: 33,
        padding: 0,
        fontSize: 11,
    },
    timeSelect: {
        color: Colors.PrimaryDarkB,
        fontWeight: 500,
        fontSize: 12,
        padding: 0,
        border: 0,
        ':focus': { boxShadow: 'none' },
    },
    timeSelectList: {
        minWidth: 81,
    },
    timeSelectListItem: {
        fontSize: 12,
        minHeight: 33,
        height: 33,
    },
    setCourseDialog: {
        padding: '25px 24px 20px',
        width: 327,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    setCourseDialogMessage: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.56,
        marginBottom: 20,
    },
    setCourseDialogBtn: {
        padding: 0,
        fontSize: 16,
        fontWeight: 'bold',
        width: '100%',
        height: 48,
        borderRadius: 24,
    },
});
