import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { Colors } from '@lean-body/components/styles';
import { PaymentFormSections } from '../organisms/payment_form';
import { AmazonPayButton } from '../atoms/amazon_pay_button';
export var PaymentFormButton = function (_a) {
    var config = _a.config, selectedSection = _a.selectedSection, signedInAmazonPay = _a.signedInAmazonPay, isLoading = _a.isLoading, filledAllForm = _a.filledAllForm, basePlan = _a.basePlan, campaign = _a.campaign, onClick = _a.onClick, dataTest = _a.dataTest;
    var plan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.plan;
    var amazonPayPlan = basePlan === null || basePlan === void 0 ? void 0 : basePlan.amazonPayPlan;
    var submitLabel = (plan === null || plan === void 0 ? void 0 : plan.hasFreeTrial()) ? "".concat(plan.freeTrialString, "\u306E\u7121\u6599\u4F53\u9A13\u3092\u306F\u3058\u3081\u308B") : 'さっそく始める';
    var selectedAmazonPaySection = selectedSection === PaymentFormSections.amazonPay;
    var buttonDisabled = function () {
        return isLoading || (!selectedAmazonPaySection && !filledAllForm);
    };
    var displayAmazonPayButton = function () {
        return !signedInAmazonPay && amazonPayPlan && selectedAmazonPaySection;
    };
    return (React.createElement(React.Fragment, null, displayAmazonPayButton() ? (React.createElement(AmazonPayButton, { plan: amazonPayPlan, config: config, campaign: campaign })) : (React.createElement(Button, { buttonType: buttonDisabled() ? ButtonType.transparentPrimary : ButtonType.primary, className: css(styles.button), loading: isLoading, disabled: buttonDisabled(), onClick: onClick, dataTest: dataTest || 'submitPaymentForm', fullWidth: true },
        React.createElement("div", { className: css(styles.buttonSmallText) }, "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC\u3068\u5229\u7528\u898F\u7D04\u306B\u540C\u610F\u3057\u3066"),
        React.createElement("div", { className: css(styles.buttonText) }, submitLabel)))));
};
var styles = StyleSheet.create({
    button: {
        color: Colors.White,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        height: 67,
        padding: '10px 0',
        borderRadius: 4,
    },
    buttonSmallText: {
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: 0.48,
    },
    buttonText: {
        fontSize: 16,
        lineHeight: '160%',
        letterSpacing: 1.28,
    },
});
