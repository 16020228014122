var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { bind } from 'bind-decorator';
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import * as route from '@lean-body/src/route';
import { Header, FixedFooter, FIXED_FOOTER_HEIGHT } from '@lean-body/components/organisms';
import { Colors, MediaBreakPointUp, Sizes, drawerWidth } from '@lean-body/components/styles';
import { AuthStatus } from '@lean-body/src/interfaces';
import { isiOSApp } from '@lean-body/src/util';
import { useLocation } from 'react-router-dom';
import { useSmMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { LocalStorage } from '@lean-body/src/infra';
import { RootMain } from './root_main';
export var Main = function (_a) {
    var _b;
    var _c;
    var contentFactory = _a.contentFactory, vm = _a.vm, tracker = _a.tracker;
    var isSM = useSmMediaQuery();
    var location = useLocation();
    var localStorage = new LocalStorage();
    vm.location = location;
    var isHeaderDisplayable = vm.isHeaderDisplayable();
    var isFixedFooterDisplayable = vm.isFixedFooterDisplayable(isSM);
    var onClickAppstoreBannerHideButton = function () {
        localStorage.saveHideAppstore(true);
    };
    var styles = StyleSheet.create({
        container: {
            background: Colors.White,
        },
        content: (_b = {
                minHeight: '100vh',
                paddingTop: vm.headerHeight(),
                paddingBottom: isFixedFooterDisplayable ? FIXED_FOOTER_HEIGHT : 0
            },
            _b[MediaBreakPointUp.MD] = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                minHeight: '100vh',
                paddingTop: 0,
                paddingBottom: 0,
                marginLeft: isHeaderDisplayable ? drawerWidth.md : 0,
            },
            _b[MediaBreakPointUp.XL] = {
                marginLeft: isHeaderDisplayable ? drawerWidth.xl : 0,
            },
            _b),
    });
    // LIVEイベント時に使ってたフロートのバナー、また使う場合、画像はバナーAPIから取得するようにする
    var _d = React.useState(false), fixedBannerDisplayable = _d[0], setFixedBannerDisplayable = _d[1];
    var _e = React.useState(null), me = _e[0], setMe = _e[1];
    var _f = React.useState(null), profile = _f[0], setProfile = _f[1];
    var isMegalosUser = !!((_c = me === null || me === void 0 ? void 0 : me.payment) === null || _c === void 0 ? void 0 : _c.isMegalosUser);
    var _g = React.useState(null), footer = _g[0], setFooter = _g[1];
    var _h = React.useState(new Date()), homeDay = _h[0], setHomeDay = _h[1];
    React.useEffect(function () {
        var isMounted = true;
        Promise.all([vm.accountService.fetchMe(), vm.apiClient.fetchFooter(), vm.apiClient.fetchMypageProfile()]).then(function (_a) {
            var fetchedMe = _a[0], fetchedFooter = _a[1], fetchedProfile = _a[2];
            if (isMounted) {
                setMe(fetchedMe);
                setFooter(fetchedFooter);
                setProfile(fetchedProfile);
            }
        });
        return function () {
            isMounted = false;
        };
    }, []);
    if (!me || !profile)
        return null;
    return (React.createElement("div", { className: css(styles.container) },
        isHeaderDisplayable && (React.createElement(Header, { className: "", isMegalosUser: isMegalosUser, getUnreadAnnounecmentCount: vm.fetchUnreadAnnouncement, footer: footer, isDormant: vm.isDormant, payment: me.payment, accountService: vm.accountService, me: me, setMe: setMe, profile: profile, alertPresenter: vm.alertPresenter, apiClient: vm.apiClient, tracker: tracker })),
        React.createElement("div", { className: css(styles.content) },
            React.createElement(RootMain, { cf: contentFactory, profile: profile, setProfile: setProfile, me: me, setMe: setMe, homeDay: homeDay, setHomeDay: setHomeDay })),
        isFixedFooterDisplayable && (React.createElement(FixedFooter, { footer: footer, config: vm.config, fixedBannerDisplayable: fixedBannerDisplayable, hideFixedBanner: function () { return setFixedBannerDisplayable(false); }, onClickAppstoreBannerHideButton: onClickAppstoreBannerHideButton }))));
};
var MainPageVM = /** @class */ (function () {
    function MainPageVM(accountService, announcementService, alertPresenter, apiClient, config) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "announcementService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "location", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.accountService = accountService;
        this.announcementService = announcementService;
        this.alertPresenter = alertPresenter;
        this.apiClient = apiClient;
        this.config = config;
    }
    Object.defineProperty(MainPageVM.prototype, "fetchUnreadAnnouncement", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.announcementService.getUnreadAnnounecmentCount();
        }
    });
    Object.defineProperty(MainPageVM.prototype, "isDormant", {
        get: function () {
            return this.accountService.authStatus === AuthStatus.Dormant;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isFuture", {
        get: function () {
            return this.accountService.authStatus === AuthStatus.Future;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isCheckEmailPage", {
        get: function () {
            return route.routes.CHECK_EMAIL.matchPath(this.location.pathname);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isFutureUserPage", {
        get: function () {
            return route.routes.FUTURE_USER.matchPath(this.location.pathname);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isGoalMatchingAssessmentPage", {
        get: function () {
            return route.routes.GOAL_MATCHING_ASSESSMENT.matchPath(this.location.pathname);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isCoachingPage", {
        get: function () {
            return route.routes.COACHING.matchPath(this.location.pathname);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isMealRecordPage", {
        get: function () {
            return route.routes.DAILY_TASKS_SUBTASK.matchPathWithParams(this.location.pathname, {
                taskName: route.DailyTasksPageTaskName.meal,
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MainPageVM.prototype, "isOriginalPlaylistCreateOrUpdatePage", {
        get: function () {
            if (route.routes.NEW_PLAYLIST.matchPath(this.location.pathname))
                return true;
            if (route.routes.ORIGINAL_PLAYLIST_UPDATE.matchPath(this.location.pathname))
                return true;
            return false;
        },
        enumerable: false,
        configurable: true
    });
    // ヘッダーの高さ
    Object.defineProperty(MainPageVM.prototype, "headerHeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.isHeaderDisplayable()) {
                return 0;
            }
            else {
                return Sizes.MobileHeaderHeight;
            }
        }
    });
    // ヘッダーを表示するかどうか
    Object.defineProperty(MainPageVM.prototype, "isHeaderDisplayable", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.isCheckEmailPage || this.isFutureUserPage || this.isGoalMatchingAssessmentPage) {
                return false;
            }
            if (isiOSApp()) {
                return false;
            }
            return true;
        }
    });
    // 固定フッターを表示するかどうか。
    Object.defineProperty(MainPageVM.prototype, "isFixedFooterDisplayable", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isSM) {
            if (!isSM) {
                return false;
            }
            if (this.isDormant) {
                return false;
            }
            if (this.isCheckEmailPage ||
                this.isFutureUserPage ||
                this.isOriginalPlaylistCreateOrUpdatePage ||
                this.isGoalMatchingAssessmentPage ||
                this.isCoachingPage ||
                this.isMealRecordPage) {
                return false;
            }
            if (isiOSApp()) {
                return false;
            }
            return true;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], MainPageVM.prototype, "fetchUnreadAnnouncement", null);
    return MainPageVM;
}());
export { MainPageVM };
