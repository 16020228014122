var _a, _b, _c, _d, _e, _f, _g;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import * as dayjs from 'dayjs';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { LocalStorage, LocalStorageType, SessionStorage } from '@lean-body/src/infra';
import { ArrowDirection, ArrowIconWithMargin, Button, ButtonType, CalendarSolidIcon, InProgressAnimationIcon, } from '../atoms';
import { routes } from '@lean-body/src/route';
import { isAndroidApp } from '@lean-body/src/util';
import { GoalMatchingAssessmentEnableNotifications } from './goal_matching_assessment_enable_notifications';
import { CourseSelectList } from './course_select_list';
import { CourseDetail } from './course_detail';
import { MatchingAssessmentButtons } from '../molecules';
import { GOAL_MATCHING_ASSESSMENT_VERSION } from '../pages/main_goal_matching_assessment';
var GoalMatchingAssessmentResultState;
(function (GoalMatchingAssessmentResultState) {
    GoalMatchingAssessmentResultState[GoalMatchingAssessmentResultState["Loading"] = 0] = "Loading";
    GoalMatchingAssessmentResultState[GoalMatchingAssessmentResultState["Ready"] = 1] = "Ready";
    GoalMatchingAssessmentResultState[GoalMatchingAssessmentResultState["PickCourse"] = 2] = "PickCourse";
    GoalMatchingAssessmentResultState[GoalMatchingAssessmentResultState["ShowCourse"] = 3] = "ShowCourse";
    GoalMatchingAssessmentResultState[GoalMatchingAssessmentResultState["Finished"] = 4] = "Finished";
    GoalMatchingAssessmentResultState[GoalMatchingAssessmentResultState["RequestNotificationPermissions"] = 5] = "RequestNotificationPermissions";
})(GoalMatchingAssessmentResultState || (GoalMatchingAssessmentResultState = {}));
export var GoalMatchingAssessmentResult = function (_a) {
    var apiClient = _a.apiClient, config = _a.config, userName = _a.userName, currentAnswers = _a.currentAnswers, isWeightKeeping = _a.isWeightKeeping, isReassessment = _a.isReassessment, setProfile = _a.setProfile, onClickBack = _a.onClickBack;
    var navigate = useNavigate();
    var sessionStorage = new SessionStorage();
    var localStorage = new LocalStorage();
    var _b = useState(GoalMatchingAssessmentResultState.Loading), state = _b[0], setState = _b[1];
    var _c = useState(), result = _c[0], setResult = _c[1];
    var _d = useState(null), selectedCourse = _d[0], setSelectedCourse = _d[1];
    var _e = useState(), goalDate = _e[0], setGoalDate = _e[1];
    var _f = useState(false), hasCours = _f[0], setHasCours = _f[1];
    useEffect(function () {
        Promise.all([
            apiClient.fetchMyCourse(),
            apiClient.fetchGoalMatchingAssessmentResult({
                answers: currentAnswers.filter(Boolean),
                version: GOAL_MATCHING_ASSESSMENT_VERSION,
                courseCode: localStorage.loadObjByKey(LocalStorageType.CourseCode),
            }),
        ]).then(function (_a) {
            var course = _a[0], result = _a[1];
            setHasCours(!!course);
            setResult(result);
            setSelectedCourse(result.courses[0]);
            setGoalDate(dayjs.unix(Number(result.goalDate)));
            apiClient.fetchMypageProfile().then(function (v) {
                setProfile(v);
            });
            localStorage.removeKey(LocalStorageType.CourseCode);
        });
        var timeoutId = setTimeout(function () {
            if (isReassessment && isWeightKeeping && hasCours) {
                setState(GoalMatchingAssessmentResultState.Finished);
            }
            else {
                setState(GoalMatchingAssessmentResultState.Ready);
            }
        }, 2000);
        return function () { return clearTimeout(timeoutId); };
    }, []);
    var onSelectCourse = function (courseId) {
        var course = result.courses.find(function (course) { return course.id === courseId; });
        setSelectedCourse(course);
    };
    var onClickCourse = function (courseId) {
        onSelectCourse(courseId);
        window.scrollTo({ top: 0 });
        setState(GoalMatchingAssessmentResultState.ShowCourse);
    };
    var onClickBackToCourseList = function () {
        window.scrollTo({ top: 0 });
        setState(GoalMatchingAssessmentResultState.PickCourse);
    };
    var onClickNextButton = function () {
        switch (state) {
            case GoalMatchingAssessmentResultState.Ready:
                setState(isReassessment && hasCours
                    ? GoalMatchingAssessmentResultState.Finished
                    : GoalMatchingAssessmentResultState.PickCourse);
                break;
            case GoalMatchingAssessmentResultState.PickCourse:
            case GoalMatchingAssessmentResultState.ShowCourse:
                // 目標診断をスキップして、先にコース診断を行なった可能性もあるので、ここでチェック
                apiClient.fetchMyCourse().then(function (course) {
                    if (course === null) {
                        apiClient.selectCourse(selectedCourse.id);
                    }
                    else {
                        apiClient.changeCourse(selectedCourse.id);
                    }
                });
                setState(GoalMatchingAssessmentResultState.Finished);
                break;
            default:
                setState(GoalMatchingAssessmentResultState.Finished);
        }
    };
    var onClickStartWorkoutButton = function () {
        if (isAndroidApp() && !sessionStorage.loadAndroidDeviceNotificationPermission()) {
            setState(GoalMatchingAssessmentResultState.RequestNotificationPermissions);
        }
        else {
            navigate(routes.TOP.compile());
        }
    };
    return (React.createElement(React.Fragment, null, state === GoalMatchingAssessmentResultState.Loading ? (React.createElement("div", { className: css(styles.loading) },
        React.createElement("div", { className: css(styles.circle, styles.loadingCircle) },
            userName,
            "\u3055\u3093\u5C02\u7528",
            React.createElement("br", null),
            "\u30D7\u30E9\u30F3\u3092\u4F5C\u6210\u4E2D\u3067\u3059\u2026",
            React.createElement(InProgressAnimationIcon, { classObject: styles.inProgressIcon })))) : state === GoalMatchingAssessmentResultState.Ready ? (React.createElement("div", { className: css(styles.loadingComplete) },
        React.createElement("div", { className: css(styles.back) },
            React.createElement(ArrowIconWithMargin, { color: Colors.TextDark, direction: ArrowDirection.left, onClick: onClickBack })),
        React.createElement("div", { className: css(styles.circle, styles.completedCircle) },
            "\u30D7\u30E9\u30F3\u3092",
            React.createElement("br", null),
            "\u4F5C\u6210\u3057\u307E\u3057\u305F!",
            React.createElement("img", { src: "/assets/images/icons/cracker.png", className: css(styles.completedIcon) })),
        React.createElement(Button, { className: css(styles.nextButton, styles.nextButtonFixed), onClick: onClickNextButton, buttonType: ButtonType.primary }, "\u6B21\u3078"))) : state === GoalMatchingAssessmentResultState.PickCourse ? (React.createElement("div", { className: css(styles.pickCourse) },
        React.createElement("div", { className: css(styles.courseSelectTitle) }, "\u3069\u306E\u30B3\u30FC\u30B9\u306B\u53D6\u308A\u7D44\u307F\u307E\u3059\u304B\uFF1F"),
        React.createElement(CourseSelectList, { courses: result.courses, selectedCourseID: selectedCourse.id, config: config, topCourseRecommendMessage: "".concat(result.userName, "\u3055\u3093\u306B\u304A\u3059\u3059\u3081"), onClickSelect: onSelectCourse, onClickCourse: onClickCourse }),
        React.createElement("div", { className: css(styles.courseSelectNextButtonContainer) },
            React.createElement(Button, { className: css(styles.nextButton), onClick: onClickNextButton, buttonType: ButtonType.primary }, "\u6B21\u3078")))) : state === GoalMatchingAssessmentResultState.ShowCourse ? (React.createElement("div", null,
        React.createElement(CourseDetail, { course: selectedCourse, apiClient: apiClient, config: config, classObject: styles.courseDetailContainer }),
        React.createElement(MatchingAssessmentButtons, { classObject: styles.courseDetailButtonsContainer, nextButtonText: '選択して次へ', nextButtonClickable: true, onClickNext: function () { return onClickNextButton(); }, onClickBack: function () { return onClickBackToCourseList(); } }))) : state === GoalMatchingAssessmentResultState.Finished ? (React.createElement("div", { className: css(styles.result) },
        React.createElement("div", { className: css(styles.back) },
            React.createElement(ArrowIconWithMargin, { color: Colors.TextDark, direction: ArrowDirection.left, onClick: onClickBack, classObject: styles.backButton })),
        React.createElement("div", { className: css(styles.resultContent) },
            React.createElement("div", { className: css(styles.resultTitle) },
                React.createElement("img", { src: "/assets/images/icons/goal.png", className: css(styles.goalIcon) }),
                React.createElement("div", { className: css(styles.titleText) },
                    userName,
                    "\u3055\u3093\u306E\u76EE\u6A19")),
            React.createElement("div", { className: css(styles.resultValue) },
                !isReassessment && React.createElement("div", { className: css(styles.resultCourse) },
                    selectedCourse.name,
                    "\u3067"),
                isWeightKeeping ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css(styles.currentWeight) },
                        React.createElement("div", { className: css(styles.currentWeightNumber) }, result.weightGoal),
                        React.createElement("div", { className: css(styles.currentWeightUnit) }, "kg")),
                    React.createElement("div", { className: css(styles.currentWeightText) }, "\u3092\u7DAD\u6301\u3059\u308B"))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: css(styles.goalDate) },
                        React.createElement(CalendarSolidIcon, null),
                        React.createElement("div", { className: css(styles.goalDateText) },
                            React.createElement("div", { className: css(styles.dateNumber) }, goalDate.year()),
                            "\u5E74",
                            React.createElement("div", { className: css(styles.dateNumber) }, goalDate.month() + 1),
                            "\u6708",
                            React.createElement("div", { className: css(styles.dateNumber) }, goalDate.date()),
                            "\u65E5\u307E\u3067\u306B")),
                    React.createElement("div", { className: css(styles.weightGoal) },
                        React.createElement("div", { className: css(styles.weightGoalNumber) }, result.weightGoal),
                        React.createElement("div", { className: css(styles.weightGoalUnit) }, "kg"))))),
            React.createElement("div", { className: css(styles.resultDetail) },
                React.createElement("div", { className: css(styles.detailHeader) }, "1\u65E5\u306E\u76EE\u6A19"),
                React.createElement("div", { className: css(styles.detailBody) }, result.dailyGoals.map(function (v, i) { return (React.createElement("div", { className: css(styles.detailRow), key: i },
                    React.createElement("img", { className: css(styles.detailIcon), src: v.iconURL(config) }),
                    React.createElement("div", { className: css(styles.detailText) },
                        React.createElement("div", { className: css(styles.detailTitle) }, v.name),
                        React.createElement("div", { className: css(styles.detailValue, v.unit && styles.detailValueWithUnit) },
                            React.createElement("div", { className: css(styles.detailNumber, v.unit && styles.detailNumberWithUnit) }, v.value),
                            React.createElement("div", { className: css(styles.detailUnit) }, v.unit))))); }))),
            React.createElement("div", { className: css(styles.annotation) }, "\u76EE\u6A19\u306F\u3044\u3064\u3067\u3082\u5909\u3048\u3089\u308C\u307E\u3059\u3002\u6BCE\u65E5\u3067\u304D\u306A\u304F\u3066\u3082\u5927\u4E08\u592B\u3002LEAN\u00A0BODY\u306F\u3042\u306A\u305F\u306E\u7406\u60F3\u306E\u8EAB\u4F53\u3065\u304F\u308A\u306E\u305F\u3081\u306E\u52AA\u529B\u3092\u3001\u5168\u529B\u3067\u30B5\u30DD\u30FC\u30C8\u3092\u3057\u307E\u3059\u3002\u4E00\u7DD2\u306B\u9811\u5F35\u308A\u307E\u3057\u3087\u3046\uFF01")),
        React.createElement(Button, { className: css(styles.nextButton, styles.nextButtonFixed), onClick: onClickStartWorkoutButton, buttonType: ButtonType.primary }, "\u3053\u306E\u76EE\u6A19\u3067\u9811\u5F35\u308B\uFF01"))) : (state === GoalMatchingAssessmentResultState.RequestNotificationPermissions && (React.createElement(GoalMatchingAssessmentEnableNotifications, null)))));
};
var styles = StyleSheet.create({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        background: "repeating-linear-gradient(-45deg, ".concat(Colors.BackgroundBlue, ", ").concat(Colors.BackgroundBlue, " 3px, ").concat(Colors.CyanLightestB, " 0, ").concat(Colors.CyanLightestB, " 10px)"),
    },
    result: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%',
        background: "repeating-linear-gradient(-45deg, ".concat(Colors.BackgroundBlue, ", ").concat(Colors.BackgroundBlue, " 3px, ").concat(Colors.CyanLightestB, " 0, ").concat(Colors.CyanLightestB, " 10px)"),
    },
    loadingComplete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%',
        backgroundColor: Colors.BackgroundBlue,
        backgroundImage: 'url(/assets/images/backgrounds/background_triangle_icon.svg)',
    },
    pickCourse: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 130,
    },
    courseSelectNextButtonContainer: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: Colors.White,
        display: 'flex',
        justifyContent: 'center',
        padding: '14px 0 40px',
    },
    courseSelectTitle: {
        marginTop: 26,
        marginBottom: 30,
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
        letterSpacing: 1.6,
    },
    courseDetailContainer: {
        paddingBottom: 130,
    },
    courseDetailButtonsContainer: {
        width: '100%',
        textAlign: 'center',
    },
    back: (_a = {
            margin: '24px 36px 0',
            width: 'calc(100% - 72px)',
            height: 24
        },
        _a[MediaBreakPointUp.MD] = {
            margin: '24px 16px 0',
            width: 'calc(100% - 32px)',
            height: 32,
        },
        _a),
    backButton: (_b = {
            width: 24,
            height: 24
        },
        _b[MediaBreakPointUp.MD] = {
            width: 32,
            height: 32,
        },
        _b),
    goalIcon: {
        width: 40,
        height: 40,
    },
    resultContent: (_c = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 'calc(100% - 32px)',
            gap: 30
        },
        _c[MediaBreakPointUp.MD] = {
            width: 343,
            marginTop: 122,
            marginBottom: 200,
        },
        _c),
    resultTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
    },
    resultValue: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    resultCourse: {
        marginBottom: 10,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    resultDetail: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        borderRadius: 10,
    },
    titleText: {
        color: Colors.Black,
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 1.6,
    },
    currentWeight: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        fontWeight: 'bold',
        lineHeight: '140%',
    },
    currentWeightNumber: {
        fontSize: 36,
        letterSpacing: 1.44,
    },
    currentWeightUnit: {
        fontSize: 14,
        letterSpacing: 0.56,
        marginTop: 10,
    },
    currentWeightText: {
        height: 25,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 1.44,
    },
    goalDate: {
        display: 'flex',
        alignItems: 'center',
        height: 28,
        gap: 8,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    goalDateText: {
        display: 'flex',
        alignItems: 'flex-end',
        height: '100%',
    },
    dateNumber: {
        fontSize: 20,
        letterSpacing: 0.8,
        marginBottom: 2,
    },
    weightGoal: {
        display: 'flex',
        alignItems: 'center',
        height: 45,
        fontWeight: 'bold',
        lineHeight: '140%',
    },
    weightGoalNumber: {
        fontSize: 32,
        letterSpacing: 1.28,
    },
    weightGoalUnit: {
        fontSize: 14,
        letterSpacing: 0.56,
        marginTop: 10,
    },
    detailHeader: {
        backgroundColor: Colors.Accent,
        color: Colors.White,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.12,
        width: '100%',
        height: 34,
        padding: '6px 0',
        borderRadius: '10px 10px 0 0',
    },
    detailBody: {
        backgroundColor: Colors.White,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 14,
        padding: '14px 20px 20px',
        borderRadius: '0 0 10px 10px',
        borderRight: "2px solid ".concat(Colors.Accent),
        borderBottom: "2px solid ".concat(Colors.Accent),
        borderLeft: "2px solid ".concat(Colors.Accent),
    },
    detailRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    detailIcon: {
        width: 32,
        height: 32,
    },
    detailText: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },
    detailTitle: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        height: 19,
    },
    detailValue: {
        display: 'flex',
        alignItems: 'center',
        height: 19,
        lineHeight: '160%',
    },
    detailValueWithUnit: {
        height: 32,
    },
    detailNumber: {
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: 0.8,
    },
    detailNumberWithUnit: {
        fontSize: 20,
        letterSpacing: 0.48,
    },
    detailUnit: {
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0.48,
    },
    annotation: {
        color: Colors.GrayDarkest,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
        width: '100%',
    },
    circle: (_d = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: Colors.White,
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: 1.6,
            width: 334,
            height: 334,
            borderRadius: 334
        },
        _d[MediaBreakPointUp.MD] = {
            marginBottom: 200,
        },
        _d[MediaBreakPointUp.XL] = {
            marginBottom: 250,
        },
        _d),
    loadingCircle: (_e = {
            color: Colors.Black,
            marginTop: 126,
            gap: 30
        },
        _e[MediaBreakPointUp.MD] = {
            marginTop: 198,
        },
        _e),
    completedCircle: (_f = {
            color: Colors.TextDark,
            marginTop: 78,
            gap: 37
        },
        _f[MediaBreakPointUp.MD] = {
            marginTop: 150,
        },
        _f),
    inProgressIcon: {
        width: 78,
        height: 78,
    },
    completedIcon: {
        width: 80,
        height: 80,
    },
    nextButton: (_g = {
            width: 'calc(100% - 32px)',
            borderRadius: 24
        },
        _g[MediaBreakPointUp.MD] = {
            width: 343,
        },
        _g),
    nextButtonFixed: {
        position: 'fixed',
        bottom: 0,
        margin: 16,
    },
});
