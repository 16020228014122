import { BasePlan } from './plan';
export var FRIEND_INVITATION_CODES = ['friend-invitation-cp', 'friend-invitation-cp2'];
// キャンペーンコードに関するエンティティ
var Campaign = /** @class */ (function () {
    function Campaign(campaignData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "campaignCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "plans", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "basePlans", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = campaignData.id;
        this.campaignCode = campaignData.campaignCode;
        this.name = campaignData.name;
        this.isActive = campaignData.isActive;
        this.basePlans = campaignData.basePlans.map(function (p) { return new BasePlan(p, config); });
    }
    Object.defineProperty(Campaign.prototype, "isFriendInvitation", {
        get: function () {
            return FRIEND_INVITATION_CODES.includes(this.campaignCode);
        },
        enumerable: false,
        configurable: true
    });
    return Campaign;
}());
export { Campaign };
