var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import { FRIEND_INVITATION_CODES } from '../domain';
dayjs.extend(isToday);
// 過去に使っていて、命名が被るとバグが出るのでコメントアウトで残しています
export var LocalStorageType = {
    Stats: 'stats', // Deprecated: 初期はLocalStorageを1つのkeyで管理してました
    AuthTokenKey: 'authToken',
    HideAppstore: 'hideAppstore',
    LoginStateKey: 'loggedIn',
    // ReleaseNotificationIds: 'releaseNotificationIds',
    // HalloweenLiveEventDialog2021: 'halloweenLiveEventDialog2021',
    HomeDialogId: 'homeDialogId',
    // HoldingLiveLessonId: 'holdingLiveLessonId',
    // SmartHabitDialog: 'smartHabitDialog',
    NavigationTabs: 'navigationTabs',
    UserSeasonAggregateRank: 'userSeasonAggregateRank',
    LoggedInPlatform: 'loggedInPlatform',
    BqSnsShareClickParams: 'BqSnsShareClickParams',
    TwitterRedirectData: 'TwitterRedirectData',
    SearchHistories: 'SearchHistories',
    // CourseInstructorCode: 'courseInstructorCode',
    CourseCode: 'courseCode',
    ShowMealImageAnalysisDescriptionDialog: 'showMealImageAnalysisDescriptionDialog',
    DeletedAtCancelUnsubscribeBanner: 'deletedAtCancelUnsubscribeBanner',
    CampaginCode: 'campaignCode',
    IsBulkRegistration: 'isBulkRegistration',
    AndroidNotificationPermissionRequestUiDismissals: 'android_notification_permission_request_ui_dismissals',
};
export var SEARCH_HISTORIES_MAX_NUM = 10; // 検索履歴の最大保存数
export var CAMPAIGN_CODE_RESET = 'reset'; // LPからの遷移で、無料期間のプランを確実に反映できるように、localstorageにあるキャンペーンコードを削除する
var CAMPAIGN_CODE_RESET_2024_05_15 = 'reset_2024_05_15'; // 2024年5月15日価格改定対応のためのコード。CAMPAIGN_CODE_RESETと同様
export var SignInType = {
    email: 'email',
};
export var LoggedInPlatForm = {
    facebook: 'facebook',
    apple: 'apple',
    line: 'line',
};
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
        Object.defineProperty(this, "storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: localStorage
        });
    }
    /*-----------------------------------*/
    Object.defineProperty(LocalStorage.prototype, "loadObjByKey", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            var stats = this.storage.getItem(key);
            return JSON.parse(stats);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "removeKey", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (key) {
            this.storage.removeItem(key);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveLoginState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (loggedIn) {
            this.storage.setItem(LocalStorageType.LoginStateKey, JSON.stringify(loggedIn));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLoginState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.loadObjByKey(LocalStorageType.LoginStateKey);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "destroy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(LocalStorageType.Stats);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveStats", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stats) {
            var saveData = Object.assign({}, this.loadStats(), __assign({}, stats));
            if (saveData) {
                this.storage.setItem(LocalStorageType.Stats, JSON.stringify(saveData));
            }
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadStats", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.loadObjByKey(LocalStorageType.Stats);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveSignInType", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (type) {
            return this.saveStats({ signInType: type });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadSignInType", {
        get: function () {
            var _a;
            return (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.signInType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHideAppstore", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (hideAppstore) {
            this.saveStats({ hideAppstore: hideAppstore });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHideAppstore", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.hideAppstore) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHideFavoriteMessageDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (hide) {
            this.saveStats({ hideFavoriteMessageDialog: hide });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHideFavoriteMessageDialog", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.hideFavoriteMessageDialog) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveShowMealTaskNotificationPermissionDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (show) {
            this.saveStats({ showMealTaskNotificationPermissionDialog: show });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadShowMealTaskNotificationPermissionDialog", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.showMealTaskNotificationPermissionDialog) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHasRecordedMeal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            this.saveStats({ hasRecordedMeal: value });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHasRecordedMeal", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.hasRecordedMeal) || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveInvitationCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (code) {
            // SNSログイン等でリダイレクトされた後にinvitationCodeが空に上書きされないようにcodeがある時だけ保存している
            // 「invitationCodeを保存しつつ、2週間無料プランで登録」という挙動をさせないために、loadInvitationCode()でハンドリングしています
            if (code) {
                this.saveStats({ invitationCode: code });
            }
        }
    });
    Object.defineProperty(LocalStorage.prototype, "removeInvitationCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.saveStats({ invitationCode: null });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadInvitationCode", {
        // お友だち紹介コード
        get: function () {
            var _a;
            // 友達招待のキャンペーンコードがある時のみ、invitationCodeをリクエストに含めることができる
            var isFriendInvitationCampaign = FRIEND_INVITATION_CODES.includes(this.loadCampaingCode);
            return isFriendInvitationCampaign ? (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.invitationCode : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveCampaingCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (campaignCode) {
            if (campaignCode === CAMPAIGN_CODE_RESET || campaignCode === CAMPAIGN_CODE_RESET_2024_05_15) {
                this.storage.removeItem(LocalStorageType.CampaginCode);
                this.saveStats({ LP: null }); // 旧仕様で保存してるパラメータも削除
            }
            else if (campaignCode) {
                this.storage.setItem(LocalStorageType.CampaginCode, JSON.stringify(campaignCode));
            }
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadCampaingCode", {
        get: function () {
            var _a, _b;
            // 旧仕様で保存してるパラメータを保存してる可能性がある
            var oldCampaignCode = ((_b = (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.LP) === null || _b === void 0 ? void 0 : _b.campaignCode) || '';
            if (oldCampaignCode !== '') {
                return oldCampaignCode;
            }
            return this.loadObjByKey(LocalStorageType.CampaginCode) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "lpParamsHasMegalosCampaignCode", {
        // メガロスクーポンコードを利用しようとしているか
        get: function () {
            return this.loadCampaingCode === 'megalos_leanbody';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (referrer) {
            this.saveStats({ referrer: referrer });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadStatsReferrer", {
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.referrer) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveLpMessageReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (referrer) {
            this.saveStats({ lpMessage: { referrer: referrer } });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLpMessageReferrer", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.lpMessage) === null || _b === void 0 ? void 0 : _b.referrer) || null;
        },
        enumerable: false,
        configurable: true
    });
    // RELOCLUBユーザーIDの取得
    Object.defineProperty(LocalStorage.prototype, "saveReloclubKid", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (kid) {
            this.saveStats({ reloclubKid: kid });
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadReloclubKid", {
        // RELOCLUBユーザーIDの取得
        get: function () {
            var _a;
            return ((_a = this.loadStats()) === null || _a === void 0 ? void 0 : _a.reloclubKid) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveHomeDialogId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            this.storage.setItem(LocalStorageType.HomeDialogId, JSON.stringify(id));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadHomeDialogId", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.HomeDialogId) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveCourseCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (code) {
            this.storage.setItem(LocalStorageType.CourseCode, JSON.stringify(code));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveLiveScheduleTabNoticed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var navigationTab = Object.assign(this.loadNavigationTabNotice, {
                liveSchedule: new Date(),
            });
            this.storage.setItem(LocalStorageType.NavigationTabs, JSON.stringify(navigationTab));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveActivityTabNoticed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var navigationTab = Object.assign(this.loadNavigationTabNotice, { activity: new Date() });
            this.storage.setItem(LocalStorageType.NavigationTabs, JSON.stringify(navigationTab));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadNavigationTabNotice", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.NavigationTabs) || {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "noticedLiveScheduleTabToday", {
        get: function () {
            if (!this.loadNavigationTabNotice.liveSchedule)
                return false;
            return dayjs(this.loadNavigationTabNotice.liveSchedule).isToday();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "noticedActivityTabToday", {
        get: function () {
            if (!this.loadNavigationTabNotice.activity)
                return false;
            return dayjs(this.loadNavigationTabNotice.activity).isToday();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveUserSeasonAggregateRank", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.UserSeasonAggregateRank, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadUserSeasonAggregateRank", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.UserSeasonAggregateRank);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveLoggedInPlatform", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.LoggedInPlatform, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadLoggedInPlatform", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.LoggedInPlatform);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "resetLoggedInPlatform", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(LocalStorageType.LoggedInPlatform);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveBqSnsShareClickParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.BqSnsShareClickParams, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadBqSnsShareClickParams", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.BqSnsShareClickParams);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveTwitterRedirectData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.TwitterRedirectData, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadTwitterRedirectData", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.TwitterRedirectData);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "resetTwitterData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.storage.removeItem(LocalStorageType.BqSnsShareClickParams);
            this.storage.removeItem(LocalStorageType.TwitterRedirectData);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveSearchHistories", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.SearchHistories, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadSearchHistories", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.SearchHistories);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "saveIsBulkRegistration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.IsBulkRegistration, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadIsBulkRegistration", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.IsBulkRegistration);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "removeAfterCCRegistration", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // 友だち紹介コードは再利用できないように削除
            this.removeInvitationCode();
            // クレカ登録以降はデータ永続化させたくないので、RELOCLUBのユーザIDをリセット
            this.saveReloclubKid(null);
            // campaignCodeはクレカ登録後、次回使えなくしたいので削除する
            this.storage.removeItem(LocalStorageType.CampaginCode);
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadAndroidNotificationPermissionRequestUIDismissals", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var setting = this.storage.getItem(LocalStorageType.AndroidNotificationPermissionRequestUiDismissals);
            if (setting) {
                return JSON.parse(setting);
            }
            return {
                liveLessonBanner: false,
                mealTaskDialog: false,
            };
        }
    });
    Object.defineProperty(LocalStorage.prototype, "setAndroidNotificationPermissionRequestUIDismissals", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            this.storage.setItem(LocalStorageType.AndroidNotificationPermissionRequestUiDismissals, JSON.stringify(params));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "saveShowMealImageAnalysisDescriptionDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            this.storage.setItem(LocalStorageType.ShowMealImageAnalysisDescriptionDialog, JSON.stringify(v));
        }
    });
    Object.defineProperty(LocalStorage.prototype, "loadShowMealImageAnalysisDescriptionDialog", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.ShowMealImageAnalysisDescriptionDialog);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "loadDeletedAtCancelUnsubscribeBanner", {
        get: function () {
            return this.loadObjByKey(LocalStorageType.DeletedAtCancelUnsubscribeBanner) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocalStorage.prototype, "setDeletedAtCancelUnsubscribeBanner", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (unixdate) {
            this.storage.setItem(LocalStorageType.DeletedAtCancelUnsubscribeBanner, JSON.stringify(unixdate));
        }
    });
    return LocalStorage;
}());
export { LocalStorage };
