var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { ZIndex } from '../constants';
import { Avatar } from '@mui/material';
import { Colors, MediaBreakPointUp, Sizes, drawerWidth } from '@lean-body/components/styles';
import { Menu } from '@lean-body/components/organisms';
import { SettingMenu, SettingItemKeys } from '@lean-body/components/organisms/setting_menu';
import { useQuery, useSmMediaQuery } from '@lean-body/src/util';
import { routes } from '@lean-body/src/route';
import { BellIcon } from '../atoms/icons/bell_icon';
import { Logo } from '../atoms';
import { ChatIcon } from '../atoms/icons/chat_icon';
import { IsCoachingCampaignTerm } from './coaching_lp';
export var Header = function (_a) {
    var className = _a.className, isDormant = _a.isDormant, isMegalosUser = _a.isMegalosUser, payment = _a.payment, footer = _a.footer, getUnreadAnnounecmentCount = _a.getUnreadAnnounecmentCount, accountService = _a.accountService, me = _a.me, profile = _a.profile;
    var navigate = useNavigate();
    var query = useQuery();
    var isSM = useSmMediaQuery();
    var _b = React.useState(null), unreadAnnouncementCount = _b[0], setUnreadAnnouncementCount = _b[1];
    var _c = React.useState(false), openSetting = _c[0], setOpenSetting = _c[1];
    var _d = React.useState(SettingItemKeys[query.get('key')]), currentItem = _d[0], setCurrentItem = _d[1];
    var coachingBadgeMessage;
    if (!me.payment.coachingPayment) {
        coachingBadgeMessage = IsCoachingCampaignTerm() ? 'チャンス!' : 'NEW!';
    }
    React.useEffect(function () {
        var isUnmounted = false;
        if (!isDormant) {
            getUnreadAnnounecmentCount().then(function (v) {
                if (!isUnmounted)
                    setUnreadAnnouncementCount(v);
            });
        }
        return function () {
            isUnmounted = true;
        };
    }, []);
    var onClickSwitchSettingMenu = function () {
        setOpenSetting(!openSetting);
        window.scrollTo(0, 0);
    };
    var onClickSettingMenu = function (key) {
        setCurrentItem(key);
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys[key]));
        setOpenSetting(false);
    };
    var onClickCoaching = function () {
        navigate(routes.COACHING.compile());
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.positionFixed) },
            React.createElement("div", { className: [className, css(styles.container)].join(' '), id: "fixedHeader" },
                React.createElement("div", { onClick: onClickSwitchSettingMenu },
                    React.createElement(Avatar, { "data-cy": 'setting-menu', src: profile.pictureSrc, className: css(styles.avatar, profile.profilePictureSrc && styles.avatarBorder) })),
                React.createElement(Link, { to: routes.TOP.compile(), className: css(styles.logoLink) },
                    React.createElement(Logo, { classObject: styles.logo })),
                !isDormant && (React.createElement("div", { className: css(styles.rightItems) },
                    payment.coachingPaymentAvailable && (React.createElement("div", { className: css(styles.chatContainer), onClick: onClickCoaching },
                        React.createElement(ChatIcon, { classObject: styles.chat }),
                        coachingBadgeMessage && React.createElement("div", { className: css(styles.coachingMessage) }, coachingBadgeMessage))),
                    React.createElement(Link, { to: routes.ANNOUNCEMENT_LIST.compile() },
                        React.createElement(BellIcon, { hasBadge: unreadAnnouncementCount === null || unreadAnnouncementCount === void 0 ? void 0 : unreadAnnouncementCount.moreThanZero, classObject: styles.bell }))))),
            !isSM && (React.createElement(Menu, { className: css(styles.menu), isMegalosUser: isMegalosUser, unreadAnnouncementCount: unreadAnnouncementCount, me: me, footer: footer, onClickCoaching: onClickCoaching }))),
        isSM && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(!openSetting && styles.displayNone, styles.settingMenuBackGround), onClick: onClickSwitchSettingMenu }),
            React.createElement(SettingMenu, { classObject: [styles.settingMenu, openSetting ? styles.menuOpen : styles.menuClose], currentItem: currentItem, onClick: onClickSettingMenu, onClickClose: onClickSwitchSettingMenu, isMegalosUser: payment === null || payment === void 0 ? void 0 : payment.isMegalosUser, me: me, isStripeUser: payment.isStripePayment(), onClickSignOut: function () {
                    accountService.signOut();
                }, profile: profile })))));
};
var styles = StyleSheet.create({
    container: (_a = {
            position: 'fixed',
            zIndex: ZIndex.Header,
            height: Sizes.MobileHeaderHeight,
            width: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
        },
        _a[MediaBreakPointUp.MD] = {
            display: 'none',
        },
        _a),
    positionFixed: {
        position: 'fixed',
        zIndex: ZIndex.Header,
        width: '100%',
    },
    menu: (_b = {
            display: 'block',
            position: 'fixed',
            top: 0,
            left: 0,
            width: drawerWidth.xs,
            height: '100%',
            zIndex: ZIndex.MainMenu,
            transform: 'translate3d(-100%, 0, 0)',
            transition: 'all .3s ease',
            boxShadow: 'none'
        },
        _b[MediaBreakPointUp.MD] = {
            width: drawerWidth.md,
            transform: 'translate3d(0, 0, 0)',
            boxShadow: 'none',
        },
        _b[MediaBreakPointUp.XL] = {
            width: drawerWidth.xl,
        },
        _b),
    logo: {
        width: 141,
        height: 'auto',
    },
    logoLink: {
        display: 'block',
    },
    rightItems: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: 16,
        top: '50%',
        transform: 'translate(0, -50%)',
    },
    heartIconContainer: {
        width: 26,
        height: 20,
        marginRight: 12,
    },
    chatContainer: {
        position: 'relative',
    },
    coachingMessage: {
        fontSize: 8,
        fontWeight: 'bold',
        color: Colors.White,
        backgroundColor: Colors.Accent,
        padding: '1.8px 3px 1px',
        position: 'absolute',
        top: 0,
        right: 7,
        borderRadius: 100,
        lineHeight: 1,
    },
    chat: {
        marginRight: 20,
    },
    bell: {
        width: 22,
        height: 23,
    },
    settingMenu: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        overflow: 'scroll',
        width: 'calc(100% - 60px)',
        border: "1px ".concat(Colors.BorderLightGray, " solid"),
        zIndex: ZIndex.SettingMenuModal,
    },
    menuOpen: {
        transform: 'translate(0, 0)',
    },
    menuClose: {
        transform: 'translate(-100%, 0)',
    },
    settingMenuBackGround: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: Colors.Black,
        opacity: 0.4,
        zIndex: ZIndex.SettingMenuModal,
    },
    displayNone: {
        display: 'none',
    },
    avatar: {
        position: 'absolute',
        left: 18,
        top: '50%',
        transform: 'translate(0, -50%)',
        width: 28,
        height: 28,
        cursor: 'pointer',
    },
    avatarBorder: {
        border: "0.5px solid ".concat(Colors.Gray),
    },
});
