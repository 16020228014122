var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { checkSameArray, validateDate } from '@lean-body/src/util';
import { UserWeight } from '@lean-body/src/domain';
import { Input, Select, InputButtonTypeRadioButton, InputButtonTypeCheckbox } from '@lean-body/components/atoms';
import { PageLoading, SelectProfilePicture } from '@lean-body/components/organisms';
import { SettingItemKeys } from '@lean-body/components/organisms/setting_menu';
import { FixedFooterUpdateButton, DateSelectForm } from '@lean-body/components/molecules';
import { WeightGoalAchievedDialog } from '../molecules/weight_goal_achieved_dialog';
import { GoalMatchingAssessmentPageQuery } from '../pages/main_goal_matching_assessment';
var useState = React.useState, useEffect = React.useEffect;
export var ProfileChangeSubsection = function (_a) {
    var classObject = _a.classObject, apiClient = _a.apiClient, tracker = _a.tracker, alertPresenter = _a.alertPresenter, setItemKey = _a.setItemKey, profile = _a.profile, setProfile = _a.setProfile;
    var navigate = useNavigate();
    var _b = useState(profile.userName), userName = _b[0], setUserName = _b[1];
    var _c = useState(profile.birthday), birthday = _c[0], setBirthday = _c[1];
    var _d = useState(profile.gender.toString()), gender = _d[0], setGender = _d[1];
    var _e = useState(profile.prefecture), prefecture = _e[0], setPrefecture = _e[1];
    var _f = useState(!profile.weight ? '' : profile.weight.toString()), weight = _f[0], setWeight = _f[1];
    var _g = useState(!profile.height ? '' : profile.height.toString()), height = _g[0], setHeight = _g[1];
    var _h = useState(profile.activityLevel.id || null), activityLevelType = _h[0], setActivityLevelType = _h[1];
    var _j = useState(profile.worriesIdsToString), worries = _j[0], setWorries = _j[1];
    var _k = useState(profile.interestsIdsToString), interests = _k[0], setInterests = _k[1];
    var _l = useState(profile.pictureSrc), imgSrc = _l[0], setImgSrc = _l[1];
    var _m = useState(profile.pictureSrc), clipFileSrc = _m[0], setClipFileSrc = _m[1];
    var _o = useState(), file = _o[0], setFile = _o[1];
    var _p = useState(), genderOptions = _p[0], setGenderOptions = _p[1];
    var _q = useState(), prefectureOptions = _q[0], setPrefectureOptions = _q[1];
    var _r = useState(), activityLevelOptions = _r[0], setActivityLevelOptions = _r[1];
    var _s = useState(), worryOptions = _s[0], setWorryOptions = _s[1];
    var _t = useState(), interestOptions = _t[0], setInterestOptions = _t[1];
    var _u = useState(false), saveButtonClickable = _u[0], setSaveButtonClickable = _u[1];
    var _v = useState(true), isLoading = _v[0], setIsLoading = _v[1];
    var _w = React.useState(), isShowGoalAchievedDialog = _w[0], setIsShowGoalAchievedDialog = _w[1];
    var _x = React.useState(false), isValidateRealtime = _x[0], setIsValidateRealtime = _x[1];
    var _y = React.useState({
        userName: '',
        birthday: '',
        gender: '',
        height: '',
        weight: '',
        activityLevelType: '',
    }), validationErrorMessages = _y[0], setValidationErrorMessages = _y[1];
    useEffect(function () {
        Promise.all([
            apiClient.fetchGenders(),
            apiClient.fetchPrefectures(),
            apiClient.fetchActivityLevels(),
            apiClient.fetchWorries(),
            apiClient.fetchInterests(),
        ])
            .then(function (_a) {
            var fetchedGenders = _a[0], fetchedPrefectures = _a[1], fetchedActivityLevelOptions = _a[2], fetchedWorryOptions = _a[3], fetchedInterestOptions = _a[4];
            setGenderOptions(fetchedGenders);
            setPrefectureOptions(fetchedPrefectures);
            setActivityLevelOptions(fetchedActivityLevelOptions.map(function (activityLevel) {
                return { name: activityLevel.name, value: activityLevel.id };
            }));
            setWorryOptions(fetchedWorryOptions);
            setInterestOptions(fetchedInterestOptions);
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, []);
    useEffect(function () {
        setSaveButtonClickable(changedInputValue() && (isValidateRealtime ? validate(false) : true));
    }, [imgSrc, userName, birthday, gender, prefecture, weight, height, activityLevelType, worries, interests]);
    var validate = function (showAlert) {
        var errorMessages = {
            userName: '',
            birthday: '',
            gender: '',
            height: '',
            weight: '',
            activityLevelType: '',
        };
        if (userName.length === 0) {
            errorMessages.userName = 'ニックネームを入力してください';
        }
        var format = 'YYYY-MM-DD';
        if (birthday.length === 0 || !validateDate(birthday, format)) {
            errorMessages.birthday = '誕生日を入力してください';
        }
        if (gender === '0') {
            errorMessages.gender = '性別を入力してください';
        }
        if (!height || Number(height) < 1) {
            errorMessages.height = '身長を入力してください';
        }
        if (!weight || Number(weight) < 1) {
            errorMessages.weight = '体重を入力してください';
        }
        if (!activityLevelType) {
            errorMessages.activityLevelType = '普段の運動量を選択してください';
        }
        setValidationErrorMessages(errorMessages);
        var hasError = !!Object.values(errorMessages).join('');
        if (hasError) {
            setIsValidateRealtime(true);
            if (showAlert) {
                alertPresenter.showError(null, '入力内容を確認してください');
            }
        }
        return !hasError;
    };
    var changeUserName = function (event) {
        if (profile.validateUserName(event.target.value)) {
            setUserName(event.target.value);
        }
    };
    var changeGender = function (event) {
        setGender(event.target.value.toString());
    };
    var changePrefecture = function (event) {
        setPrefecture(event.target.value);
    };
    var changeWeight = function (event) {
        var value = UserWeight.formatWeight(event.target.value);
        setWeight(value);
    };
    var changeHeight = function (event) {
        if (profile.validateHeight(Number(event.target.value))) {
            setHeight(event.target.value);
        }
    };
    var changeWorry = function (value) {
        var WORRY_NONE = '12';
        if (value === WORRY_NONE && !worries.includes(WORRY_NONE)) {
            setWorries([value]);
        }
        else if (worries.includes(WORRY_NONE)) {
            setWorries(__spreadArray(__spreadArray([], worries, true), [value], false).filter(function (v) { return v !== WORRY_NONE; }));
        }
    };
    var changedInputValue = function () {
        return (imgSrc !== profile.pictureSrc ||
            userName !== profile.userName ||
            birthday !== profile.birthday ||
            gender !== profile.gender.toString() ||
            prefecture !== profile.prefecture ||
            weight !== (!profile.weight ? '' : profile.weight.toString()) ||
            height !== (!profile.height ? '' : profile.height.toString()) ||
            activityLevelType !== (profile.activityLevel.id || null) ||
            !checkSameArray(worries, profile.worriesIdsToString) ||
            !checkSameArray(interests, profile.interestsIdsToString));
    };
    var onClickCancel = function () {
        setItemKey(SettingItemKeys.mypage);
        window.scrollTo(0, 0);
    };
    var selectFile = function (selectedFile) {
        setFile(selectedFile);
        setImgSrc(window.URL.createObjectURL(selectedFile));
    };
    // 更新対象取得
    var buildUpdateItems = function () {
        return {
            userName: userName,
            birthday: birthday,
            gender: Number(gender),
            prefecture: prefecture,
            height: Number(height),
            weight: Number(weight),
            activityLevelType: activityLevelType,
            worryIds: worries.map(function (str) { return parseInt(str, 10); }),
            interestIds: interests.map(function (str) { return parseInt(str, 10); }),
        };
    };
    var onClickSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var profile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSaveButtonClickable(true);
                    if (!validate(true)) {
                        setSaveButtonClickable(false);
                        return [2 /*return*/];
                    }
                    if (!(file != null)) return [3 /*break*/, 2];
                    return [4 /*yield*/, apiClient.uploadProfilePicture(file)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, apiClient.patchMypageProfile(buildUpdateItems())];
                case 3:
                    profile = _a.sent();
                    setProfile(profile);
                    tracker.trackMypageProfileUpdate(profile);
                    if (profile.showGoalAchievedDialog) {
                        setIsShowGoalAchievedDialog(true);
                    }
                    else {
                        navigateToMyPage();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickSetNewGoal = function () {
        navigate("".concat(routes.GOAL_MATCHING_ASSESSMENT.compile(), "?").concat(GoalMatchingAssessmentPageQuery.change, "=1"));
    };
    var navigateToMyPage = function () {
        setItemKey(SettingItemKeys.mypage);
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.mypage));
        window.scrollTo(0, 0);
    };
    if (isLoading) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement(SelectProfilePicture, { submit: selectFile, imgSrc: imgSrc, clipFileSrc: clipFileSrc, setClipFileSrc: setClipFileSrc }),
        React.createElement("div", { className: css(styles.section) },
            React.createElement(Input, { type: "text", value: userName, placeholder: "15\u6587\u5B57\u4EE5\u5185", dataTest: "name", containerClassObject: styles.inputContainer, onChange: changeUserName, label: "\u30CB\u30C3\u30AF\u30CD\u30FC\u30E0", required: true, hasValidationError: !!validationErrorMessages.userName, validationErrorMessage: validationErrorMessages.userName }),
            React.createElement(DateSelectForm, { containerClassObject: styles.inputContainer, label: "\u8A95\u751F\u65E5", required: true, currentValue: birthday, setCurrentValue: setBirthday, hasValidationError: !!validationErrorMessages.birthday, validationErrorMessage: validationErrorMessages.birthday }),
            React.createElement(Select, { selectOptions: genderOptions, label: "\u6027\u5225", defaultValue: gender.toString(), onChange: changeGender, containerClassObject: styles.selectContainer, displayIcon: true, placeholder: "\u6027\u5225\u3092\u8FFD\u52A0", placeholderValue: "0", required: true, hasValidationError: !!validationErrorMessages.gender, validationErrorMessage: validationErrorMessages.gender }),
            React.createElement(Select, { selectOptions: prefectureOptions, label: "\u5C45\u4F4F\u5730", defaultValue: prefecture, onChange: changePrefecture, containerClassObject: styles.selectContainer, displayIcon: true, placeholder: "\u5C45\u4F4F\u5730\u3092\u8FFD\u52A0", placeholderValue: "" })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement(Input, { type: "number", value: height, placeholder: "\u8EAB\u9577\u3092\u8FFD\u52A0", dataTest: "height", containerClassObject: styles.inputContainer, hideSpinner: true, onChange: changeHeight, label: "\u8EAB\u9577", unitLabel: "cm", required: true, hasValidationError: !!validationErrorMessages.height, validationErrorMessage: validationErrorMessages.height }),
            React.createElement(Input, { type: "number", value: weight, placeholder: "\u4F53\u91CD\u3092\u8FFD\u52A0", dataTest: "weight", containerClassObject: styles.inputContainer, hideSpinner: true, onChange: changeWeight, label: "\u4F53\u91CD", unitLabel: "kg", required: true, hasValidationError: !!validationErrorMessages.weight, validationErrorMessage: validationErrorMessages.weight })),
        React.createElement("div", { className: css(styles.section) },
            React.createElement(InputButtonTypeRadioButton, { classObject: styles.inputContainer, items: activityLevelOptions, currentValue: activityLevelType, label: '普段の運動量', setCurrentValue: setActivityLevelType, required: true, hasValidationError: !!validationErrorMessages.activityLevelType, validationErrorMessage: validationErrorMessages.activityLevelType }),
            React.createElement(InputButtonTypeCheckbox, { classObject: styles.inputContainer, labelContainerclassObject: styles.worriesLabelContainer, items: worryOptions, currentValue: worries, label: '目的', setCurrentValue: setWorries, onClickButton: changeWorry }),
            React.createElement(InputButtonTypeCheckbox, { labelContainerclassObject: styles.interestsLabelContainer, items: interestOptions, currentValue: interests, label: '興味', setCurrentValue: setInterests })),
        React.createElement(FixedFooterUpdateButton, { onCancelClick: onClickCancel, onSaveClick: onClickSave, disabeled: !saveButtonClickable }),
        isShowGoalAchievedDialog && (React.createElement(WeightGoalAchievedDialog, { onClickSetNewGoal: onClickSetNewGoal, onClickCancel: navigateToMyPage }))));
};
var styles = StyleSheet.create({
    container: {
        width: 315,
    },
    section: {
        marginBottom: 40,
    },
    inputContainer: {
        marginBottom: 20,
    },
    selectContainer: {
        display: 'block',
        marginBottom: 20,
    },
    worriesLabelContainer: {
        width: '100%',
    },
    interestsLabelContainer: {
        width: 'calc((100% - 4px) / 2)',
    },
});
