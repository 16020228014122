import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
export var CCBrandIcon = function (_a) {
    var classObject = _a.classObject, iconClassObject = _a.iconClassObject;
    return (React.createElement("div", { className: css(styles.icons, classObject) },
        React.createElement("img", { className: css(styles.icon, iconClassObject), src: "/assets/images/cc_brands/visa.png" }),
        React.createElement("img", { className: css(styles.icon, iconClassObject), src: "/assets/images/cc_brands/jcb.png" }),
        React.createElement("img", { className: css(styles.icon, iconClassObject), src: "/assets/images/cc_brands/mc.png" }),
        React.createElement("img", { className: css(styles.icon, iconClassObject), src: "/assets/images/cc_brands/amex.png" })));
};
var styles = StyleSheet.create({
    icons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        height: 28,
        marginRight: 12,
    },
});
