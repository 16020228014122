import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Colors } from '../styles';
import { RadioIcon } from '../atoms/icons/radio_icon';
export var PaymentFormAmazonPay = function (_a) {
    var classObject = _a.classObject, checked = _a.checked, signedInAmazonPay = _a.signedInAmazonPay, amazonPayPlan = _a.amazonPayPlan, onClickSection = _a.onClickSection;
    var isAmazonPayOnly = signedInAmazonPay && amazonPayPlan;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        !isAmazonPayOnly && React.createElement(RadioIcon, { checked: checked, onClick: onClickSection }),
        React.createElement("div", null,
            React.createElement("div", { className: css(styles.sectionTitle), onClick: onClickSection }, "Amazon Pay\u3067\u304A\u652F\u6255\u3044"),
            checked && React.createElement("div", { className: css(styles.text) }, "Amazon\u306B\u767B\u9332\u3057\u305F\u304A\u652F\u6255\u3044\u60C5\u5831\u3092\u305D\u306E\u307E\u307E\u4F7F\u7528\u3067\u304D\u307E\u3059"))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        gap: 8,
        padding: '12px 20px',
        borderRadius: 10,
        border: "1px solid ".concat(Colors.PrimaryDark),
        cursor: 'pointer',
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
    },
    checkIcon: {
        margin: '0 8px 0 4px',
        width: 16,
        height: 16,
    },
    notChecked: {
        backgroundColor: Colors.Gray,
        borderRadius: 100,
    },
    text: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        marginTop: 6,
    },
});
