import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../../styles/variables';
export var RadioIcon = function (_a) {
    var classObject = _a.classObject, color = _a.color, checked = _a.checked, onClick = _a.onClick;
    var styles = StyleSheet.create({
        icon: {
            width: 20,
            height: 20,
        },
    });
    var iconColor = color || Colors.PrimaryDark;
    return (React.createElement(React.Fragment, null, checked ? (React.createElement("svg", { className: css(styles.icon, classObject), xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "none", onClick: onClick },
        React.createElement("circle", { cx: "10", cy: "10", r: "9.25", fill: Colors.White, stroke: iconColor, strokeWidth: "1.5" }),
        React.createElement("circle", { cx: "10", cy: "10", r: "5", fill: iconColor }))) : (React.createElement("svg", { className: css(styles.icon, classObject), xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "none", onClick: onClick },
        React.createElement("circle", { cx: "10", cy: "10", r: "9.25", fill: Colors.White, stroke: Colors.Gray, strokeWidth: "1.5" })))));
};
